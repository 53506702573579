import React, {Component} from "react";
import {get} from "../../utils/Api";
import {modalBodyScrollPrevent} from "../../utils/generic";
import ComplaintsForm from "../Complaints/ComplaintsForm";
import Modal from "../utils/Modal/Modal";
import confirmDialog from "../../utils/confirmDialog";
import RecommendationForm from "../Recommendation/RecommendationForm";
import {parseFullName} from "../utils/NameParser";
import ForceUpdateScrollbars from "../ForceUpdateScrollbars/ForceUpdateScrollbars";

// stores local state
// in case of component update
let storage = undefined;

class Adviser extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modalIsOpen: false,
      modal2IsOpen: false,
      modal3IsOpen: false,
      modal4IsOpen: false,
			confirmClose: false,
		};
	}

	componentDidMount() {
		// if component mounts (i.e. is rendered for the first time), reload
		// the contents
		this.reload();
	}

	reload = () => {
		if (storage) {
			this.setState({advisor: storage});
		} else {
			// fetches new data from API
			get("/poradce/info").then(response => {
				// updates local state
				// (and causes re-render)
				if (response) {
					storage = response.data.data;
					this.setState({advisor: storage});
				}
			});
		}

	};

	toggleModal = (modalIsOpen, modal2IsOpen, modal3IsOpen, modal4IsOpen) => {
		if (!(modalIsOpen || modal2IsOpen || modal3IsOpen || modal4IsOpen) && this.state.confirmClose) {
			confirmDialog("Neuložené změny", "Opravdu si přejete zavřít okno bez uložení změn?", "zavřít okno").then(
				result => {
					this.setState({
						modalIsOpen,
						modal2IsOpen,
						modal3IsOpen,
						modal4IsOpen,
						confirmClose: false,
					});
					modalBodyScrollPrevent(modalIsOpen || modal2IsOpen || modal3IsOpen || modal4IsOpen, "adviser");
				},
				result => {
				}
			);
		} else {
			this.setState({
        modalIsOpen,
        modal2IsOpen,
        modal3IsOpen,
        modal4IsOpen,
			});
			modalBodyScrollPrevent(modalIsOpen || modal2IsOpen || modal3IsOpen || modal4IsOpen, "adviser");
		}
	};

	onChangeForm = () => {
		if (!this.state.confirmClose) {
			this.setState({
				confirmClose: true,
			});
		}
	};

	onSubmitForm = () => {
		this.setState({
			confirmClose: false,
		});
	};

	parseName = (name) => {
	  let parsedName = parseFullName(name, 'all', true, false, true);

    let names = {
      lastName: parsedName.last,
      middleName: parsedName.middle,
      firstName: parsedName.first,
      nameSuffix: parsedName.suffix,
      namePrefix: parsedName.title,
    };

	  return names;
  };

	getVCard = (qr) => {
	  if (this.state.advisor) {
      let names = this.parseName(this.state.advisor.db.jmeno);

      let card = {
        version: '3.0',
        lastName: names.lastName,
        middleName: names.middleName,
        firstName: names.firstName,
        nameSuffix: names.nameSuffix,
        namePrefix: names.namePrefix,
        workPhone: this.state.advisor.db.telefon,
        role: this.state.advisor.db.pozice,
        workEmail: this.state.advisor.db.email,
        workUrl: this.state.advisor.db.web,
        workAddress: {
          street: this.state.advisor.db.adresa
        },
      };

      if (!qr && this.state.advisor && this.state.advisor.fotka && this.state.advisor.fotka.url) {
        card['photo'] = {
          url: this.state.advisor.fotka.url.replace(/.*base64,/g, ''),
          mediaType: 'JPEG',
          base64: true
        }
      }

      return card;
    } else {
	    return {};
    }
  };

	generateQRCode = () => {
	  let qrCode = '';
	  let card = this.getVCard(true);
    try {
      qrCode = window.qrCode.createVCardQr(card, {
        typeNumber: 20,
        cellSize: 2,
        errorCorrectionLevel: 'H'
      });
    } catch (e) {
      qrCode = window.qrCode.createVCardQr(card, {
        typeNumber: 30,
        cellSize: 2,
        errorCorrectionLevel: 'H'
      });
    }

    return qrCode;
  };

  getMobileOperatingSystem = () => {
    let userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  };

	downloadVCard = () => {
    let card = this.getVCard(false);
    let vcard = window.qrCode.createVCard(card);
    const file = new Blob([vcard], {type: 'text/vcard'});
    let os = this.getMobileOperatingSystem();
    if(os === "iOS") {
      let reader = new FileReader();
      reader.onload = (e) => {
        window.location.href = reader.result;
      };
      reader.readAsDataURL(file);
    } else {
      const element = document.createElement("a");
      element.href = URL.createObjectURL(file);
      element.download = "vcard.vcf";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
  };

	render() {
	  if (!this.state.advisor) {
	    return (
        <div id="profil-block">
          <div className="row">
            <div className="col-sm-3">
              <div className={'local-spinner'}></div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div id="profil-block">
          <div className="left">
            <ForceUpdateScrollbars
              className="cell--half-height__scrollbox scrollbox--md--disabled"
              autoHeight
              autoHeightMin={0}
              autoHeightMax={400}
              forceupdaterendertimer={1000}
              identifier={'home-scrollbar-vase-udalosti'}
            >
              <div scrollbarIdentifier={'home-scrollbar-vase-udalosti'}>
                <h3 className="name">{this.state.advisor.db.jmeno}</h3>
                <h4 className="pozice">{this.state.advisor.db.pozice}</h4>
                <p className="popis">{this.state.advisor.db.popis}</p>
                <a className="kontakt mobil" href={'tel:' + this.state.advisor.db.telefon}>
                  {this.state.advisor.db.telefon ? this.state.advisor.db.telefon.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ') : ''}
                </a>
                <a className="kontakt email" href={'mailto:' + this.state.advisor.db.email}>
                  {this.state.advisor.db.email}
                </a>
              </div>
            </ForceUpdateScrollbars>
            {this.state.advisor.db.linkedin_aktivni && this.state.advisor.db.linkedin ?
              <a className="icon icon-lnk" href={this.state.advisor.db.linkedin} target={'_blank'}> </a> : null}
            {this.state.advisor.db.web_aktivni && this.state.advisor.db.web ?
              <a className="icon icon-www" href={this.state.advisor.db.web} target={'_blank'} > </a> : null}
            {this.state.advisor.db.kontakt_aktivni ? <span className="icon icon-contact" onClick={(e) => {
              this.toggleModal(false, false, false, true)
            }}></span> : null}
            {this.state.advisor.db.adresa_aktivni && this.state.advisor.db.adresa ?
              <span className="icon icon-maps" onClick={(e) => {
                this.toggleModal(false, false, true, false)
              }}></span> : <span className="icon"></span>}
          </div>
          <div className="right">
            {this.state.advisor && this.state.advisor.fotka && this.state.advisor.fotka.url ?
              <img className={'photo'} src={this.state.advisor.fotka.url} alt=""/>
              :
              <img className={'photo'} src="/img/nophoto.jpg" alt=""/>
            }
            <label className="zpetna-vazba" onClick={(e) => {
              this.toggleModal(true, false, false, false)
            }}>zpětná vazba</label>
            <label className="dejte-o-me-vedet" onClick={(e) => {
              this.toggleModal(false, true, false, false)
            }}>doporučení</label>
          </div>

          <div className="complaints-modal">
            <Modal
              isOpen={this.state.modalIsOpen}
              onRequestClose={() => this.toggleModal(false, false, false, false)}
            >
              <h1>Zpětná vazba</h1>
              <ComplaintsForm
                onChange={this.onChangeForm.bind(this)}
                onSubmit={this.onSubmitForm.bind(this)}
                close={() => this.toggleModal(false, false, false, false)}
              />
            </Modal>
          </div>

          <div className="recommendation-modal">
            <Modal
              isOpen={this.state.modal2IsOpen}
              onRequestClose={() => this.toggleModal(false, false, false, false)}
            >
              <h1 className="recommendation-title">Zřejmě jsem Vám byl prospěšný :-)</h1>
              <RecommendationForm
                onChange={this.onChangeForm.bind(this)}
                onSubmit={this.onSubmitForm.bind(this)}
                close={() => this.toggleModal(false, false, false, false)}
              />
            </Modal>
          </div>

          <div className="map-modal">
            <Modal
              isOpen={this.state.modal3IsOpen}
              onRequestClose={() => this.toggleModal(false, false, false, false)}
            >
              <div style={{
                width: '550px',
                position: 'relative',
                'margin-top': '20px'
              }}>
                <iframe
                  title="Mapa poradce"
                  width="550"
                  height="440"
                  src={"https://maps.google.com/maps?width=550&amp;height=440&hl=cs&q=" + encodeURIComponent(!this.state.advisor || !this.state.advisor.db.adresa ? '' : this.state.advisor.db.adresa.replace(/[,-]/g, ' ').replace(/\s{1,}/g, ' ')) + "+(Titul)&ie=UTF8&t=&z=15&iwloc=B&output=embed"}
                  frameBorder="0"
                  scrolling="no"
                  marginHeight="0"
                  marginWidth="0"
                />
                <div style={{
                  position: 'absolute',
                  width: '80%',
                  bottom: '10px',
                  left: 0,
                  right: 0,
                  'margin-left': 'auto',
                  'margin-right': 'auto',
                  color: '#000',
                  'text-align': 'center'
                }}/>
              </div>
            </Modal>
          </div>

          <div className="contact-modal">
            <Modal
              isOpen={this.state.modal4IsOpen}
              onRequestClose={() => this.toggleModal(false, false, false, false)}
            >
              <div className={'contact-modal-content'}>
                <h2>Přidejte si mě do kontaktů</h2>
                <div className={'left'}>
                  {this.state.advisor && this.state.advisor.fotka && this.state.advisor.fotka.url ?
                    <img className={'photo'} src={this.state.advisor.fotka.url} alt=""/>
                    :
                    <img className={'photo'} src="/img/nophoto.jpg" alt=""/>
                  }
                  <label>{!this.state.advisor ? '' : this.state.advisor.db.jmeno}</label>
                  <label>{!this.state.advisor ? '' : this.state.advisor.db.pozice}</label>
                  <div className={'clear'}/>
                  <div className={'space'}/>
                  <label>{!this.state.advisor || !this.state.advisor.db.telefon ? '' : this.state.advisor.db.telefon.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')}</label>
                  <label>{!this.state.advisor ? '' : this.state.advisor.db.email}</label>
                  <div className={'space'}/>
                  <label className={'address'}>{!this.state.advisor ? '' : this.state.advisor.db.adresa}</label>
                </div>
                <div className={'right'}>
                  <div className={'qrCode'}>
                    <div dangerouslySetInnerHTML={{__html: this.generateQRCode()}}/>
                    <div className={'qrCode-logo'}/>
                  </div>
                  <span className={'btn submit'} onClick={() => {
                    this.downloadVCard();
                    return false;
                  }}>stáhnout vcf vizitku</span>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      );
    }
	}
}

export default Adviser;
