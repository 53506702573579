import React, {Component} from "react";
import Input from "./Input";

export default class CheckboxGroup extends Component {

	constructor(props) {
		super(props);
		this.state = {...props.defaults}
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.defaults) {
			this.setState({...props.defaults});
		}
	}

	onChange = (e) => {
		this.setState({
			[e.target.name]: e.target.checked
		});
		this.props.onChange(e);
	};

	render() {
		return (
			<div>
				{Object.keys(this.props.options).map((name, index) => {
					if ('exclude' in this.props && name in this.props.exclude && this.props.exclude[name]) {
						return null;
					} else {
						return (
							<Input key={index} onChange={this.onChange} type="checkbox" name={name}
								   label={this.props.options[name]}
								   value={this.state[name]} disabled={this.props.disabled}/>
						)
					}
				})}
			</div>
		);
	};
}
