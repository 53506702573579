import {getUserObject} from "./Api";
import Uzivatel from "./../services/Uzivatel.js";
import MyplannClient from "./../services/MyplannClient.js";
import DateHelper from "./../services/DateHelper.js";
import ReactDI from "react-di";
import SmartformClient from "../services/SmartformClient";
import MainCache from "../services/MainCache";

class Container extends ReactDI {

	constructor() {
		super();
		this.getMyplannClient();
		this.getSmartformClient();
		this.getDateHelper();
		this.getUzivatel();
		this.getMainCache();
	}

	/**
	 * @returns {Uzivatel}
	 */
	getUzivatel = () => {
		if (!this.has('uzivatel')) {
			if (!getUserObject()) {
				this.set('uzivatel', new Uzivatel({data: {}}));
			} else {
				this.set('uzivatel', new Uzivatel(getUserObject().data));
			}
		}
		return this.get('uzivatel');
	};

	/**
	 * @returns {MyplannClient}
	 */
	getMyplannClient = () => {
		if (!this.has('myplannClient')) {
			this.set('myplannClient', new MyplannClient(this.getMainCache()));
		}
		return this.get('myplannClient');
	};

	/**
	 * @returns {SmartformClient}
	 */
	getSmartformClient = () => {
		if (!this.has('smartformClient')) {
			this.set('smartformClient', new SmartformClient());
		}
		return this.get('smartformClient');
	};

	/**
	 * @returns {DateHelper}
	 */
	getDateHelper = () => {
		if (!this.has('dateHelper')) {
			this.set('dateHelper', new DateHelper());
		}
		return this.get('dateHelper');
	};

	/**
	 * @returns {MainCache}
	 */
	getMainCache = () => {
		if (!this.has('mainCache')) {
			this.set('mainCache', new MainCache());
		}
		return this.get('mainCache');
	};
}

export default Container;