import React, {Component} from "react";
import {get, getApi, put} from "../../utils/Api";
import {getContainer, modalBodyScrollPrevent} from "../../utils/generic";
import Modal from "../utils/Modal/Modal";
import ForceUpdateScrollbars from "../ForceUpdateScrollbars/ForceUpdateScrollbars";

let intervenceCache = {
	db: []
};

let incidentsCache = {
  db: []
};

let tasksCache = {
  db: []
};

class HomeIncidents extends Component {

	constructor(props) {
		super(props);
		this.chatInput = React.createRef();
		this.state = {
		  chat: null,
			modalIsOpen: false,
			activeItem: null,
			activeTask: null,
			intervence: {
				db: []
			},
      incidents: {
        db: []
      },
      tasks: {
        db: []
      },
			loading: true,
			inOwnPage: props.inOwnPage ? props.inOwnPage : false,
			incidentsFilter: getIncidentsDefaultFilter(),
			showFinished: false,
		};
        let filteredCache = this.filterEvents(false);
        this.state = Object.assign(this.state, {
            intervence: filteredCache.intervence,
            incidents: filteredCache.incidents,
            tasks: filteredCache.tasks,
		});

		if (this.hasItems()) {
			this.state.loading = false;
		}

		this._dateHelper = getContainer().getDateHelper();
		this._client = getContainer().getMyplannClient();
	}


	componentDidUpdate() {
    var list = document.getElementById('list');
    if(list) {
      var c = list.children[0];
      if(c) {
        c.scrollTop = c.scrollHeight;
      }
    }
  }

  componentDidMount() {
		// if component mounts (i.e. is rendered for the first time), reload
		// the contents
		this.reload();
	}

	onIncidentsFilterChange = (incidentsFilter) => {
		let showFinished = false;

		if (incidentsFilter) {
			incidentsFilter.forEach((item) => {
				if (item === "finished") {
					showFinished = true;
				}
			});
		}

		this.setState({incidentsFilter, showFinished}, this.filterEvents);
	};

	filterEvents = (setState : boolean = true) => {
		let filteredIncidents;

		let intervences = [];
		let incidents = [];
		let tasks = [];

		if (this.state.incidentsFilter.indexOf("interventions-only") > -1 || !this.state.inOwnPage || this.state.incidentsFilter.length === 0 || (this.state.incidentsFilter.length === 1 && this.state.incidentsFilter[0] === 'finished')) {
			intervences = intervenceCache.db;
		}

    if (this.state.incidentsFilter.indexOf("tasks") > -1 || !this.state.inOwnPage || this.state.incidentsFilter.length === 0 || (this.state.incidentsFilter.length === 1 && this.state.incidentsFilter[0] === 'finished')) {
      tasks = tasksCache.db;
    }

		if (this.state.incidentsFilter.indexOf("advisers-dates-only") > -1 || !this.state.inOwnPage || this.state.incidentsFilter.length === 0 || (this.state.incidentsFilter.length === 1 && this.state.incidentsFilter[0] === 'finished')) {
			filteredIncidents = incidentsCache.db.filter(function (item) {
				return item.filterTyp === "termin-od-poradce";
			});
			Array.prototype.push.apply(incidents, filteredIncidents);
		}

    if (this.state.incidentsFilter.indexOf("contract-dates-only-vyroci") > -1 || !this.state.inOwnPage || this.state.incidentsFilter.length === 0 || (this.state.incidentsFilter.length === 1 && this.state.incidentsFilter[0] === 'finished')) {
      filteredIncidents = incidentsCache.db.filter(function (item) {
        return item.filterTyp === "termin-smlouvy" && item.typ === "vyroci";
      });
      Array.prototype.push.apply(incidents, filteredIncidents);
    }

    if (this.state.incidentsFilter.indexOf("contract-dates-only-fixace") > -1 || !this.state.inOwnPage || this.state.incidentsFilter.length === 0 || (this.state.incidentsFilter.length === 1 && this.state.incidentsFilter[0] === 'finished')) {
      filteredIncidents = incidentsCache.db.filter(function (item) {
        return item.filterTyp === "termin-smlouvy" && item.typ === "fixace";
      });
      Array.prototype.push.apply(incidents, filteredIncidents);
    }

    if (this.state.incidentsFilter.indexOf("contract-dates-only-konec") > -1 || !this.state.inOwnPage || this.state.incidentsFilter.length === 0 || (this.state.incidentsFilter.length === 1 && this.state.incidentsFilter[0] === 'finished')) {
      filteredIncidents = incidentsCache.db.filter(function (item) {
        return item.filterTyp === "termin-smlouvy" && item.typ === "konec";
      });
      Array.prototype.push.apply(incidents, filteredIncidents);
    }

		let hiddenInterventionsCount = intervences.filter(function (item) {
			return item.skrytoKlientem === 1;
		}).length;

    let hiddenIncidentsCount = incidents.filter(function (item) {
      return item.skrytoKlientem === 1;
    }).length;

    let hiddenTasksCount = tasks.filter(function (item) {
      return item.skrytoKlientem === 1;
    }).length;

		if (this.state.incidentsFilter.indexOf("finished") === -1 || !this.state.inOwnPage) {
			intervences = intervences.filter(function (item) {
				return item.skrytoKlientem === 0
			});

      incidents = incidents.filter(function (item) {
        return item.skrytoKlientem === 0
      });

      tasks = tasks.filter(function (item) {
        return item.skrytoKlientem === 0;
      });


    }

		let ret = {
            intervence: {
                db: intervences,
                hiddenInterventions: hiddenInterventionsCount
            },
            incidents: {
              db: incidents,
              hiddenEvents: hiddenIncidentsCount
            },
            tasks: {
              db: tasks,
              hiddenEvents: hiddenTasksCount
            },
        };


        if (setState) {
            this.setState(ret);
        }
		return ret;
	};

  onClick = (e) => {
    let dataset = e.currentTarget.dataset;
    if (dataset.eventType !== undefined && dataset.eventId !== undefined && dataset.eventFinished !== undefined) {
      if (dataset.eventType === "intervence") {
        put("/intervence/vyrizeno?id=" + dataset.eventId + "&stav=" + dataset.eventFinished).then(response => {
          this.reload();
        });
      } else if(dataset.eventType === 'task') {
        put("/ukol/changestate?id=" + dataset.eventId + "&confirm=" + dataset.eventFinished).then(response => {
          this.reload();
        });
      } else {
        put("/udalost/vyrizeno?id=" + dataset.eventId + "&typ=" + dataset.eventType + "&stav=" + dataset.eventFinished).then(response => {
          this.reload();
        });
      }
    }
  };

  onChatChange = (e) => {

    let value = e.target.value;
    this.setState({chat: value});

  };

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.onSend(e);
    }
  }


  onSend = (e) => {
    if(!this.state.chat || this.state.chat.trim().length === 0){
      return;
    }
    this.setState({loading: true});
    var that = this;
    getApi().post("/ukol/chat?id=" + this.state.activeTask.data.id,{value: this.state.chat}).then(response => {
      if(response.data){
        this.chatInput.current.value='';
        that.setState({
          activeTask: response.data,
          chat: null,
          loading: false,
        });
      }
    });
  };

  onUkolDilciChange = (e) => {


    var dilciId = e.currentTarget.dataset.id;
    var checked = e.currentTarget.checked;
    var that = this;

    get("/ukol/dilcichangestate?id=" + dilciId + "&confirm=" + checked).then(response => {
      if(response.data){
        that.setState({activeTask: response.data});
      }
            //this.loadTasksOnly();
    });

  };

	loadInterventionsOnly = () => {
		let url = "/intervence/home";



		get(url, false, null, false, true).then(response => {
			// updates local state
			// (and causes re-render)
			if (response) {

				if (response.data && response.data.data && response.data.data.db) {
					response.data.data.db.forEach((result) => {
						if (result) {
							this._dateHelper.reformatDates(result);

							if (result.detail_api && result.detail_api.datum_podpisu) {
								result.detail_api.datum_podpisu = this._dateHelper.reformatDate(result.detail_api.datum_podpisu);
							}
						}
					});
				}

				this.setState({
					loading: false
				});
				intervenceCache = response.data.data;
				this.filterEvents();
			} else {
				this.setState({
					intervence: {
						db: []
					},
					loading: false
				});
			}
		});
	};

	loadIncidentsOnly = () => {
		let url = "/udalost/home";

		get(url, false, null, false, true).then(response => {
			// updates local state
			// (and causes re-render)
			if (response) {
				if (response.data && response.data.data && response.data.data.db) {
					response.data.data.db.forEach((result) => {
						this._dateHelper.reformatDates(result);
					});
				}
				this.setState({
					loading: false
				});
				incidentsCache = response.data.data;
				this.filterEvents();
			} else {
				this.setState({
					incidents: {
						db: []
					},
					loading: false
				});
			}
		});
	};

  loadTasksOnly = () => {
    let url = "/ukol/home";

    get(url, false, null, false, true).then(response => {
      // updates local state
      // (and causes re-render)
      if (response) {
        if (response.data && response.data.data && response.data.data.db) {
          response.data.data.db.forEach((result) => {
            this._dateHelper.reformatDates(result);
          });
        }
        this.setState({
          loading: false
        });
        tasksCache = response.data.data;
        this.filterEvents();
      } else {
        this.setState({
          tasks: {
            db: []
          },
          loading: false
        });
      }
    });
  };

	reload = () => {
		this.loadIncidentsOnly();
		this.loadInterventionsOnly();
		this.loadTasksOnly();
	};

	hasItems = () => {
		if (this.state.intervence.db.length === 0) {
			if (this.state.incidents.db.length === 0) {
			  if(this.state.tasks.db.length === 0){
          return false;
        }
			}
		}
		return true;
	};

  toggleModalIntervence = (modalIsOpen, activeItem = null) => {
    this.setState({modalIsOpen});

    if (activeItem) {
      this.setState({activeItem});
    } else {
      // Firstly we need close the modal window and then we can set data to null.
      setTimeout(() => {
        this.setState({activeItem});
      }, 250);
    }

    modalBodyScrollPrevent(modalIsOpen, "homeIncidents");
  };

  toggleModalTask = (modalIsOpen, activeTask = null) => {
    this.setState({modalIsOpen});

    if (activeTask) {
      this.setState({activeTask});
    } else {
      // Firstly we need close the modal window and then we can set data to null.
      setTimeout(() => {
        this.setState({activeTask});
      }, 250);
    }

    modalBodyScrollPrevent(modalIsOpen, "homeTasks");
  };


  renderInterventionsList = (hidden) => {
		return (
			<div
				className={"responsive-table responsive-table--first-icon " + (this.state.inOwnPage ? "responsive-table--sm" : "") + " responsive-table--second-next-first"}>
				{this.state.intervence && this.state.intervence.db.filter(function (item) {
					return item.skrytoKlientem === (hidden ? 1 : 0)
				}).map((result, index) => {
						return (
							<div key={index} className="tr table-row pointer">
								{this.state.inOwnPage === true ?
									<div className="td">
										{result.skrytoKlientem !== 1 ?
											<button onClick={this.onClick}
													data-event-type={'intervence'}
													data-event-id={result.id_intervence}
													data-event-finished={result.skrytoKlientem}
													className={'btn-check top-space--minus-4'}
													title={'Označit událost jako vyřízenou'}>
												<i className="fa fa-times"></i>
												<i className="fa fa-check hover"></i>
											</button>
											:
											<button onClick={this.onClick}
													data-event-type={'intervence'}
													data-event-id={result.id_intervence}
													data-event-finished={result.skrytoKlientem}
													className={'btn-check-active top-space--minus-4'}
													title={'Označit událost jako nevyřízenou'}>
												<i className="fa fa-check"></i>
												<i className="fa fa-times hover"></i>
											</button>
										}
									</div>
									:
									''
								}
                <div className="td td--width--30 min--width--90" onClick={(e) => {
                  this.toggleModalIntervence(true, {id: result.id, data: result})
                }}>
                  <span className="s-type s-type-intervence text-nowrap">Intervence</span>

                </div>

								<div className="td td--width--90 min--width--70" onClick={(e) => {
									this.toggleModalIntervence(true, {id: result.id, data: result})
								}}>
									<span className="text-gray text-nowrap">{result.datum}</span>
								</div>
								<div className="td width--100--percent" onClick={(e) => {
									this.toggleModalIntervence(true, {id: result.id, data: result})
								}}>
									{result.nadpis}
								</div>
							</div>
						)
					}
				)}
			</div>
		);
	};

  renderIncidentsList = (hidden) => {
    let useColspan = false;

    if (this.state.incidents && this.state.incidents.db) {
      this.state.incidents.db.forEach((item) => {
        if (item.skrytoKlientem === (hidden ? 1 : 0) && item.cislo_smlouvy !== '') {
          useColspan = true;
        }
      });
    }

    return (
      <table
        className={"responsive-table responsive-table--first-icon " + (this.state.inOwnPage ? "responsive-table--sm" : "") + " responsive-table--second-next-first"}>
        <tbody>
        {this.state.incidents && this.state.incidents.db.filter(function (item) {
          return item.skrytoKlientem === (hidden ? 1 : 0)
        }).map((result, index) => {
            return (
              <tr key={index}>
                {this.state.inOwnPage === true ?
                  <td>

                    {Number(result.skrytoKlientem) === 0 || result.skrytoKlientem === null ?
                      <button onClick={this.onClick}
                              data-event-type={result.typ}
                              data-event-id={result.id_udalost}
                              data-event-finished={result.skrytoKlientem !== null ? result.skrytoKlientem : 0}
                              className={'btn-check top-space--minus-4'}
                              title={'Označit událost jako vyřízenou'}>
                        <i className="fa fa-times"></i>
                        <i className="fa fa-check hover"></i>
                      </button>
                      :
                      <button onClick={this.onClick}
                              data-event-type={result.typ}
                              data-event-id={result.id_udalost}
                              data-event-finished={result.skrytoKlientem !== null ? result.skrytoKlientem : 0}
                              className={'btn-check-active top-space--minus-4'}
                              title={'Označit událost jako nevyřízenou'}>
                        <i className="fa fa-check"></i>
                        <i className="fa fa-times hover"></i>
                      </button>
                    }
                  </td>
                  :
                  null
                }
                <td className="td td--width--30 min--width--90">
                  <span className={"s-type text-nowrap s-type-"+result.typ}>{result.typ === 'vyroci' ? 'Výročí' : (result.typ === 'konec' ? 'Konec' : (result.typ === 'fixace' ? 'Fixace' : 'Termín'))}</span>

                </td>
                <td className="td--width--90 min--width--70">
                  <span className="text-gray text-nowrap">{result.datumx}</span>
                </td>

                {result.cislo_smlouvy !== "" ?
                  <td>
                    <span className={'text-nowrap'}>Č. {result.cislo_smlouvy}</span>
                  </td> : null}

                <td className="td width--100--percent" colSpan={(useColspan && result.cislo_smlouvy === '' ? 2 : 1)}>
                  {result.producent} {result.typ_popis}
                </td>
              </tr>
            )
          }
        )}
        </tbody>
      </table>
    );
  };

  renderTasksList = (hidden) => {
    return (
      <div
        className={"responsive-table responsive-table--first-icon " + (this.state.inOwnPage ? "responsive-table--sm" : "") + " responsive-table--second-next-first"}>
        {this.state.tasks && this.state.tasks.db.filter(function (item) {
          return item.skrytoKlientem === (hidden ? 1 : 0)
        }).map((result, index) => {
            return (
              <div key={index} className="tr table-row pointer">
                {this.state.inOwnPage === true ?
                  <div className="td">
                    {result.skryto_poradcem === 1 ? <button data-event-type={'task'}
                                                            data-event-id={result.id}
                                                            data-event-finished={1}
                                                            className={'btn-finished top-space--minus-4'}
                                                            title={'Tento úkol poradce označil jako vyřešený'}>
                      <i className="fa fa-check"></i>
                      <i className="fa fa-times hover"></i>
                    </button> : (result.skrytoKlientem !== 1 ?
                      <button onClick={this.onClick}
                              data-event-type={'task'}
                              data-event-id={result.id}
                              data-event-finished={result.skrytoKlientem ? 0 : 1}
                              className={'btn-check top-space--minus-4'}
                              title={'Označit úkol jako vyřízený'}>
                        <i className="fa fa-times"></i>
                        <i className="fa fa-check hover"></i>
                      </button>
                      :
                      <button onClick={this.onClick}
                              data-event-type={'task'}
                              data-event-id={result.id}
                              data-event-finished={result.skrytoKlientem ? 0 : 1}
                              className={'btn-check-active top-space--minus-4'}
                              title={'Označit událost jako nevyřízený'}>
                        <i className="fa fa-check"></i>
                        <i className="fa fa-times hover"></i>
                      </button>)}
                  </div>
                  :
                  ''
                }
                <div className="td td--width--30 min--width--90" onClick={(e) => {
                  this.toggleModalTask(true, {id: result.id, data: result})
                }}>
                  <span className="s-type s-type-ukol text-nowrap">Úkol</span>

                </div>

                <div className="td td--width--90 min--width--70" onClick={(e) => {
                  this.toggleModalTask(true, {id: result.id, data: result})
                }}>
                  <span className="text-gray text-nowrap">{result.termin_splneni}</span>
                </div>
                <div className="td width--100--percent" onClick={(e) => {
                  this.toggleModalTask(true, {id: result.id, data: result})
                }}>
                  {result.nazev}
                </div>
              </div>
            )
          }
        )}
      </div>
    );
  };

  renderTasksPartialList = () => {

    var tasksPartial = this.state.activeTask.data.ukoly_dilci;

    if(Object.keys(tasksPartial).length === 0){
      return (
        ''
      );
    }

    var that = this;
    var lis = Object.keys(tasksPartial).map(function(value, index){
      return (
        <li className={'relative'}>
          <input data-id={tasksPartial[value].id} onChange={that.onUkolDilciChange} checked={!!tasksPartial[value].vyresil} id={'partial-'+tasksPartial[value].id} name={'partial-' + tasksPartial[value].id} style={{position: 'absolute', 'left':0, 'top':'3px'}} type="checkbox"/>
            <label for={'partial-'+tasksPartial[value].id}>{tasksPartial[value].nazev}</label>
            {tasksPartial[value].vyresil ? <div className={"partial-done"}>{tasksPartial[value].datum_vyreseni} Od: {tasksPartial[value].vyresil}</div> : ''}
        </li>
      );
    });

    return (<div><h2>Dílčí úkoly</h2><ul className={'partial-list'} style={{marginBottom:'5px'}}>{lis}</ul></div>);

  };

  renderTasksChat = () => {

    var chat = this.state.activeTask.data.zpravy;

    var lis = '';
    if(Object.keys(chat).length > 0){
      lis = Object.keys(chat).map(function(value, index){
        if(chat[value].moje_zprava){
          return (
            <div className={'task-chat task-chat-out'}>
              <div>
                <div className={'text-white'}>{chat[value].uzivatel}</div>
                <div className={'text-white'}>{chat[value].datum_vytvoreni}</div>
              </div>
              {chat[value].zprava}
            </div>
          );
        }else{
          return (
            <div className={'task-chat task-chat-in'}>
              <div>
                <div>{chat[value].uzivatel}</div>
                <div>{chat[value].datum_vytvoreni}</div>
              </div>
              {chat[value].zprava}
            </div>
          );
        }

      });
    }

    return (
      <div style={{marginBottom:'5px'}}>
        <ForceUpdateScrollbars
          id={'list'}
          className="cell--half-height__scrollbox"
          autoHeight
          autoHeightMin={0}
          autoHeightMax={300}
          forceupdaterendertimer={1000}
        >
          {lis}
        </ForceUpdateScrollbars>


      <div className={'relative'}>
        <input style={{paddingRight: '85px'}} ref={this.chatInput} onKeyPress={this.handleKeyPress} onChange={this.onChatChange} placeholder={'Přidat zprávu'} type={'text'} />
        <span style={{
          position: 'absolute',
          right: 0,
          padding: '0 15px',
          margin: 0,
          top: '2px',
          height: '33px',
          lineHeight: '33px',
          display: (!this.state.chat || this.state.chat.trim().length === 0) ? 'none' : 'inline-block'
        }} onClick={this.onSend} className={'btn'}>Odeslat</span>
      </div>
    </div>

    );

  };

  renderTasksAttachmentList = () => {

    var attachments = this.state.activeTask.data.prilohy;

    if(Object.keys(attachments).length === 0){
      return '';
    }

    var that = this;
    var lis = Object.keys(attachments).map(function(value, index){
      return (
        <li style={{padding: '5px 0'}}>
          <a style={{marginRight:'5px'}} className="pointer download underline-hover"
             href={that._client.__ukol_attachment_download_url(attachments[value].id)}
             onClick={(e) => {
               e.preventDefault();
               that._client.__ukol_attachment_download(attachments[value].id)
             }}> </a>
          <span>{attachments[value].nazev}</span>

        </li>
      );
    })

    return (<div><h2>Přílohy</h2><ul style={{marginBottom:'5px'}}>{lis}</ul></div>);

  };


	render = () => {
		let hiddenCount = 0;
		if (this.state.intervence && this.state.intervence.hiddenInterventions !== undefined) {
			hiddenCount += parseInt(this.state.intervence.hiddenInterventions, 10);
		}

		if (this.state.incidents && this.state.incidents.hiddenEvents !== undefined) {
			hiddenCount += parseInt(this.state.incidents.hiddenEvents, 10);
		}

    if (this.state.tasks && this.state.tasks.hiddenEvents !== undefined) {
      hiddenCount += parseInt(this.state.tasks.hiddenEvents, 10);
    }

		return (
			<div>
				{this.state.inOwnPage === true ?
					<div>
						<IncidentsFilter onChange={this.onIncidentsFilterChange} showStadard={true}
										 showNonStandard={false} key={"top"}/>
					</div>
					:
					null
				}
				{this.state.loading === true ?
					<div className={'local-spinner'}></div>
					: !this.hasItems() ? <div className={'no-events'}>
						{this.state.inOwnPage ? <br/> : ""}
						Nemáte žádné události
					</div> : ''
				}
				<div className={'responsive-table__container top-space--10'}>
					{this.renderInterventionsList(false)}
				</div>

        <div className={'responsive-table__container'}>
          {this.renderTasksList(false)}
        </div>

				<div className={'responsive-table__container'}>
					{this.renderIncidentsList(false)}
				</div>


				{(this.state.inOwnPage === true && hiddenCount !== 0) ?
					<div className={"top-space--10"}>
						<IncidentsFilter onChange={this.onIncidentsFilterChange} showStadard={false}
										 showNonStandard={true} key={"bottom"}/>
					</div>
					:
					null
				}

				{this.state.incidentsFilter.indexOf("finished") > -1 ?
					<div>
						<div className={'responsive-table__container top-space--10'}>
							{this.renderInterventionsList(true)}
						</div>

						<div className={'responsive-table__container'}>
							{this.renderIncidentsList(true)}
						</div>

            <div className={'responsive-table__container'}>
              {this.renderTasksList(true)}
            </div>
					</div>
					: ''}

				<div className="incidents-modal">
					<Modal
						onRequestClose={() => this.toggleModalIntervence(false)}
						isOpen={this.state.modalIsOpen}
					>
						{this.state.activeItem ?
							<div className={'intervention-modal'}>
								<h1>Intervence</h1>
								<h2>{this.state.activeItem.data.nadpis}</h2>
								<table>
									<tbody>
									<tr>
										<td colSpan={2}><h3>Smlouva</h3></td>
									</tr>
									<tr>
										<td>Číslo smlouvy:</td>
										<td>{this.state.activeItem.data.detail_api.cislo_smlouvy}</td>
									</tr>
									<tr>
										<td>Rodné číslo:</td>
										<td>{this.state.activeItem.data.detail_api.rodne_cislo}</td>
									</tr>
									<tr>
										<td>Producent:</td>
										<td>{this.state.activeItem.data.detail_api.producent}</td>
									</tr>
									<tr>
										<td>Klient:</td>
										<td>{this.state.activeItem.data.detail_api.klient}</td>
									</tr>
									<tr>
										<td>Produkt:</td>
										<td>{this.state.activeItem.data.detail_api.produkt}</td>
									</tr>
									<tr>
										<td>Datum podpisu:</td>
										<td>{this.state.activeItem.data.detail_api.datum_podpisu}</td>
									</tr>
									<tr>
										<td colSpan={2}><h3 className={'space'}>Intervence</h3></td>
									</tr>
									<tr>
										<td>Zadal:</td>
										<td>{this.state.activeItem.data.detail_api.zadal}</td>
									</tr>
									<tr>
										<td>Datum:</td>
										<td>{this.state.activeItem.data.datum}</td>
									</tr>
									<tr>
										<td>Stav:</td>
										<td>{this.state.activeItem.data.detail_api.stav}</td>
									</tr>
									<tr>
										<td>Typ:</td>
										<td>{this.state.activeItem.data.detail_api.kategorie}</td>
									</tr>
									</tbody>
								</table>
								<h3 className={'space'}>Obsah</h3>
								<div className={'text-content'}
									 dangerouslySetInnerHTML={{__html: this.state.activeItem.data.detail_api.popis_nl2br}}/>

								{this.state.activeItem.data.detail_api.dokumenty &&
								this.state.activeItem.data.detail_api.dokumenty.length > 0 ? <h3>Přílohy</h3> : ""}

								{this.state.activeItem.data.detail_api.dokumenty && this.state.activeItem.data.detail_api.dokumenty.map((result, index) => {
										return (
											<div key={index}>
												<a className={'pointer underline-hover'}
												   href={this._client._intervence_dokumentStahnout_url(result.dokumenty_id, this.state.activeItem.data.id_intervence)}
												   onClick={(e) => {
													   e.preventDefault();
													   this._client._intervence_dokumentStahnout(result.dokumenty_id, this.state.activeItem.data.id_intervence)
												   }}>{result.nazev}</a>
											</div>
										)
									}
								)}
							</div>
							:
							''
						}
					</Modal>
				</div>

        <div className="incidents-modal tasks-modal">
          <Modal
            onAfterOpen={function () {
              var list = document.getElementById('list');
              if(list) {
                var c = list.children[0];
                if(c){
                  c.scrollTop = c.scrollHeight;
                }
              }
            }}
            onRequestClose={() => this.toggleModalTask(false)}
            isOpen={this.state.modalIsOpen && this.state.activeTask}
          >
            {this.state.activeTask ? (this.state.activeTask.data.length === 0 ? <div className={'intervention-modal task-modal'}>Došlo k chybě při načítání úkolu. Poradce s Vámi tento úkol již pravděpodobně nesdílí.</div> : <div className={'intervention-modal task-modal'}>
                <h1>{this.state.activeTask.data.nazev}</h1>
                {this.state.activeTask.data.popis ? <><h2>Popis</h2>
                  <p style={{marginBottom: '10px'}} dangerouslySetInnerHTML={{__html: this.state.activeTask.data.popis_nl2br}}/>
                </> : ''}
                {this.state.activeTask.data.termin_splneni ? (<div><h2>Termín splnění</h2>
                  <p style={{marginBottom: '10px'}}>{this.state.activeTask.data.termin_splneni}</p></div>) : ''}
                {this.renderTasksAttachmentList()}

                {this.renderTasksPartialList()}

                <h2>Chat</h2>
                {this.renderTasksChat()}

              </div>)
              :
              ''
            }
          </Modal>
        </div>

			</div>
		);
	};
}

export default HomeIncidents;

let incidentsFilterItems = [
	{name: 'interventions-only', label: 'Intervence', type:'intervence', check: false, standard: true},
	{name: 'advisers-dates-only', label: 'Termíny od poradce', type:'termin', check: false, standard: true},
  //{name: 'contract-dates-only', label: 'Termíny smluv', check: true, standard: true},
  {name: 'contract-dates-only-vyroci', label: 'Výročí smlouvy', type:'vyroci', check: false, standard: true},
  {name: 'contract-dates-only-konec',  label: 'Konec smlouvy', type:'konec', check: false, standard: true},
  {name: 'contract-dates-only-fixace',  label: 'Konec fixace', type:'fixace', check: false, standard: true},
  {name: 'tasks', label: 'Úkoly', type:'ukol', check: false, standard: true},
	{name: 'finished', label: 'Zobrazit i skryté', check: false, standard: false}
];

let getIncidentsDefaultFilter = () => {
	let result = [];
	incidentsFilterItems.forEach(i => {
		if (i.check) {
			result.push(i.name);
		}
	});

	return result;
};

class IncidentsFilter extends Component {
	state = {
		showStadard: typeof this.props.showStadard !== 'undefined' ? this.props.showStadard : true,
		showNonStandard: typeof this.props.showNonStandard !== 'undefined' ? this.props.showNonStandard : false,
	};

	onChange = (name, checked) => {
		let result = [];

		let standardIncidentsFilter = incidentsFilterItems.filter(function (item) {
			return item.standard === true
		});
		let nonStandardIncidentsFilter = incidentsFilterItems.filter(function (item) {
			return item.standard === false
		});
		let checkedIncidentsFilter = standardIncidentsFilter.filter(function (item) {
			return item.check === true
		});

		standardIncidentsFilter.forEach(item => {
			if (standardIncidentsFilter.length === checkedIncidentsFilter.length && name !== "finished") {
				if (item.name !== name) {
					item.check = checked;
				}
			} else {
				if (item.name === name) {
					item.check = checked;
				}
			}

			if (item.check) {
				result.push(item.name);
			}
		})

		if ((result.length === 1 && result.indexOf("finished") > -1)) {
			standardIncidentsFilter.forEach(item => {
				item.check = true;

				if (item.check) {
					result.push(item.name);
				}
			});
		}

		nonStandardIncidentsFilter.forEach(item => {
			if (item.name === name) {
				item.check = checked;
			}

			if (item.check) {
				result.push(item.name);
			}
		});

		this.props.onChange(result);
	};

	render = () => {
		let filters = [];
		incidentsFilterItems.forEach(item => {
			if ((item.standard === true && this.state.showStadard === true) || (item.standard === false && this.state.showNonStandard === true)) {
				filters.push(item);
			}
		});

		return (
			<div className={"incidelts-filter-row"}>
				{this.state.showStadard ?
					<span className={"text-gray incidelts-filter-row__help-text"}>Filtr:</span> : ""}

				{filters.map((item, index) =>
					<span key={index}>
						<input type='checkbox' checked={item.check}
							   onChange={e => this.onChange(item.name, e.target.checked)}
							   id={'incidentsFilter' + item.name}
							   className="text-checkbox text-checkbox--small text-checkbox--green text-checkbox--filtering"/>
						<label className={'s-type s-type-' + item.type} htmlFor={'incidentsFilter' + item.name} key={item.name}>{item.label}</label>
					</span>
				)}
			</div>
		);
	}
}
