import React, {Component} from "react";
import Adviser from "../Adviser/Adviser";
import ProfilForm from "./ProfilForm";
import ZmenaHeslaForm from "./ZmenaHeslaForm";
import ForceUpdateScrollbars from "../ForceUpdateScrollbars/ForceUpdateScrollbars";

class Profil extends Component {
	render = () => {
		return (
			<div className="profil-page">
				<div className="container">
					<div className="row row--smaller-spaces align-items-start">
						<div className="col-lg-4 col-md-5">
							<div className="cell cell--adviser">
								<Adviser/>
							</div>
						</div>
						<div className="col-lg-8 col-md-7">
							<div className="cell cell--full-height">
								<h1 className="padding--bottom--10">Nastavení</h1>

								<ForceUpdateScrollbars
									className="cell--full-height__scrollbox scrollbox--md--disabled"
									autoHeight
									autoHeightMin={0}
									autoHeightMax={1500}
									forceupdaterendertimer={1000}
									identifier={'profil'}
								>
									<ProfilForm
										user={this.props.user}
										userUpdate={this.props.userUpdate}
									/>

									<ZmenaHeslaForm/>
								</ForceUpdateScrollbars>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};
}

export default Profil;