import React, {Component} from "react";
import * as PubSub from "pubsub-js";
import Modal from "../../utils/Modal/Modal";
import {getContainer} from "../../../utils/generic";
import propertyAssetsTypes, {getPropertyAssetTypeIDFromName} from "../../../data/propertyAssetsTypes";

export default class PronajemMajetkuGrid extends Component {

	constructor(props) {
		super(props);
		this._myplannClient = getContainer().getMyplannClient();
		this._dateHelper = getContainer().getDateHelper();
		this._uzivatel = getContainer().getUzivatel();
		this._client = getContainer().getMyplannClient();
		this.state = {
			activeItem: null,
			majetkyKPronajmu: [],
			typ: null,
			msg: null,
			success: null,
			showNames: false,
		};
	}

	/**
	 * @return MyplannClient
	 */
	getMyplannClient = () => {
		return this._myplannClient;
	};

	UNSAFE_componentWillMount = function () {
		this._onMajetekSmazan = PubSub.subscribe('onMajetekSmazan', this.reload.bind(this));
		this._onMajetekPridan = PubSub.subscribe('onMajetekPridan', this.reload.bind(this));
		this._onMajetekProdan = PubSub.subscribe('onMajetekProdan', this.reload.bind(this));
	};

	componentWillUnmount = function () {
		PubSub.unsubscribe(this._onMajetekSmazan);
		PubSub.unsubscribe(this._onMajetekPridan);
		PubSub.unsubscribe(this._onMajetekProdan);
	};

	componentDidMount() {
		this.reload();
	}

	reload = () => {
		this.getMyplannClient().majetekKPronajmu().then(response => {
			if (response) {

				let showNames = false;
				let name = null;

				if (response.data && response.data.data && response.data.data.db) {
					response.data.data.db.forEach((result) => {

						if (name !== null && result.jmeno !== name) {
							showNames = true;
						}

						name = result.jmeno;

						this._dateHelper.reformatDates(result);
						this._dateHelper.reformatDates(result.formData);
					});
				}
				this.setState({majetkyKPronajmu: response.data.data.db, showNames});
			}
		});
	};

	confirmMajetek = (e, index) => {
		if (!this._uzivatel.readAccessOnly()) {
			let m = this.state.majetkyKPronajmu[index].formData;

			if (m && m.id) {
				this._client.__dokumenty_index(undefined, undefined, m.id).then((result) => {
					if (result) {
						m.documents = result.data.data.db;

						this.setState({
							activeItem: m,
							success: null
						});
					}
				});
			} else {
				this.setState({
					activeItem: m,
					success: null
				});
			}
		}
	};

	confirmMajetekFail = (e) => {
		this.setState({
			activeItem: null,
			typ: null
		});
	};

	confirmMajetekSuccess = (e) => {
		this.pronajemMajetek(this.state.activeItem).then(r => {
		});
	};

	onReject = (error) => {
		if (error.response && error.response.data && error.response.data.exception) {
			this.setState({
				msg: error.response.data.exception.message,
				activeItem: null,
			});
		}
		return undefined;
	};

	pronajemMajetek = (majetekKPronajmu) => {
		return this.getMyplannClient().pronajmyMajetek(majetekKPronajmu.id, this.state.typ).then(response => {
			if (response) {
				if (response.data.data.success) {
					this.reload();

					this.setState({
						activeItem: null,
						typ: null,
						success: "Nový pronájem majetku byl vytvořen."
					});

					PubSub.publish('onMajetekPronajat', {data: this.state.activeItem});
				}
			}
			return response;
		}).catch(e => {
			this.onReject(e);
		});
	};

	onTypeSelected = (e, type) => {
		this.setState({
			typ: type,
			msg: null
		});
	};

	render = () => {

		let type = null;
		if (this.state.activeItem && this.state.activeItem.druh) {
			type = getPropertyAssetTypeIDFromName(this.state.activeItem.druh);
		}

		const Form = type !== null && propertyAssetsTypes[type].form;

		return (
			<div className={'ProdejMajetkuGrid'}>
				<h1 className={"padding--right--25"}>Pronájem nemovitostí</h1>
				<p className={"padding--bottom--20"}>Kliknutím na daný záznam vyberte majetek k pronájmu.</p>

				{this.state.majetkyKPronajmu === undefined || this.state.majetkyKPronajmu.length <= 0 ?
					<p className={"error"}>
						Nemáte žádný majetek vhodný k pronájmu.
					</p> : ""}

				{this.state.msg !== null ?
					<p className={"error padding--bottom--10 text-weight--400"}>Chyba: {this.state.msg}</p>
					:
					''
				}

				{this.state.success !== null ?
					<div className={"padding--bottom--20 padding--top--10"}>
						<p className={"success"}>{this.state.success}</p>
					</div>
					: ""
				}

				<div
					className="responsive-table responsive-table--sm responsive-table--first-icon responsive-table--second-next-first">
					{this.state.majetkyKPronajmu.map((item, index) => {
						return (

							<div
								className={"tr table-row pointer" + (this._uzivatel.readAccessOnly() ? " cursor-not-allowed" : "")}
								key={index} onClick={(e) => {
								this.confirmMajetek(e, index);
							}}>
								<div className="td td--width--30">
									<div className={`icon oblm`}>M</div>
								</div>
								<div className={"td text-nowrap"}>
									{item.druh_nazev}
								</div>
								<div className={"td text-ellipsis property-table__address"}>
									{item.lokalita}
								</div>
								<div className={"td text-nowrap"}>
									{item.datum_koupeno}
								</div>

								{this.state.showNames === true ?
									<div className="td text-nowrap--big-screen">
										{item.jmeno}
									</div>
									: ""}
							</div>
						);
					})}
				</div>

				<Modal
					isOpen={this.state.activeItem && this.state.typ === null ? true : false}
					onRequestClose={this.confirmMajetekFail.bind(this)}
				>
					{this.state.activeItem ?
						<div>
							<h1>Pronájem</h1>
							<p>Vyberte typ pronájmu.</p>
							<ul className="links-center top-space--25">
								<li className={'popis-pronajmu-wrapper'}>
									<span className="links-center-a" onClick={(e) => {
										this.onTypeSelected(e, 'garantovany');
									}}>
										Garantovaný pronájem
										<div className={'text-left text-black padding--left--25 padding--right--25'}>
											<p>
												<b>Majitel inkasuje nájem, ne pronájem</b><br/>Byt si od majitele pronajmeme a:
											</p>
											<ul className={"list"}>
												<li>pojistíme proti riziku poškození nájemníkem (platí podnájemník)</li>
												<li>zpracujeme profesionální fotky a prezentaci</li>
												<li>podnajímáme podnájemníkům, které prověříme v registru dlužníků</li>
												<li>zajišťujeme opravy</li>
												<li>vyúčtování a veškerou administrativu</li>
											</ul>
											<p>Majitel tak inkasuje dohodnuté nájemné, které se rovná 85 % tržního nájemného, i když je byt neobsazený, nebo nájemníci neplatí.</p>
										</div>
									</span>
								</li>
								<li className={'popis-pronajmu-wrapper'}>
									<span className="links-center-a" onClick={(e) => {
										this.onTypeSelected(e, 'kratkodoby');
									}}>
										Krátkodobý pronájem
										<div className={'text-left text-black padding--left--25 padding--right--25'}>
											<p>Při krátkodobém pronájmu inkasujete až o 100 % vyšší nájemné oproti dlouhodobým pronájmům. S kompletním servisem, bez starostí.</p>
											<p>Byt si od majitele pronajmeme a:</p>
											<ul className={"list"}>
												<li>pojistíme proti riziku poškození nájemníkem</li>
												<li>zpracujeme profesionální fotky a prezentaci</li>
												<li>zřídíme inzertní profily s vynikajícím hodnocením na 20 nejnavštěvovanějších rezervačních portálech</li>
												<li>zajistíme maximální obsazenost a výnosy díky dalším inzertním kanálům (vlastní stránky a spolupráce s mezinárodními agenturami a cestovními kancelářemi)</li>
												<li>ubytujeme hosty a pravidelně kontrolujeme stav bytů</li>
												<li>zajišťujeme úklidy, praní prádla a ručníků v profesionální prádelně</li>
												<li>zajišťujeme opravy a pravidelnou údržbu</li>
												<li>zasíláme pravidelné měsíční finanční reporty o výnosech</li>
											</ul>
											<p>Kompletní služba – 25 % z vybraného nájemného.</p>
										</div>
									</span>
								</li>
							</ul>
						</div>
						:
						''
					}
				</Modal>

				<Modal
					isOpen={this.state.activeItem && this.state.typ !== null ? true : false}
					onRequestClose={this.confirmMajetekFail.bind(this)}
				>
					{this.state.activeItem ?
						<div>
							<h1>Chcete opravdu pronajmout tento majetek?</h1>

							{Form ? <Form onSubmit={(item) => {
								}}
										  onFormChange={(item) => {
										  }}
										  asset={{
											  disabled: true,
											  id: this.state.activeItem.id,
											  type: this.state.activeItem.druh,
											  data: this.state.activeItem,
											  documents: this.state.activeItem.documents
										  }}/>
								:
								""
							}

							<div className={"modal-form-table__buttons"}>
								<span className={'btn btn--gray padding--right--10 mr-10'}
								   onClick={this.confirmMajetekFail}>Zpět</span>
								<span className={'btn'} onClick={this.confirmMajetekSuccess}>Pronajmout</span>
							</div>
						</div>
						:
						''
					}
				</Modal>
			</div>
		);
	};
}
