import React, {Component} from "react";
import HomePortfolio from './../Home/HomePortfolio';
import Majetek from './Majetek';
import Documents from './Documents';

import Graf from "./Graf";
import {getContainer} from "../../utils/generic";
import * as PubSub from "pubsub-js";
import ForceUpdateScrollbars from "../ForceUpdateScrollbars/ForceUpdateScrollbars";
import Nakupy from "./Nakupy";

export let grafyCache = {
	oblastiGrafData: {
		raw: {},
		data: [
			['', '']
		],
	},
	smlouvyGrafData: {
		raw: {},
		data: [
			['', '']
		],
	},
	smlouvyColors: {},
	oblastiColors: {},
	grafyPovoleny: false,
};

let documentsShowButtonCache = "Zobrazit více";

class Portfolio extends Component {
	constructor(props) {
		super(props);
		this.state = {
			items: [],
			modalIsOpen: false,
			activeItem: null,
			oblastiGrafData: grafyCache.oblastiGrafData,
			smlouvyGrafData: grafyCache.smlouvyGrafData,
			smlouvyColors: grafyCache.smlouvyColors,
			oblastiColors: grafyCache.oblastiColors,
			grafyPovoleny: grafyCache.grafyPovoleny,
			documentsShowButton: documentsShowButtonCache,
            openFileWizard: false,
            dokumentyModalIsOpen: false,
		};
		this._uzivatel = getContainer().getUzivatel(); //props.di('uzivatel');
	}

	UNSAFE_componentWillMount = function () {
		this._reloadChartsByDeletingContract = PubSub.subscribe('smazatSmlouvu', this.reloadCharts.bind(this));
		this._reloadChartsByDeletingProperty = PubSub.subscribe('smazatMajetek', this.reloadCharts.bind(this));
	};

	componentWillUnmount = function () {
		PubSub.unsubscribe(this._reloadChartsByDeletingContract);
		PubSub.unsubscribe(this._reloadChartsByDeletingProperty);
	};

	reloadCharts = function (msg, data) {
		this.getGrafyData();
	};

	componentDidMount() {
		this._client = getContainer().getMyplannClient();
		this.getGrafyData();
		this.reload();
	}

	reload = () => {
	};

	setDocumentsShowButton = function (documentsShowButton) {
		this.setState({
			documentsShowButton: documentsShowButton
		});

		documentsShowButtonCache = documentsShowButton;
	};

	getGrafyData = () => {
		this._client.getGrafyData().then((finalData) => {
			this.setState({
				smlouvyGrafData: finalData.smlouvy,
				smlouvyColors: finalData.smlouvyColors,
				oblastiGrafData: finalData.oblasti,
				oblastiColors: finalData.oblastiColors,
				grafyPovoleny: finalData.grafyPovoleny
			});

			grafyCache.smlouvyGrafData = finalData.smlouvy;
			grafyCache.smlouvyColors = finalData.smlouvyColors;
			grafyCache.oblastiGrafData = finalData.oblasti;
			grafyCache.oblastiColors = finalData.oblastiColors;
			grafyCache.grafyPovoleny = finalData.grafyPovoleny;
		});
	};

	zobrazitGrafy = () => {
		return this.state.grafyPovoleny === true;
	};

	openDocumentsAdditionModal = (scrollToDokumentyForm = false, openFileWizard : boolean = false) => {
		if (scrollToDokumentyForm) {
			setTimeout(() => {
				PubSub.publish('scrollToDokumentyForm'); //proč se to neřeší přes propsy ?
			}, 500);
		}

		this.setState({
            openFileWizard: openFileWizard,
            dokumentyModalIsOpen: true,
		});
	};

    dokumentyModalClosed() {
        this.setState({
            openFileWizard: false,
            dokumentyModalIsOpen: false,
        });
    }

	render() {
		return (
			<div className="portfolio-page">
				<div className="container">
					<div className="row row--smaller-spaces align-items-start">
						<div className="col-lg-4 col-md-5 order-md-0 order-2 cell--md-margin">
								<div className="cell cell--without-top-padding cell--half-height">
									<h1 className={"cell__relative-title"}>Hodnota portfolia</h1>
                  {this.zobrazitGrafy() === true ?
									<ForceUpdateScrollbars
										className="cell--half-height__scrollbox scrollbox--md--disabled"
										autoHeight
										autoHeightMin={0}
										autoHeightMax={1500}
										forceupdaterendertimer={1000}
										identifier={'portfolio-scrollbar-hodnota-portfolia'}
									>
										<div className={"portfolio-graphs"}>
											{this.state.smlouvyGrafData.data.length > 1 ?
												<div id={'graf-smlouvy-wrapper'}>
													<div className={'overlay'}>
														<table>
															<tbody>
															<tr>
																<td><span
																	className={'text-nowrap text-big'}>{this.state.smlouvyGrafData.raw.celkem.celkem} Kč</span>
																	<br/><span className={'text-gray text-small'}>Celková výše</span>
																</td>
															</tr>
															</tbody>
														</table>
													</div>
													<Graf id={'graf-smlouvy'} data={this.state.smlouvyGrafData.data}
														  colors={this.state.smlouvyColors} title={""}/>
												</div>
												:
                        <div id={'graf-smlouvy-wrapper'}>
                          <div className={'overlay'}>
                            <table>
                              <tbody>
                              <tr>
                                <td><span
                                  className={'text-nowrap text-big'}></span>
                                  <br/><span className={'text-gray text-small'}>Celková výše</span>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                          <Graf id={'graf-smlouvy'} data={[["Oblast","Kč",{"role":"tooltip"}],["",1,""]]}
                                colors={["#d2d2d2"]} title={""}/>
                        </div>
											}


											{this.state.oblastiGrafData.data.length > 1 ?
												<div id={'graf-oblasti-wrapper'}>
													<div className={'overlay'}>
														<table>
															<tbody>
															<tr>
																<td><span
																	className={'text-nowrap text-big'}>{this.state.oblastiGrafData.raw.celkem.celkem} Kč</span><br/>
																	<span className={'text-gray text-small'}>Celková výše</span><br/>
																	<span className={'text-small text-nowrap'}>majetek ‒ závazky</span>
																</td>
															</tr>
															</tbody>
														</table>
													</div>
													<Graf id={'graf-oblasti'} data={this.state.oblastiGrafData.data}
														  colors={this.state.oblastiColors} title={""}/>
												</div>
												:
                        <div id={'graf-smlouvy-wrapper'}>
                          <div className={'overlay'}>
                            <table>
                              <tbody>
                              <tr>
                                <td><span
                                  className={'text-nowrap text-big'}></span><br/>
                                  <span className={'text-gray text-small'}>Celková výše</span><br/>
                                  <span className={'text-small text-nowrap'}>majetek ‒ závazky</span>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                          <Graf id={'graf-oblasti'} data={[["Oblast","Kč",{"role":"tooltip"}],["",1,""]]}
                                colors={["#d2d2d2"]} title={""}/>
                        </div>
											}
										</div>
									</ForceUpdateScrollbars>
                    : ''}
								</div>

							<div className={"cell cell--half-height cell--margin"}>
								<h1 className={'padding--bottom--10'}>
									Dokumenty
									{this._uzivatel.readAccessOnly() ? "" :
										<a href="#dummy" className={"add"} onClick={(e) => {
											e.preventDefault();
											this.openDocumentsAdditionModal(true, true);
										}}><i className={"fa fa-plus"}></i></a>
									}
								</h1>

								<ForceUpdateScrollbars
									className="cell--half-height__scrollbox scrollbox--md--disabled"
									autoHeight
									autoHeightMin={0}
									autoHeightMax={1500}
									forceupdaterendertimer={1000}
									identifier={'portfolio-scrollbar-dokumenty-1'}
								>
									<Documents match={this.props.match} showAllBtn={true}
											   scrollbarIdentifier={'portfolio-scrollbar-dokumenty-1'}
											   setDocumentsShowButton={this.setDocumentsShowButton.bind(this)}
												openFileWizard={this.state.openFileWizard}
                                               modalIsOpen={this.state.dokumentyModalIsOpen}
                                               dokumentyModalClosed={this.dokumentyModalClosed.bind(this)}
									/>

								</ForceUpdateScrollbars>

								<div className="btn width--100--percent text-center top-space--5--important"
									 onClick={(e) => {
										 e.preventDefault();
										 this.openDocumentsAdditionModal();
									 }}>
									{this.state.documentsShowButton}
								</div>
							</div>
						</div>
						<div className="col-lg-8 col-md-7">
							<div className="cell cell--full-height">
								<ForceUpdateScrollbars
									className="cell--full-height__scrollbox scrollbox--md--disabled"
									autoHeight
									autoHeightMin={0}
									autoHeightMax={1500}
									forceupdaterendertimer={1000}
									identifier={'portfolio-scrollbar-dokumenty-2'}
								>
									<HomePortfolio match={this.props.match} withoutServices={true}
												   scrollbarIdentifier={'portfolio-scrollbar-dokumenty-2'}/>

									<div className="top-space--45">
										<Majetek match={this.props.match}
												 scrollbarIdentifier={'portfolio-scrollbar-dokumenty-2'}/>
									</div>
                  {/*Poptavky po majetku - schovavam sekci - nevim za se bude pouzivat*/}
									{/*<div className="top-space--45">*/}
									{/*	<Nakupy match={this.props.match}*/}
									{/*			scrollbarIdentifier={'portfolio-scrollbar-dokumenty-2'}/>*/}
									{/*</div>*/}

									<div className="top-space--45">
										<HomePortfolio match={this.props.match} onlyServices={true}
													   scrollbarIdentifier={'portfolio-scrollbar-dokumenty-2'}/>
									</div>
								</ForceUpdateScrollbars>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Portfolio;
