import React, {Component} from "react";
import Header from "../Header";
import {parse_query_string} from "../../utils/urlparse";
import {getContainer} from "../../utils/generic";
import Gold from "./Gold";
import Blue from "./Blue";


export default class Gdpr extends Component {

  constructor(props) {
    super(props);
    this._client = getContainer().getMyplannClient();
    this.state = {
      token: parse_query_string(props.location.search.substring(1)).token,
      validToken: undefined,
      type: undefined,
      done: undefined,
      goldData: undefined,
      email: undefined
    };
  }

  componentDidMount() {
    this.reload();
    this.setState({showHtmlDetail: true});
    this._client.validateGdprToken(this.state.token).then((res) => {
      this.setState({validToken: res});
    });
  }

  reload = () => {
    this._client.getGdprData(this.state.token).then(response => {
      if (response) {
        console.log('response to state', response.data);
        this.setState({type: response.data.data.type, done: response.data.data.confirmation, email: response.data.data.email, goldData: response.data.data.gold});
      }
    });
  };

  render = () => {


    if(this.state.validToken === undefined) {
      return (
        <div>
          <Header showPartnerLogo={true}/>
          <div className="container">
            <div className="row row--smaller-spaces align-items-start justify-content-md-center">
              <div className={'col-md-8'}>
                <div className="cell validation">
                  <div style={{margin: '0 auto'}} className={'local-spinner'}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (!this.state.validToken) {
      return <div>
        <Header showPartnerLogo={true}/>
        <div className="container">
          <div className="row row--smaller-spaces align-items-start justify-content-md-center">
            <div className={'col-md-8'}>
              <div className="cell validation">
                <h1 style={{margin: 20}} className="text-red">Souhlas nebyl nalezen nebo byl již podepsán</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    }

    if(this.state.type === 'zlaty' && !this.state.done) {
      return <div>
        <Header showPartnerLogo={true}/>
        <div className="container">
          <div className="row row--smaller-spaces align-items-start justify-content-md-center">
            <div className={'col-md-8'}>
              <div className="cell validation">
                <Gold data={this.state.goldData} token={this.state.token} onDone={() => this.reload()} />
              </div>
            </div>
          </div>
        </div>
      </div>
    }

    if(this.state.type === 'modry' && !this.state.done) {
      return <div>
        <Header showPartnerLogo={true}/>
        <div className="container">
          <div className="row row--smaller-spaces align-items-start justify-content-md-center">
            <div className={'col-md-8'}>
              <div className="cell validation">
                <Blue email={this.state.email} token={this.state.token} onDone={() => this.reload()} />
              </div>
            </div>
          </div>
        </div>
      </div>
    }


    return null;
  }
}
