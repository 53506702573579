import React, {Component} from "react";
import Autosuggest from "react-autosuggest";
import {getContainer} from "../../../utils/generic";

class AutocompleteProdukt extends Component {
	constructor(props) {
		super(props);
		this._client = getContainer().getMyplannClient();
		this.state = {
            filterSuggestions: false,
			produkty: [],
			value: props.value ? props.value : '',
			suggestions: [], // generuji se z pole produkty
			producentiMap: {}
		};
	}

	componentDidMount() {
		this._mounted = true;
	}

	componentWillUnmount() {
		this._mounted = false;
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.selectedProducentSuggestion) {
            this.setState({
				produkty: nextProps.selectedProducentSuggestion.produkty.select,
                filterSuggestions: false,
            });
        }
	}

	onChange = (event, {newValue}) => {
		this.setState({
			value: newValue,
            filterSuggestions: true
		});
		this.changeFormValue(newValue);
	};

	onSuggestionSelected = (event, {suggestion}) => {
		this.setState({
			selected: suggestion,
            filterSuggestions: false,
		});
	};

	changeFormValue = (value) => {
		let e = {
			target: {
				name: this.props.name,
				value: value
			}
		};

		this.props.onChange(e);
	};

    onlyUnique(value, index, self) {
		let first = null;
		for(let key in self){
            if (self.hasOwnProperty(key)) {
                if(self[key].label.toLowerCase() === value.label.toLowerCase()){
                    first = key *1;
                    break;
                }
            }
		}
		return first === (index *1);
    }

	onSuggestionsFetchRequested = ({value}) => {
		let inputValue = value.trim().toLowerCase();
		let inputLength = inputValue.length;
		let suggestions =
			inputLength === 0 || !this.state.filterSuggestions
				? this.state.produkty.filter(this.onlyUnique).filter((s) => true)
				: this.state.produkty.filter(this.onlyUnique).filter(
				s => s.label.toLowerCase().indexOf(inputValue) !== -1
				);
		this.setState({suggestions});
	};

	onSuggestionsClearRequested = () => {
	};

	onFocus = () => {
        this.setState({
            filterSuggestions: false,
        });
	};

	onBlur = () => {
        this.setState({
            filterSuggestions: false,
        });
	};

	render() {
		let inputProps = {
			placeholder: "",
			value: this.state.value,
			onChange: this.onChange,
			onFocus: this.onFocus,
			onBlur: this.onBlur,
			name: this.props.name
		};
		return (
			<Autosuggest
				suggestions={this.state.suggestions}
				onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
				onSuggestionsClearRequested={this.onSuggestionsClearRequested}
				getSuggestionValue={suggestion => suggestion.label}
				renderSuggestion={suggestion => <div>{suggestion.label}</div>}
				inputProps={inputProps}
				onSuggestionSelected={this.onSuggestionSelected}
				shouldRenderSuggestions={() => true}
			/>
		);
	}
}

export default AutocompleteProdukt;
