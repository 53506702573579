import React, {Component} from "react";
import Autosuggest from "react-autosuggest";
import SmartformClientRequest from "../../../services/SmartformClientRequest";
import SmartformClientResponse from "../../../services/SmartformClientResponse";
import {getContainer} from "../../../utils/generic";

export default class SmartformAdresa extends Component {

	constructor(props) {
		super(props);
		this._client = getContainer().getSmartformClient(); // props.di("smartformClient");
		this.state = {
			response: undefined,
			activeField: null,
			suggestions: {
				STREET_AND_NUMBER: [],
				CITY: [],
				ZIP: [],
				COUNTRY: [],
			},
			STREET_AND_NUMBER: props.STREET_AND_NUMBER ? props.STREET_AND_NUMBER : "",
			CITY: props.CITY ? props.CITY : "",
			ZIP: props.ZIP ? props.ZIP : "",
			COUNTRY: "CZ",
			PART_OF_TOWN: props.PART_OF_TOWN ? props.PART_OF_TOWN : ""
		};
		this.disabled = props.disabled ? props.disabled : false;
	}

	componentWillUnmount() {
		this._client.cancelRequests();
	}

	refresh = () => {
		let _self = this;
		let request = new SmartformClientRequest();
		request.setCombination2(
			this.state.STREET_AND_NUMBER,
			this.state.CITY,
			this.state.ZIP,
			this.state.COUNTRY
		);
		request.setFieldType(this.state.activeField); // co se zrovna edituje

		this._client.sendRequest(request).then((response: SmartformClientResponse) => {
			if (response.isOk()) {
				_self.setState({
					response: response,
					suggestions: {
						STREET_AND_NUMBER: response.getInputSuggestionsFor("STREET_AND_NUMBER", "WHOLE_ADDRESS"),
						CITY: response.getInputSuggestionsFor("CITY"),
						ZIP: response.getInputSuggestionsFor("ZIP"),
						COUNTRY: response.getInputSuggestionsFor("COUNTRY"),
					}
				});
			}
		}).catch((err) => {
			throw err;
		});
	};

	UNSAFE_componentWillReceiveProps(props) {
		if (props) {
			this.setState({
				STREET_AND_NUMBER: props.STREET_AND_NUMBER ? props.STREET_AND_NUMBER : "",
				CITY: props.CITY ? props.CITY : "",
				ZIP: props.ZIP ? props.ZIP : "",
				PART_OF_TOWN: props.PART_OF_TOWN ? props.PART_OF_TOWN : ""
			});
		}
	}

	onChange = (e) => {
		let name = e.target.name;
		this.setState({
			activeField: name
		});
		this.props.onChange({
			target: {
				name: this.getFieldSynonymum(name),
				value: e.target.value
			}
		});
	};

	getFieldSynonymum = (name) => {
		let synonyma = {
			"STREET_AND_NUMBER": "ulice",
			"CITY": "obec",
			"ZIP": "psc",
		};
		return synonyma[name] ? synonyma[name] : name;
	};

	onSuggestionsFetchRequested = (data, inputName) => {
		let value = data.value;
		this.props.onSuggestionMenuOpened({
			target: {
				name: this.getFieldSynonymum(inputName),
				value: value
			}
		});

		let inputValue = value.trim();
		let inputLength = inputValue.length;

		if (inputValue !== this.state[inputName] || inputName !== this.state.activeField) {
			this.setState({
				[inputName]: inputValue,
				activeField: inputName
			});

			if (inputLength > 0) {
				this.refresh();
			} else {
				this.onSuggestionsClearRequested();
			}
		}
	};

	onSuggestionsClearRequested = (event) => {
		this.props.onSuggestionMenuClosed(event);
	};

	onSuggestionSelected = (event, {suggestion}) => {
		let values = this.state.response.getSuggestionValuesByIndex(suggestion.index);
		this.props.onChange({
			target: {
				name: this.getFieldSynonymum('STREET_AND_NUMBER'),
				value: values['STREET_AND_NUMBER']
			}
		});
		this.props.onChange({
			target: {
				name: this.getFieldSynonymum('CITY'),
				value: values['CITY']
			}
		});
		this.props.onChange({
			target: {
				name: this.getFieldSynonymum('ZIP'),
				value: values['ZIP']
			}
		});
		// this.props.onChange({
		//     target: {
		//         name: this.getFieldSynonymum('COUNTRY'),
		//         value: values['COUNTRY']
		//     }
		// });
	};

	onSuggestionHighlighted = (event) => {
	};

	render() {
		return (
			<tbody>
			<tr>
				<td>Ulice a čp.</td>
				<td>
					{this.disabled ?
						<input type="text" value={this.state.STREET_AND_NUMBER} disabled={true}/>
						:
						<Autosuggest
							suggestions={this.state.suggestions['STREET_AND_NUMBER']}
							onSuggestionsFetchRequested={(data) => this.onSuggestionsFetchRequested(data, 'STREET_AND_NUMBER')}
							onSuggestionsClearRequested={this.onSuggestionsClearRequested}
							onSuggestionHighlighted={this.onSuggestionHighlighted}
							getSuggestionValue={suggestion => suggestion.value}
							renderSuggestion={suggestion => <div>{suggestion.label}</div>}
							inputProps={{
								placeholder: "",
								value: this.state.STREET_AND_NUMBER,
								onChange: this.onChange,
								name: 'ulice',
								autoComplete: "off"
							}}
							onSuggestionSelected={this.onSuggestionSelected}
							shouldRenderSuggestions={() => true}
							disabled={this.disabled}
						/>
					}
				</td>
			</tr>
			<tr>
				<td>Obec</td>
				<td>
					{this.disabled ?
						<input type="text" value={this.state.CITY} disabled={true}/>
						:
						<Autosuggest
							suggestions={this.state.suggestions['CITY']}
							onSuggestionsFetchRequested={(data) => this.onSuggestionsFetchRequested(data, 'CITY')}
							onSuggestionsClearRequested={this.onSuggestionsClearRequested}
							onSuggestionHighlighted={this.onSuggestionHighlighted}
							getSuggestionValue={suggestion => suggestion.value}
							renderSuggestion={suggestion => <div>{suggestion.label}</div>}
							inputProps={{
								placeholder: "",
								value: this.state.CITY,
								onChange: this.onChange,
								name: 'obec',
								autoComplete: "off"
							}}
							onSuggestionSelected={this.onSuggestionSelected}
							shouldRenderSuggestions={() => true}
						/>
					}
				</td>
			</tr>
			<tr>
				<td>PSČ</td>
				<td>
					{this.disabled ?
						<input type="text" value={this.state.ZIP} disabled={true}/>
						:
						<Autosuggest
							suggestions={this.state.suggestions['ZIP']}
							onSuggestionsFetchRequested={(data) => this.onSuggestionsFetchRequested(data, 'ZIP')}
							onSuggestionsClearRequested={this.onSuggestionsClearRequested}
							onSuggestionHighlighted={this.onSuggestionHighlighted}
							getSuggestionValue={suggestion => suggestion.value}
							renderSuggestion={suggestion => <div>{suggestion.label}</div>}
							inputProps={{
								placeholder: "",
								value: this.state.ZIP,
								onChange: this.onChange,
								name: 'psc',
								autoComplete: "off",
								maxLength: 6,
							}}
							onSuggestionSelected={this.onSuggestionSelected}
							shouldRenderSuggestions={() => true}
							disabled={this.disabled}
						/>
					}
				</td>
			</tr>
			</tbody>
		);
	}
}
