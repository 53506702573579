import React, {Component} from "react";
import {Link, Redirect} from "react-router-dom";
import Adviser from './../Adviser/Adviser';
import HomePortfolio from './../Home/HomePortfolio';
import HomeIncidents from './../Home/HomeIncidents';
import {logout} from "../../utils/Api";
import ForceUpdateScrollbars from './../ForceUpdateScrollbars/ForceUpdateScrollbars';
import * as PubSub from "pubsub-js";
import Events from "../Incidents/Events";
import PronajemMajetkuGrid from "../Portfolio/majetek/PronajemMajetkuGrid";
import NakupAssetWizard from "../Portfolio/forms_nakup_majetku/NakupAssetWizard";
import ProdejMajetkuGrid from "../Portfolio/majetek/ProdejMajetkuGrid";
import {getContainer, modalBodyScrollPrevent} from "../../utils/generic";
import Modal from "../utils/Modal/Modal";
import Graf from "../Portfolio/Graf";
import {grafyCache} from "../Portfolio/Portfolio";
import HomeLeadovac from "./HomeLeadovac";

class Home extends Component {

	constructor(props) {
		super(props);

    this._myplannClient = getContainer().getMyplannClient();

		let allowNakupMajetku = sessionStorage.getItem('allow_nakup_majetku');
		if (allowNakupMajetku === "true") {
			allowNakupMajetku = true;
		} else if (allowNakupMajetku === "false") {
			allowNakupMajetku = false;
		}

		this.state = {
			prodejMajetku: false,
			nakupMajetku: false,
			pronajemMajetku: false,
			modalIsOpen: false,
      oblastiGrafData: grafyCache.oblastiGrafData,
      smlouvyGrafData: grafyCache.smlouvyGrafData,
      smlouvyColors: grafyCache.smlouvyColors,
      oblastiColors: grafyCache.oblastiColors,
      grafyPovoleny: grafyCache.grafyPovoleny,
			allowNakupMajetku,
      initialized: false,
      leadovace: []
		};

	}

	reload = () => {
		if (this.state.allowNakupMajetku === null) {
			this._myplannClient.getMplanConfiguration().then(
				response => {
					if (response.allowNakupMajetku) {
						this.setState({allowNakupMajetku: response.allowNakupMajetku});
					}
				}
			);
		}
	};

	getLeadovace = () => {
	  let self = this;
    this._client.getLeadovace().then(response => {
      if (response) {
        if(response.status === 'ok') {
          self.setState({initialized: true, leadovace: response.items});
        } else {
          self.setState({initialized: true});
        }

      } else {
        self.setState({initialized: true});
      }
    });
  }

  zobrazitGrafy = () => {
    return this.state.grafyPovoleny === true;
  };

	componentDidMount() {
    this._client = getContainer().getMyplannClient();
    this.getLeadovace();
    this.getGrafyData();
		this.reload();
	}

	componentDidCatch(error, info) {
		// Display fallback UI
		this.setState({hasError: true});
		logout();
	}

	fakeUpdateState = () => {
		this.setState({
			rnd: Math.random()
		})
	};

	redirect = (link) => {
		this.setState({
			redirect: link
		});
	};

	UNSAFE_componentWillMount = function () {
		this._reloadHomeToken = PubSub.subscribe('reloadHome', this.fakeUpdateState);
	};

	componentWillUnmount = function () {
		PubSub.unsubscribe(this._reloadHomeToken);
	};

	toggleModal_prodejMajetku = () => {
		modalBodyScrollPrevent(!this.state.prodejMajetku, "prodejMajetku");
		this.setState({prodejMajetku: !this.state.prodejMajetku})
	};

	toggleModal_nakupMajetku = () => {
		modalBodyScrollPrevent(!this.state.nakupMajetku, "nakupMajetku");
		this.setState({nakupMajetku: !this.state.nakupMajetku});
	};

	toggleModal_pronajemMajetku = () => {
		modalBodyScrollPrevent(!this.state.pronajemMajetku, "pronajemMajetku");
		this.setState({pronajemMajetku: !this.state.pronajemMajetku});
	};

  getGrafyData = () => {
    this._client.getGrafyData().then((finalData) => {
      this.setState({
        smlouvyGrafData: finalData.smlouvy,
        smlouvyColors: finalData.smlouvyColors,
        oblastiGrafData: finalData.oblasti,
        oblastiColors: finalData.oblastiColors,
        grafyPovoleny: finalData.grafyPovoleny
      });

      grafyCache.smlouvyGrafData = finalData.smlouvy;
      grafyCache.smlouvyColors = finalData.smlouvyColors;
      grafyCache.oblastiGrafData = finalData.oblasti;
      grafyCache.oblastiColors = finalData.oblastiColors;
      grafyCache.grafyPovoleny = finalData.grafyPovoleny;
    });
  };

	render = () => {
		if (this.state.redirect) {
			return <Redirect to={this.state.redirect}/>;
		}

		if(!this.state.initialized) {
      return(
        <div className="loading-overlay loading-overlay-show loading-overlay-process">
          <img src="/img/spinner.gif" alt="loading..." />
        </div>
        );
    }


		return (
			<div className="home-page" key={this.state.rnd}>
				<div className="container">

					<div className="row row--smaller-spaces row--padding-bottom">
						<div className="col-lg-4 col-md-4">
							<div className="cell cell--adviser cell--half-height">
								<Adviser/>
							</div>
						</div>

						<div className="col-lg-5 col-md-5">
							<div className="cell cell--half-height cell--half-height--with-show-more-link cell--md-margin">
								<h1 className="cell-title-max-height">
									Moje termíny
								</h1>
								<ForceUpdateScrollbars
									className="cell--half-height__scrollbox scrollbox--md--disabled"
									autoHeight
									autoHeightMin={0}
									autoHeightMax={400}
									forceupdaterendertimer={1000}
									identifier={'home-scrollbar-vase-udalosti'}
								>
									<HomeIncidents match={this.props.match} small={true}
												   scrollbarIdentifier={'home-scrollbar-vase-udalosti'}/>
								</ForceUpdateScrollbars>
								<Link to={`${this.props.match.url}udalosti`} className={"show-more-link"}>Zobrazit
									všechny termíny</Link>
							</div>
						</div>

						<div className="col-lg-3 col-md-3 zaznamy">
							<div className="cell cell--half-height cell--md-margin">
								<h1 className="cell-title-max-height">
									Záznamy
								</h1>
								<ForceUpdateScrollbars
									className="cell--half-height__scrollbox scrollbox--md--disabled"
									autoHeight
									autoHeightMin={0}
									autoHeightMax={400}
									forceupdaterendertimer={1000}
									identifier={'home-scrollbar-zaznamy'}
								>
									<Events scrollbarIdentifier={'home-scrollbar-zaznamy'}/>
								</ForceUpdateScrollbars>
							</div>
						</div>
					</div>

          <div className="row row--smaller-spaces row--padding-bottom">
            <div className={this.state.leadovace.length > 0 ? "col-lg-5 col-md-8" : "col-lg-9 col-md-9"}>
              <HomePortfolio onlyContractNumber={this.state.leadovace.length > 0} match={this.props.match} small={true} perPage={5}/>
            </div>


          <div className={"col-lg-3" + (this.state.leadovace.length > 0 ? ' col-md-4' : ' col-md-3')}>
            <div className="cell cell--half-height cell--md-margin">
              <h1 className={"cell-title-max-height"}>Hodnota portfolia</h1>
              {this.zobrazitGrafy() === true ?
              <ForceUpdateScrollbars
                className="cell--half-height__scrollbox scrollbox--md--disabled"
                autoHeight
                autoHeightMin={0}
                autoHeightMax={1500}
                forceupdaterendertimer={1000}
                identifier={'portfolio-scrollbar-hodnota-portfolia'}
              >
                <div className={"portfolio-graphs"}>
                  {this.state.smlouvyGrafData.data.length > 1 ?
                    <div id={'graf-smlouvy-wrapper'}>
                      <div className={'overlay'}>
                        <table>
                          <tbody>
                          <tr>
                            <td><span
                              className={'text-nowrap text-big'}>{this.state.smlouvyGrafData.raw.celkem.celkem} Kč</span>
                              <br/><span className={'text-gray text-small'}>Celková výše</span>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <Graf id={'graf-smlouvy'} data={this.state.smlouvyGrafData.data}
                            colors={this.state.smlouvyColors} title={""}/>
                    </div>
                    :
                    <div id={'graf-smlouvy-wrapper'}>
                      <div className={'overlay'}>
                        <table>
                          <tbody>
                          <tr>
                            <td><span
                              className={'text-nowrap text-big'}></span>
                              <br/><span className={'text-gray text-small'}>Celková výše</span>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <Graf id={'graf-smlouvy'} data={[["Oblast","Kč",{"role":"tooltip"}],["",1,""]]}
                            colors={["#d2d2d2"]} title={""}/>
                    </div>
                  }


                  {this.state.oblastiGrafData.data.length > 1 ?
                    <div id={'graf-oblasti-wrapper'}>
                      <div className={'overlay'}>
                        <table>
                          <tbody>
                          <tr>
                            <td><span
                              className={'text-nowrap text-big'}>{this.state.oblastiGrafData.raw.celkem.celkem} Kč</span><br/>
                              <span className={'text-gray text-small'}>Celková výše</span><br/>
                              <span className={'text-small text-nowrap'}>majetek ‒ závazky</span>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <Graf id={'graf-oblasti'} data={this.state.oblastiGrafData.data}
                            colors={this.state.oblastiColors} title={""}/>
                    </div>
                    :
                    <div id={'graf-smlouvy-wrapper'}>
                      <div className={'overlay'}>
                        <table>
                          <tbody>
                          <tr>
                            <td><span
                              className={'text-nowrap text-big'}></span><br/>
                              <span className={'text-gray text-small'}>Celková výše</span><br/>
                              <span className={'text-small text-nowrap'}>majetek ‒ závazky</span>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <Graf id={'graf-oblasti'} data={[["Oblast","Kč",{"role":"tooltip"}],["",1,""]]}
                            colors={["#d2d2d2"]} title={""}/>
                    </div>
                  }
                </div>
              </ForceUpdateScrollbars>
                : ''}
            </div>
            </div>

            {this.state.leadovace.length > 0 && <div className="col-lg-4 col-md-12 cell--md-e-margin">
              <HomeLeadovac match={this.props.match} perPage={5}/>
            </div>}

            {false && <div className="col-lg-3 col-md-4 col--fixed-height cell--md-e-margin">
              <div className="cell cell--without-padding">
                <div className={'property-control'}>
                  <div className={'property-control__buttons'}>
                    {this.state.allowNakupMajetku ?
                      <div className={'property-control__buttons__button'}
                           onClick={this.toggleModal_prodejMajetku.bind(this)}>prodej</div>
                      :
                      <div
                        className={'property-control__buttons__button property-control__buttons__button--disabled'}
                        title={"Již brzy!"}>
                        prodej
                      </div>
                    }
                    {this.state.allowNakupMajetku ?
                      <div className={'property-control__buttons__button'}
                           onClick={this.toggleModal_nakupMajetku.bind(this)}>nákup</div>
                      :
                      <div
                        className={'property-control__buttons__button property-control__buttons__button--disabled'}
                        title={"Již brzy!"}>
                        nákup
                      </div>
                    }
                    {this.state.allowNakupMajetku ?
                      <div className={'property-control__buttons__button'}
                           onClick={this.toggleModal_pronajemMajetku.bind(this)}>pronájem</div>
                      :
                      <div
                        className={'property-control__buttons__button property-control__buttons__button--disabled'}
                        title={"Již brzy!"}>
                        pronájem
                      </div>
                    }
                  </div>
                  <Link className={'property-control__home'} to={"/portfolio"}>
                    <i className={'fa fa-home'}></i>
                  </Link>
                </div>
              </div>
            </div>}


					</div>
				</div>


				<Modal
					onRequestClose={this.toggleModal_prodejMajetku.bind(this)}
					isOpen={this.state.prodejMajetku}
				>
					<ProdejMajetkuGrid/>
				</Modal>

				<Modal
					onRequestClose={this.toggleModal_pronajemMajetku.bind(this)}
					isOpen={this.state.pronajemMajetku}
				>
					<PronajemMajetkuGrid/>
				</Modal>

				<div className="majetek-modal">
					<NakupAssetWizard
						asset={{id: null, type: null, data: null}}
						onSubmit={(item) => {
						}}
						onZrusit={(item) => {
						}}
						closeModal={this.toggleModal_nakupMajetku.bind(this)}
						modalIsOpen={this.state.nakupMajetku}
						resetAfterSubmit={true}
					/>
				</div>
			</div>
		);
	};
}

export default Home;
