// base app components

import React from "react";
import Routes from "./routes";

import "./styles/App.css";

const App = () => <Routes/>;

export default App;
