import React, {Component} from "react";
import * as PubSub from "pubsub-js";
import {getContainer} from "../../utils/generic";

class SendSms extends Component {

	constructor(props) {
		super(props);
		this._client = getContainer().getMyplannClient();
		this.state = {
			token: props.token ? props.token : "",
			smsToken: props.smsToken ? props.smsToken : "",
			smsSent: null,
			smsVerified: null,
			canBeSigned: null,
			canBeSignedPhoneNumber: '',
			inModal: props.inModal ? props.inModal : false,
		};

		this._dateHelper = getContainer().getDateHelper(); //props.di('dateHelper');
	}

	componentDidMount() {
		this._client.canBeSigned(this.state.token).then((res) => {
			if (res.status === "ALREADY SIGNED") {
				this.setState({
					canBeSigned: res.status,
					canBeSignedDate: this._dateHelper.reformatDate(res.date, false, true, "D. M. YYYY v HH:mm")
				});
			} else {
				this.setState({canBeSigned: res.status});

				if (res.status === true) {
					this.setState({canBeSignedPhoneNumber: res.phoneNumber});
				}
			}
		});
	}

	onClick = (e) => {
		e.preventDefault();
		this._client.sendSms(this.state.token).then((res) => {
			if (res === 201 || res === "CONCEPT") {
				this.setState({smsSent: true});
			} else {
				let smsSent = res;

				if (smsSent === 'WAIT') {
					smsSent = 'WAIT_FIRST_TRY'
				}

				this.setState({smsSent});
			}
		});
		return false;
	};

	onClickSendAgain = (e) => {
		e.preventDefault();
		this.setState({smsVerified: null, smsToken: ''});
		this._client.sendSms(this.state.token).then((res) => {
			if (res === null || res === 201 || res === "CONCEPT") {
				this.setState({smsSent: true});
			} else {
				this.setState({smsSent: res});
			}
		});
		return false;
	};

	onSubmit = (e) => {
		e.preventDefault();

		let code = e.target.elements.smsToken.value;
		code = code.replace(/\s/g, '');

		if (code.length > 0) {
			this._client.verifySms(code, this.state.token).then((res) => {
				this.setState({smsVerified: res, smsToken: '', smsSent: true,});
				if (res === true) {
					if (this.props.onSmsVerified) {
						this.props.onSmsVerified();
					}
					PubSub.publish('recordSigned', {target: this});
				}
			});
		} else {
			this.setState({smsVerified: 'MISSING CODE', smsSent: true,});
		}

	};

	onChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	renderSmsSignOutput() {
		if (this.state.canBeSigned === true || this.state.canBeSigned === "ALREADY SENT") {
			return (
				<div>
					{this.state.smsSent === 'WAIT_FIRST_TRY' ?
						<div className={'padding--bottom--15'}>
							<span className={'error'}>
								SMS nelze odeslat, jelikož od odeslání předchozí SMS neuběhlo alespoň půl minuty.
							</span>
						</div>
						: ''}
					<button onClick={this.onClick} className={"btn text-uppercase"}>Zaslat potvrzovací kód</button>
				</div>
			);
		}

		if (this.state.canBeSigned === "ALREADY SIGNED") {
			return <span
				className={"validation__info"}>Záznam byl podepsán{this.state.canBeSignedDate ? " " + this.state.canBeSignedDate : ""}.</span>
		}

		if (this.state.canBeSigned === "CAN NOT SIGN") {
			return <span
				className={"error"}>Záznam nelze podepsat, kontaktujte prosím svého poradce.</span>
		}

		if (this.state.canBeSigned === "INVALID PHONE") {
			return <span className={"error"}>Neplatné telefonní číslo.</span>
		}

		if (this.state.canBeSigned == null) {
			return <div className="local-spinner"></div>
		}
	}

	render = () => {
		return (
			<div>
				{this.state.smsSent == null || this.state.smsSent === 'WAIT_FIRST_TRY' ? (
					<span>
						{this.renderSmsSignOutput()}
					</span>
				) : this.state.smsSent === true || this.state.smsSent === 'WAIT' ? (
					<div>
						{this.state.smsVerified == null || this.state.smsVerified === 'INVALID SMS CODE' || this.state.smsVerified === 'MISSING CODE' ? (
							<form onSubmit={this.onSubmit}>
								<p className={"padding--bottom--10 text-darken-red text-right"}>
									Na vaše telefonní číslo {this.state.canBeSignedPhoneNumber} byla odeslána SMS s potvrzovacím kódem.<br/>
									Pro podepsání záznamu opište kód z SMS a potvrďte.
								</p>

								{this.state.smsSent === 'WAIT' ? (
									<div className='text-right'>
										<span className={"error"}>SMS nelze odeslat, jelikož od odeslání předchozí SMS neuběhlo alespoň půl minuty.</span>
									</div>
								) : ''}

								{this.state.smsVerified === 'INVALID SMS CODE' ? (
									<div className='text-right'>
										<span className={"error"}>Zadaný SMS klíč není platný.</span>
									</div>
								) : ''}

								{this.state.smsVerified === 'MISSING CODE' ? (
									<div className='text-right'>
										<span className={"error"}>Zadejte, prosím, SMS kód</span>
									</div>
								) : ''}

								<div className={"validation__form validation__form--right"}>
									<div className={"validation__form__input"}>
										<input inputMode={'numeric'} autoComplete={'one-time-code'} onChange={this.onChange} placeholder={"kód"} name={'smsToken'}
											   type="text" value={this.state.smsToken}/>
									</div>
									<div className={"validation__form__btn"}>
										<input type={"submit"} className={"btn text-uppercase"} value={"Podepsat záznam"}/>
									</div>
								</div>
								<div className='width--100--percent text-right text-gray padding--top--5'>
									<button onClick={this.onClickSendAgain} className='gray-link pointer btn--text-link'>zaslat znovu</button>
								</div>
							</form>
						) : this.state.smsVerified === true ? (
							<span
								className={"success"}>Záznam byl úspěšně ověřen a podepsán. {!this.state.inModal ? "Nyní můžete stránku zavřít." : ""}</span>
						) : this.state.smsVerified === 'SYSTEM ERROR' ? (
							<div>
								<span className={"error"}>Došlo k chybě při zpracování požadavku, kontaktujte svého poradce.</span>
								<button onClick={this.onClickSendAgain}
										className={"btn top-space--15--important"}>Poslat nový SMS klíč
								</button>
							</div>
						) : this.state.smsVerified === 'CAN NOT SIGN' ? (
							<div>
								<span className={"error"}>Záznam nelze podepsat, kontaktujte prosím svého poradce.</span>
							</div>
						) : (
							<div>
								<span className={"error"}>Došlo k chybě při zpracovávání požadavku.</span>
								<button onClick={this.onClickSendAgain}
										className={"btn top-space--15--important"}>Poslat nový SMS klíč
								</button>
							</div>
						)}
					</div>
				) : (
					<div>
						{this.state.smsSent === "PHONE NUMBER INVALID" ? (
							<span className={"error"}>Neplatné telefonní číslo.</span>
						) : (
							<span className={"error"}>Při ověřování došlo k chybě.</span>
						)}
					</div>
				)}
			</div>
		)
	}
}

export default SendSms;
