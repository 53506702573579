import axios from "axios/index";
import SmartformClientRequest from "./SmartformClientRequest";
import SmartformClientResponse from "./SmartformClientResponse";

export default class SmartformClient {
	constructor() {
		this.client = axios.create({
			baseURL: process.env.REACT_APP_BASE_URL + "/smartform/proxy",
			timeout: 20000,
			crossDomain: true,
			headers: {
				'auth': process.env.REACT_APP_AUTH_TOKEN,
				'Content-type': 'application/json',
				'Accept': 'application/json',
			}
		});
		this.requestCancelSources = [];
	}

	sendRequest = (request: SmartformClientRequest): Promise => {
		let _self = this;
		return new Promise((resolve, reject) => {
			var CancelToken = axios.CancelToken;
			var source = CancelToken.source();
			_self.addCancelSource(source);
			this.client.post('', request.toJson(), {
				cancelToken: source.token
			}).then((responseData) => {
				if (responseData) {
					let r = new SmartformClientResponse(responseData);
					resolve(r);
				} else {
					// mělo to vlezt do catch bloku, takže reject
				}
			}).catch((errorResponse) => {
				if (axios.isCancel(errorResponse)) {
				} else {
					throw errorResponse;
				}
			});
		});
	};

	cancelRequests = () => {
		for (let sourceKey in this.requestCancelSources) {
			if (this.requestCancelSources.hasOwnProperty(sourceKey)) {
				let source = this.requestCancelSources[sourceKey];
				source.cancel('Canceled by SmartformClient::cancelRequests');
			}
		}
		this.requestCancelSources = [];
	};

	addCancelSource = (source) => {
		this.requestCancelSources.push(source);
	};
}