

export default class MainCache {
    constructor() {
        this.cache = {};
    }

    producenti = (oblast, data : Object = undefined) => {
        let structure = {
            producenti: {
                [oblast]: null
            }
        };
        this.cache = Object.assign(structure, this.cache);
        if (data !== undefined) {
            this.cache['producenti'][oblast] = data;
        }
        return this.cache['producenti'][oblast];
    };

    produkty = (oblast, id_producent, data : Object = undefined) => {
        let structure = {
            produkty: {
                [oblast]: {
                    [id_producent]: null
                }
            }
        };
        this.cache = Object.assign(structure, this.cache);
        if (data !== undefined) {
            this.cache['produkty'][oblast][id_producent] = data;
        }
        return this.cache['produkty'][oblast][id_producent];
    };

    userInfo = (data : Object = undefined) => {
        if (data !== undefined) {
            this.cache['userInfo'] = data;
        }

        return this.cache['userInfo'];
    };

    clear = () => {
        this.cache = {};
    };
}
