import Chata from "../components/Portfolio/forms_nakup_majetku/Chata";
import Byt from "../components/Portfolio/forms_nakup_majetku/Byt";
import Dum from "../components/Portfolio/forms_nakup_majetku/Dum";
import Pozemek from "../components/Portfolio/forms_nakup_majetku/Pozemek";
import NemovitostJakoInvestice from "../components/Portfolio/forms_nakup_majetku/NemovitostJakoInvestice";

const druhyPoptavekPoMajetku = [
	{id: "byt", name: "Byt", form: Byt},
	{id: "dum", name: "Dům", form: Dum},
	{id: "chata", name: "Chata", form: Chata},
	{id: "pozem", name: "Pozemek", form: Pozemek},
	{
		id: "nemov",
		name: "Nemovitost jako investice",
		desc: "Vyhledá nemovistosti vhodné ke krátkodobým pronájmům",
		form: NemovitostJakoInvestice
	},
];
export default druhyPoptavekPoMajetku;

export function getDruhyPoptavekPoMajetkuIDFromName(name) {
	let type = null;
	druhyPoptavekPoMajetku.forEach((data, index) => {
		if (data.id === name) {
			type = index;
		}
	});
	return type;
}
