import React from "react";
import HomeIncidents from "../Home/HomeIncidents";
import Events from "./Events";
import ForceUpdateScrollbars from "../ForceUpdateScrollbars/ForceUpdateScrollbars";

const Incidents = (props) => {
	let contractSMSSignToken = null;
	if (props && props.location && props.location.token && props.location.token.contractSMSSignToken) {
		contractSMSSignToken = props.location.token.contractSMSSignToken;
	}

	return (
		<div className="portfolio-page">
			<div className={'container'}>
				<div className="row row--smaller-spaces align-items-start">
					<div className="col-lg-8 col-md-7">
						<div className="cell cell--full-height">
							<h1 className="padding--bottom--10">
								Moje termíny
							</h1>
							<ForceUpdateScrollbars
								className="cell--full-height__scrollbox scrollbox--md--disabled"
								autoHeight
								autoHeightMin={0}
								autoHeightMax={1500}
								forceupdaterendertimer={1000}
								identifier={'incidents'}
							>
								<HomeIncidents inOwnPage={true}/>
							</ForceUpdateScrollbars>
						</div>
					</div>
					<div className="col-lg-4 col-md-5 zaznamy">
						<div className="cell cell--half-height cell--md-margin">
							<h1 className="padding--bottom--10">
								Záznamy
							</h1>
							<ForceUpdateScrollbars
								className="cell--half-height__scrollbox scrollbox--md--disabled"
								autoHeight
								autoHeightMin={0}
								autoHeightMax={1500}
								forceupdaterendertimer={1000}
								identifier={'events'}
							>
								<Events contractSMSSignToken={contractSMSSignToken}/>
							</ForceUpdateScrollbars>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Incidents;
