import React, {Component} from "react";
import {getContainer} from "../../../utils/generic";

export default class NakupMajetkuFormWrapper extends Component {
	constructor(props) {
		super(props);
		this._dateHelper = getContainer().getDateHelper();
		this._uzivatel = getContainer().getUzivatel();
		this._myplannClient = getContainer().getMyplannClient();
		this.state = {
			formKey: (new Date()).valueOf(),
		};
	}

	/**
	 * @returns {DateHelper}
	 */
	getDateHelper = () => {
		return this._dateHelper;
	};

	// v tehle funkci je to ruzne (podle toho, jak se to nabinduje)
	smazat(e, context) {
		// if (window.confirm("Opravdu smazat poptávku po majetku?")) {
		//     console.warn('not implemented');
		// }
	};

	// zde je to taky ruzne
	onChange(e, context) {
		let v = e.target.value;
		if (e.target.type === 'checkbox') {
			v = e.target.checked;
		}

		context.setState({[e.target.name]: v});
		this.props.onFormChange();
	};

	// zde taky
	onFieldChange(name, value, context) {
		context.setState({[name]: value});
		this.props.onFormChange();
	};

	onDispoziceMaxChange = (e, value, context) => {
		context.setState({
			maxDispozice: value
		});
		this.props.onFormChange();
	};

	onDispoziceMinChange = (e, value, context) => {
		context.setState({
			minDispozice: value
		});
		this.props.onFormChange();
	};

	render() {
		let Specific = this.props.Specific;
		return (
			<Specific
				onChange={this.onChange.bind(this)}
				onFieldChange={this.onFieldChange.bind(this)}
				onDelete={this.smazat.bind(this)}
				onDispoziceMaxChange={this.onDispoziceMaxChange.bind(this)}
				onDispoziceMinChange={this.onDispoziceMinChange.bind(this)}
				dateHelper={this.getDateHelper()}
				myplannClient={this._myplannClient}
				uzivatel={this._uzivatel}
				key={this.state.formKey}
				{...this.props}
			/>
		);
	}
}