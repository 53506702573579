import PojisteniZivotForm from "../components/Portfolio/forms_smlouvy/PojisteniZivot";
import PojisteniNezivotForm from "../components/Portfolio/forms_smlouvy/PojisteniNezivot";
import PenzijniProduktForm from "../components/Portfolio/forms_smlouvy/PenzijniProdukt";
import StavebniSporeniForm from "../components/Portfolio/forms_smlouvy/StavebniSporeni";
import InvesticeForm from "../components/Portfolio/forms_smlouvy/Investice";
import UveryPoSvjForm from "../components/Portfolio/forms_smlouvy/UveryPoSvj";
import UveryForm from "../components/Portfolio/forms_smlouvy/Uvery";
import LeasingForm from "../components/Portfolio/forms_smlouvy/Leasing";
import KomodityForm from "../components/Portfolio/forms_smlouvy/Komodity";
import BankovniProduktyForm from "../components/Portfolio/forms_smlouvy/BankovniProdukty";
import SluzbyForm from "../components/Portfolio/forms_smlouvy/Sluzby";

const druhySmluv = [
	{id: "1", name: "Pojištění život", form: PojisteniZivotForm},
	{id: "11", name: "Pojištění neživot", form: PojisteniNezivotForm},
	{id: "3", name: "Penzijní produkty", form: PenzijniProduktForm},
	{id: "4", name: "Stavební spoření", form: StavebniSporeniForm},
	{id: "5", name: "Investice", form: InvesticeForm},
	{id: "7", name: "Úvěry pro PO + SVJ", form: UveryPoSvjForm},
	{id: "2", name: "Úvěry", form: UveryForm},
	{id: "13", name: "Leasing", form: LeasingForm},
	{id: "9", name: "Komodity", form: KomodityForm},
	{id: "6", name: "Bankovní produkty", form: BankovniProduktyForm},
	{id: "10", name: "Služby", form: SluzbyForm}
];
export default druhySmluv;

export function getDruhySmluvIDFromName(name) {
	let type = null;
	druhySmluv.forEach((data, index) => {
		if (data.id === String(name)) {
			type = index;
		}
	});
	return type;
}