import React from "react";
import BaseMajetekForm from "./BaseMajetekForm";
import moment from "moment/moment";
import SmartformAdresa from "./SmartformAdresa";
import ForceUpdateScrollbars from "../../ForceUpdateScrollbars/ForceUpdateScrollbars";
import FilesList from "./filesUpload/FilesList";
import FilesDropzone from "./filesUpload/FilesDropzone";
import DatePickerWithListener from "../../utils/DatePickerWithListener";

const emptyData = {
	ulice: '',
	obec: '',
	cast_obce: '',
	psc: '',
	velikost_pozemku: "",
	hodnota_pozemku: "",
	datum_koupeno: "",
	druh: "pozem",
	documents: [],
	files: [],
};

class LandForm extends BaseMajetekForm {
	constructor(props) {
		super(props);
		this.files = [];
		this.state = emptyData;
		this.disabled = false;
		if (props.asset) {
			this.disabled = props.asset.disabled ? props.asset.disabled || this._uzivatel.readAccessOnly() : this._uzivatel.readAccessOnly();
			this.state = Object.assign({}, {...props.asset.data}, {documents: props.asset.documents}, {files: []});
		}

		this.state.saveBtn = {
			disabled: false,
			text: "uložit"
		};
	}

	emptyData = () => {
		return emptyData;
	};

	onDrop = (files) => {
		let saveBtn = this.state.saveBtn;

		if (files.length > 0) {
			saveBtn.text = "nahrát soubory a uložit";
		} else {
			saveBtn.text = "uložit";
		}

		this.setState({
			saveBtn,
			files
		});

		this.props.onFormChange();
	};

	onDocumentDelete = (document) => {
		if (this.state.documents) {
			let documents = this.state.documents.filter(function (item) {
				return item.id !== document;
			});

			this.setState({
				documents: documents,
			});
		}
	};

	render() {
		return (
			<div>
				<form className={'form'} onSubmit={this.submit.bind(this)}>
					<h1>{this.state.nazev && this.state.nazev.trim().length > 0 ? this.state.nazev : 'Pozemek'}</h1>
					<table cellSpacing="0" cellPadding="0" border="0"
						   className="modal-form-table modal-form-table--single-column">
						<SmartformAdresa
							onSuggestionMenuOpened={this.onSuggestionMenuOpened.bind(this)}
							onSuggestionMenuClosed={this.onSuggestionMenuClosed.bind(this)}
							onChange={this.onChange}
							STREET_AND_NUMBER={this.state.ulice}
							CITY={this.state.obec}
							PART_OF_TOWN={this.state.cast_obce}
							ZIP={this.state.psc}
							disabled={this.disabled}
						/>
						<tbody>
						<tr>
							<td>Plocha pozemku</td>
							<td><input onChange={this.onChangeOnlyNumbers} type="text" name="velikost_pozemku"
									   className="nfthousands"
									   value={this.showFormatedNumber(this.state.velikost_pozemku)} maxLength={11}
									   autoComplete={"off"}
									   disabled={this.disabled}/><span> m<sup>2</sup></span></td>
						</tr>
						<tr>
							<td>Hodnota pozemku</td>
							<td><input onChange={this.onChangeOnlyNumbers} type="text" name="hodnota_pozemku"
									   autoComplete={"off"}
									   className="nfthousands dontclear"
									   value={this.showFormatedNumber(this.state.hodnota_pozemku)}
									   maxLength={11} disabled={this.disabled}/><span> Kč</span></td>
						</tr>
						<tr>
							<td>Datum pořízení</td>
							<td>
								<DatePickerWithListener
									name={'datum_koupeno'}
									selected={this.state.datum_koupeno ? moment(this.state.datum_koupeno, this.getDateHelper().getActiveFormat()) : undefined}
									onChange={date => this.onFieldChange('datum_koupeno', date)}
									dateFormat={this.getDateHelper().getActiveFormat()}
									disabled={this.disabled}
                                    onError={this.onDatepickerError}
                                    onErrorRemoved={this.onDatepickerErrorRemoved}
								/>
							</td>
						</tr>
						</tbody>
					</table>

					<div className={'padding--bottom--10'}>
						<h2 className={'padding--bottom--10 ' + (this._uzivatel.readAccessOnly() ? 'padding--top--10 ' : '') + 'font-size--14'}>Fotky</h2>
						<div className={'contract-documents__container'}>
							<ForceUpdateScrollbars
								className="contract-documents__scrollbox"
								autoHeight
								autoHeightMin={0}
								autoHeightMax={234}
								forceupdaterendertimer={1000}
							>
								<div
									className={'contract-documents__scrollbox__container contract-documents__scrollbox__container--boxes'}>
									{this.disabled ? "" : <FilesDropzone onDrop={this.onDrop.bind(this)}/>}
									<FilesList documents={this.state.documents}
											   onDelete={this.onDocumentDelete.bind(this)} disabled={this.disabled}/>
								</div>
							</ForceUpdateScrollbars>
						</div>
					</div>

					<table cellSpacing="0" cellPadding="0" border="0"
						   className="modal-form-table modal-form-table--single-column">
						<tbody>
						<tr>
							<td>Doplňující popis pozemku</td>
							<td><textarea onChange={this.onChange} name="popis" disabled={this.disabled}
										  value={this.state.popis}/></td>
						</tr>

						{this._uzivatel.readAccessOnly() || this.disabled ? '' :
							<tr className={'conrol-row'}>
								<td className={'td-delete'}>
									{this.state.id !== undefined && this.state.disabledDelete !== true ?
										<span onClick={this.smazat} className={'delete pointer'}>smazat majetek</span> : ''}
								</td>
								<td>
									<input type="submit" name={"add"} className="btn" value={this.state.saveBtn.text}
										   disabled={this.state.saveBtn.disabled}/>
								</td>
							</tr>
						}

						</tbody>
					</table>
				</form>
			</div>
		);
	}
}

export default LandForm;
