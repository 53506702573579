import React, {Component} from "react";
import * as PubSub from "pubsub-js";
import CircularProgressbar from 'react-circular-progressbar';

import 'react-circular-progressbar/dist/styles.css';

class Loading extends Component {
	constructor(props) {
		super(props);
		this._ismounted = false;
		this.state = {
			process: false,
			requests: 0,
			progress: false,
			progressState: 0,
			show: false,
			forceShow: props.forceShow ? props.forceShow : false,
		};

		if (this.state.forceShow) {
			this.state.show = true;
		}

		this._timeout = null;
	}

	UNSAFE_componentWillMount = function () {
		this._requestStarted = PubSub.subscribe('requestStarted', this.requestStarted.bind(this));
		this._requestFinished = PubSub.subscribe('requestFinished', this.requestFinished.bind(this));
		this._downloadProgress = PubSub.subscribe('downloadProgress', this.downloadProgress.bind(this));
		this._ismounted = true;
	};

	componentWillUnmount = function () {
		PubSub.unsubscribe(this._requestStarted);
		PubSub.unsubscribe(this._requestFinished);
		PubSub.unsubscribe(this._downloadProgress);
		this._ismounted = false;
	};

	requestStarted = function (msg, data) {
		if (data.hideSpinner === undefined || data.hideSpinner === false) {
			let progress = false;
			let progressState = 0;
			if (data.progress) {
				progress = data.progress;
			}

			this.setState({process: true, show: true, progress, progressState});
		}
	};

	requestFinished = function (msg, data) {
		if (!this.state.forceShow) {
			clearTimeout(this._timeout);
			this._timeout = setTimeout(() => {
				if (this._ismounted) {
					this.setState({process: false, show: false, progress: false});
				}
			}, 1000);
			this.setState({show: false});
		}
	};

	downloadProgress = (msg, data) => {
		if (data.percentCompleted) {

			let percent = parseInt(data.percentCompleted, 10);
			if (percent < 0) {
				percent = 0;
			} else if (percent > 100) {
				percent = 100;
			}

			this.setState({progressState: percent});
		}
	};

	render() {
		return (
			<div
				className={'loading-overlay' + (this.state.show ? ' loading-overlay-show' : '') + (this.state.process ? ' loading-overlay-process' : ' loading-overlay-no-process')}>
				{this.state.progress ?
					<div className={"loading-overlay__progressbar"}>
						<CircularProgressbar percentage={this.state.progressState}/>
					</div>
					:
					<img src="/img/spinner.gif" alt="loading..."/>
				}
			</div>
		);
	}
}

export default Loading;
