import React, {Component} from "react";
import DropzoneFile from "./DropzoneFile";
import fileRenamePromtDialog from "../../utils/fileRenamePromt";
import ProgressBar from "../utils/ProgressBar";
import * as PubSub from "pubsub-js";
import Dropzone from "../utils/Dropzone";
import {getContainer} from "../../utils/generic";

export default class SmlouvyDropzone extends Component {

	constructor(props) {
		super(props);
		this.state = {
			files: [],
			addRemoveLinks: true,
			dictCancelUpload: "",
			dictCancelUploadConfirmation: "Opravdu chcete zrušit nahrávání?",
			dictRemoveFile: "",
			maxFileUploadSize: parseInt(sessionStorage.getItem('max_file_upload_size'), 10),
			allowedMimetypes: sessionStorage.getItem('allowed_mimetypes'),
            openFileWizard: props.openFileWizard
		};
		this._uzivatel = getContainer().getUzivatel();
		this._client = getContainer().getMyplannClient();
	};

	UNSAFE_componentWillReceiveProps(nextProps) {
	}

	componentDidMount() {
		this.reload();
	}

	reload = () => {
		if (!this.state.maxFileUploadSize || !this.state.allowedMimetypes) {
			this._client.getMplanConfiguration().then(
				response => {
					if (response.maxFileUploadSize) {
						this.setState({maxFileUploadSize: parseInt(response.maxFileUploadSize, 10)});
					}

					if (response.allowedMimetypes) {
						this.setState({allowedMimetypes: response.allowedMimetypes});
					}
				}
			);
		}

		this.setState({files: []});
	};

	onDrop = (acceptedFiles) => {
		let files = this.state.files;
		acceptedFiles.forEach((file) => {
			files.push(new DropzoneFile(null, file));
		});

		this.setState({files});
		this.props.onDrop(files);
		this.setState({messages: []});
		PubSub.publish("dropzoneDroppedFile", {files: files});

		if (this.props.dropzoneListChanged) {
			this.props.dropzoneListChanged(files);
		}
	};

	onClick = (e, index) => {
		e.preventDefault();
		e.stopPropagation();
		let oldName = this.state.files[index].name;
		fileRenamePromtDialog(oldName).then(
			result => {
				if (result.length > 0) {
					let files = this.state.files;
					files[index].setCustomName(result);
					this.setState({files});
				}
			},
			result => {
				// `cancel` callback
			}
		);
	};

	deleteFile = (e, index) => {
		e.preventDefault();
		e.stopPropagation();
		if (this.state.files[index].isNew()) {

			let files = this.state.files;
			files.forEach((item, itemIndex) => {
				if (itemIndex === index) {
					files.splice(index, 1);
				}
			});
			this.setState({files});

			if (this.props.dropzoneListChanged) {
				this.props.dropzoneListChanged(files);
			}
		}
		PubSub.publish("dropzoneDeletedFile", {files: this.state.files});
	};

	onDropRejected = (e) => {
		let mb = (this.state.maxFileUploadSize / (1024 * 1024));

		let messages = [];
		if (e && e.length > 0) {
			e.forEach((file, index) => {
				if (file.size >= this.state.maxFileUploadSize) {
					messages.push("Soubor je příliš velký. Maximální velikost je " + mb + " MB (" + file.name + ")");
				} else {
					messages.push("Nemůžete nahrát soubor tohoto typu (" + file.name + ")");
				}
			});
		}

		this.setState({messages});
	};

	render() {
		return (
			<div>
				{this._uzivatel.readAccessOnly() ? '' :
					<div className={'contract-documents__dropzone__container'}>
						<ProgressBar/>
						<Dropzone
							name={"dropzone"}
							className={'contract-documents__dropzone__dropzone'}
							onDrop={this.onDrop.bind(this)}
							maxSize={this.state.maxFileUploadSize}
							mimetypes={this.state.allowedMimetypes}
							onDropRejected={this.onDropRejected}
                            openfilewizard={this.state.openFileWizard ? '1' : '0'}
						>
							<div className={'contract-documents__dropzone__dropzone__btn'}>
                                <span>
                                    <img alt='upload'
										 src={'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADdcAAA3XAUIom3gAAAAHdElNRQfiBBgJJhrGGIhIAAAAqUlEQVQoz4WOLQvCUBiFn3sZwyBYxKpldShiE6PNPMTfJ6aF/QVZ0yGYbGPVwYqYj0HcUPdx0r3nOZz3wJfkyaNZ8pUrl9+Ep0oVKVKqaeWaEo85smMJxOxZmezt2zL6YG1iABOz4fmxnbKqoCjf1+qEpUNWgzasniVpLThb+q2BYecG5+d/+5+RyW2c6CqzhASN/QGh0YQDdy41eMaIrQHQgnlNIDEneAEr4TFWJv51KgAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxOC0wNC0yNFQwOTozODoyNiswMjowMKwDfrwAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTgtMDQtMjRUMDk6Mzg6MjYrMDI6MDDdXsYAAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg=='}/>
                                    Vyberte soubory
                                </span>
							</div>
							<span className={'contract-documents__dropzone__dropzone__text'}>&nbsp;nebo je přetáhněte do tohoto pole</span>
						</Dropzone>
						{this.state.messages && this.state.messages.length > 0 && this.state.messages.map((result) => {
							return (
								<div className={'contract-documents__dropzone__error'}>
									{result}
								</div>
							)
						})
						}
						{this.state.files.map((file: DropzoneFile, index) => {
							return (
								<div className="document-row" key={index}>
									<div className={'width--20'}></div>

									<div className={''}>
										<span className={'break-words'}>{file.getCustomName()}</span>
										<strong className={'document-row__unsave'}>&nbsp; neuložený</strong>
									</div>

									<div className="width--30 text-right">
										<a href="#dummy" className={'pointer edit d-inline-block hide-text'}
										   onClick={(e) => {
											   this.onClick(e, index)
										   }}>Upravit soubor</a>
										<a href="#dummy"
										   className="delete pointer d-inline-block delete__cross--small hide-text"
										   onClick={(e) => {
											   this.deleteFile(e, index)
										   }}>Smazat soubor</a>
									</div>
								</div>
							)
						})}
					</div>
				}
			</div>
		);
	}
}
