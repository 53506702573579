import React, {Component} from "react";
import * as PubSub from "pubsub-js";

export default class ProgressBar extends Component {

	constructor(props) {
		super(props);
		this.state = {
			progress: null,
		}
	};

	UNSAFE_componentWillMount = function () {
		this._uploadSmlouvyFilesProgress = PubSub.subscribe('uploadSmlouvyFilesProgress', this.onUploadProgress.bind(this));
	};

	componentWillUnmount = function () {
		PubSub.unsubscribe(this._uploadSmlouvyFilesProgress);
	};

	onUploadProgress = (message, data) => {
		if (data) {
			this.setState({progress: data.percentCompleted});
		} else {
			this.setState({progress: false});
		}
	};

	render() {
		return (
			<div>
				{this.state.progress !== null && parseInt(this.state.progress, 10) < 100 ?
					<div className={"progress-bar"}>
						<div className={"progress-bar__done"} style={{width: this.state.progress + "%"}}></div>
						<div className={"progress-bar__percent"}>
							{this.state.progress} %
						</div>
					</div>
					: ""}

			</div>
		);
	}
};
