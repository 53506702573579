import React, {Component} from 'react';
import Loading from './utils/Loading';

export default class EmptyPage extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render = () => {
    return (
      <div className="empty-page">
        <Loading show={true}/>
      </div>
    );
  };
}
