import {parse_query_string} from '../../utils/urlparse';
import React, {Component} from "react";
import Loading from "../utils/Loading";
import Header from "../Header";
import {Redirect} from "react-router";
import * as PubSub from "pubsub-js";
import {getContainer} from "../../utils/generic";

class ZapomenuteHeslo extends Component {

	constructor(props) {
		super(props);
		this._client = getContainer().getMyplannClient();

		this.state = {
			id: "",
			username: "",
			token: "",
			password: "",
			passwordZnovu: "",
			pin: "",
			msg: "",
			error: false,
			success: false,
			showSpinner: false,
			abort: false,
			isTokenValid: false,
			redirectToLoginPage: false,
			step: 1
		};

		let qs = parse_query_string(this.props.location.search.substring(1));
		this.state.token = qs.token;
		this.state.id = qs.id;

		this.state.token = this.state.token ? this.state.token : "";
		this.state.id = this.state.id ? this.state.id : "";

		if (this.state.token) {
			this.state.step = 2;
			this.state.msg = "";
			this.state.showSpinner = true;
			this.zvalidovatToken();
		}
	}

	componentDidMount() {

	}

	onChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	odeslatToken = (e) => {
		e.preventDefault();
		if (this.state.username) {
			PubSub.publish('requestStarted', {target: this});
			this._client._sign_zapomenuteHesloOdeslatToken(this.state.username, window.location.origin).then((response) => {
				PubSub.publish('requestFinished', {target: this});
				if (response && response.data && response.data.data) {
					if (response.data.data.success) {
						this.setState({
							error: false,
							success: true,
							msg: "Potvrzovací odkaz byl odeslán na Vaši e-mailovou adresu.",
							step: -1
						});
						return null;
					}

					this.setState({
						error: true,
						msg: "Během zpracování požadavku došlo k chybě. Kontaktujte Vašeho poradce.",
						step: 1
					});
				}
			}).catch((e) => {
				console.error(e);
				PubSub.publish('requestFinishedWithError', {target: this, error: e});
				PubSub.publish('requestFinished', {target: this});
				if (e.response) {
					let msg = '';
					if ((msg = this._client.getExceptionMessageFromResponse(e.response, 'Myplann\\Exception\\UserDataUnavailableException'))) {
						this.setState({
							error: true,
							success: false,
							abort: true,
							msg: msg
						});
					} else if ((msg = this._client.getExceptionMessageFromResponse(e.response, 'Myplann\\Exception\\UserDataEmailUnavailableException'))) {
						this.setState({
							error: true,
							success: false,
							abort: true,
							msg: msg
						});
					} else if ((msg = this._client.getExceptionMessageFromResponse(e.response, 'Myplann\\Exception\\UserDataPhoneUnavailableException'))) {
						this.setState({
							error: true,
							success: false,
							abort: true,
							msg: msg
						});
					} else if ((msg = this._client.getExceptionMessageFromResponse(e.response, 'Myplann\\Exception\\ZapomenuteHesloTokenNotExpiredException'))) {
						this.setState({
							error: true,
							success: false,
							abort: true,
							msg: msg
						});
					} else if ((msg = this._client.getExceptionMessageFromResponse(e.response, 'Myplann\\Exception\\WrongUsernameException'))) {
						this.setState({
							error: true,
							success: false,
							abort: false,
							msg: msg
						});
					}
					return null;
				}
				this.setState({
					error: true,
					success: false,
					abort: false,
					msg: 'Nepodařilo se odeslat potvrzovací odkaz na e-mail. Kontaktujte Vašeho poradce.'
				});

			});
		} else {
			this.setState({
				error: true,
				success: false,
				abort: false,
				msg: 'Zadejte uživatelské jméno.'
			});
		}
	};

	zvalidovatToken = () => {
		this._client._sign_zapomenuteHesloZvalidovatToken(this.state.id, this.state.token).then((response) => {
			if (response && response.data && response.data.data) {
				if (response.data.data.isTokenValid) {
					this.setState({
						isTokenValid: true,
						error: false,
						success: true,
						showSpinner: false,
						abort: false,
						step: response.data.data.skipPasswordRetype ? 3 : 2,
						msg: response.data.data.skipPasswordRetype ?
								'Byla Vám odeslána SMS zpráva s kódem na číslo ' + response.data.data.userMaskedPhoneNumber + '. Pro potvrzení změny hesla tento kód zadejte do políčka níže.'
							: '',
					});
				} else {
					this.setState({
						isTokenValid: false,
						error: true,
						success: false,
						showSpinner: false,
						abort: false,
						msg: 'Potvrzovací odkaz je neplatný. Zopakujte proces obnovy hesla.',
						step: 1
					});
				}
			}
		}).catch((e) => {
			this.setState({
				error: true,
				success: false,
				abort: false,
				msg: 'Nepodařilo se ověřit potvrzovací odkaz. Zkuste to, prosím, později znovu.',
				step: 1
			});
		});
	};

	zmenitHeslo = (e) => {
		e.preventDefault();

		if (this.state.password !== '' && this.state.password.length >= 8) {
			if (this.state.password !== this.state.passwordZnovu) {
				this.setState({
					error: true,
					success: false,
					abort: false,
					msg: "Obsah políček pro nové heslo není shodný."
				});
			} else {
				this._client._sign_zapomenuteHesloZmenitHeslo(this.state.id, this.state.token, this.state.password).then((response) => {
					if (response && response.data && response.data.data) {
						if (response.data.data.success) {
							this.setState({
								error: false,
								success: true,
								abort: false,
								msg: 'Byla Vám odeslána SMS zpráva s kódem na číslo ' + response.data.data.userMaskedPhoneNumber + '. Pro potvrzení změny hesla tento kód zadejte do políčka níže.',
								step: 3
							});
							return null;
						}
					}
					this.setState({
						error: true,
						success: false,
						abort: true,
						msg: "Nepodařilo se změnit heslo. Zopakujte proces obnovy hesla.",
						step: 2
					});
				}).catch((e) => {
					if (this._client.getExceptionMessageFromResponse(e.response, 'Myplann\\Exception\\ZapomenuteHesloInvalidTokenException')) {
						this.setState({
							error: true,
							success: false,
							msg: this._client.getExceptionMessageFromResponse(e.response, 'Myplann\\Exception\\ZapomenuteHesloInvalidTokenException'),
							step: 1
						});
					} else if (this._client.getExceptionMessageFromResponse(e.response, 'Myplann\\Exception\\PasswordValidationException')) {
						this.setState({
							error: true,
							success: false,
							msg: this._client.getExceptionMessageFromResponse(e.response, 'Myplann\\Exception\\PasswordValidationException'),
							step: 2,
							password: '',
							passwordZnovu: ''
						});
					} else if (this._client.getExceptionMessageFromResponse(e.response, 'Myplann\\Exception\\ZapomenuteHesloInvalidSmsAdviserCountException')) {
						this.setState({
							error: true,
							success: false,
							abort: true,
							msg: "Nepodařilo se změnit heslo, vlivem špatně nakonfigurovaného uživatelského účtu. Kontaktujte Vašeho poradce."
						});
					} else {
						this.setState({
							error: true,
							success: false,
							abort: true,
							msg: "Nepodařilo se změnit heslo."
						});
					}
				});
			}
		} else {
			this.setState({
				error: true,
				success: false,
				abort: false,
				msg: "Heslo je příliš krátké."
			});
		}
	};

	potvrditSms = (e) => {
		e.preventDefault();

		if (this.state.pin !== '') {
			this._client._sign_zapomenuteHesloPotvrditZmenuHesla(this.state.id, this.state.token, this.state.pin).then((response) => {
				if (response && response.data && response.data.data) {
					if (response.data.data.success) {
						this.setState({
							redirectToLoginPage: true
						});
					}
					return null;
				}
				this.setState({
					error: true,
					success: false,
					msg: "Nepodařilo se potvrdit změnu hesla.",
					step: 3
				});
			}).catch((e) => {
				if (this._client.getExceptionMessageFromResponse(e.response, 'Myplann\\Exception\\ZapomenuteHesloInvalidSmsCodeException')) {
					this.setState({
						error: true,
						success: false,
						msg: this._client.getExceptionMessageFromResponse(e.response, 'Myplann\\Exception\\ZapomenuteHesloInvalidSmsCodeException'),
						step: 3,
						pin: ''
					});
				} else if (this._client.getExceptionMessageFromResponse(e.response, 'Myplann\\Exception\\ZapomenuteHesloInvalidTokenException')) {
					this.setState({
						error: true,
						success: false,
						msg: this._client.getExceptionMessageFromResponse(e.response, 'Myplann\\Exception\\ZapomenuteHesloInvalidTokenException'),
						step: 1
					});
				} else {
					this.setState({
						error: true,
						success: false,
						msg: "Nepodařilo se potvrdit změnu hesla.",
						step: 3
					});
				}
			});
		} else {
			this.setState({
				error: true,
				success: false,
				abort: false,
				msg: "Není vyplněný SMS kód pro potvrzení změny hesla."
			});
		}
	};

	onClickZnovuOdeslatSmsKod= (e) => {
		e.preventDefault();
		this._client._sign_zapomenuteHesloZmenitHeslo(this.state.id, this.state.token, this.state.password).then((response) => {
			if (response && response.data && response.data.data) {
				if (response.data.data.success) {
					this.setState({
						error: false,
						success: true,
						abort: false,
						msg: 'Byla Vám odeslána SMS zpráva s novým kódem na číslo ' + response.data.data.userMaskedPhoneNumber + '. Pro potvrzení změny hesla tento kód zadejte do políčka níže.',
						step: 3
					});
					return null;
				}

				this.setState({
					error: true,
					success: false,
					msg: 'Nepodařilo se odeslat nový SMS kód.',
					step: 3
				});
			}
		}).catch((e) => {
			if (this._client.getExceptionMessageFromResponse(e.response, 'Myplann\\Exception\\ZapomenuteHesloInvalidTokenException')) {
				this.setState({
					error: true,
					success: false,
					msg: this._client.getExceptionMessageFromResponse(e.response, 'Myplann\\Exception\\ZapomenuteHesloInvalidTokenException'),
					step: 1
				});
			} else if (this._client.getExceptionMessageFromResponse(e.response, 'Myplann\\Exception\\ZapomenuteHesloCanNotSendSMS')) {
				this.setState({
					error: true,
					success: false,
					msg: this._client.getExceptionMessageFromResponse(e.response, 'Myplann\\Exception\\ZapomenuteHesloCanNotSendSMS'),
					step: 3
				});
			} else {
				console.log(e.response);
				this.setState({
					error: true,
					success: false,
					msg: "Nepodařilo se zaslat nový SMS kód.",
					step: 3
				});
			}
		});
	};


	render() {
		if (this.state.redirectToLoginPage) {
			return <Redirect to={{
				pathname: '/login',
				passwordChanged: true
			}}/>
		}

		return (
			<div>
				<Loading show={false}/>
				<Header showBtns={false} showPartnerLogo={true} logoLinkHref={'/login'}/>
				<div className="content">
					<div className="zapomenute-heslo-page">
						<div className={'container'}>
							<div className="row row--smaller-spaces align-items-start justify-content-md-center">
								<div className="col-lg-6 col-md-8">
									<div className="cell">
										<div className="padding--bottom--25">
											<h1 className="padding--bottom--20">
												Zapomenuté heslo
											</h1>
											{this.state.step === 1 ?
												<div>
													<p>
														Zadejte Vaše přihlašovací jméno a potvrďte. Na Váš e-mail bude
														doručen potvrzovací e-mail. Po potvrzení odkazu Vám bude zaslána
														potvrzovací SMS, jejíž kód následně opiště do pole při změně
														hesla.
													</p>
												</div>
												:
												''
											}
											{this.state.msg ?
												<div className={'msg-container'}>
													<p className={(this.state.error ? ' error' : '') + (this.state.success ? ' success' : '')}>
														{this.state.msg}
													</p>
												</div>
												:
												''
											}
											{!this.state.abort ?
												<div>
													{this.state.step === 1 ?
														<div>
															<form onSubmit={this.odeslatToken}>
																<div className={'row'}>
																	<div className={'col-sm-12'}>
																		<label htmlFor="">Přihlašovací jméno:</label>
																	</div>
																</div>
																<div className={'row padding--bottom--5'}>
																	<div className={'col-sm-6 col-md-6 col-lg-5'}>
																		<input onChange={this.onChange} type="text"
																			   name={"username"}
																			   placeholder={''}
																			   value={this.state.username}/>
																	</div>
																</div>
																<div className={'row padding--bottom--5'}>
																	<div className={'col-sm-6 col-md-6 col-lg-5'}>
																		<input type="submit"
																			   className={'btn width--100--percent text-center'}
																			   name={'odeslatToken'}
																			   value={'Potvrdit'}/>
																	</div>
																</div>
															</form>
														</div>
														:
														''
													}

													{this.state.step === 2 ?
														<div>
															{this.state.showSpinner ?
																<div className={"local-spinner"}></div> :
																<form onSubmit={this.zmenitHeslo}>

																	<div className={'row padding--bottom--5'}>
																		<div className={'col-sm-12 col-md-12 col-lg-10'}>
																			<p>Zadejte heslo dlouhé minimálně 8 znaků složené z malých a velkých písmen a čísel.</p>
																			<label htmlFor="">Zadejte nové
																				heslo:</label>
																			<input onChange={this.onChange}
																				   type="password" name={'password'}
																				   placeholder={''}
																				   value={this.state.password}/>
																		</div>
																	</div>

																	<div className={'row padding--bottom--5'}>
																		<div className={'col-sm-12 col-md-12 col-lg-10'}>
																			<label htmlFor="">Zadejte nové heslo
																				znovu:</label>
																			<input onChange={this.onChange}
																				   type="password"
																				   name={'passwordZnovu'}
																				   placeholder={''}
																				   value={this.state.passwordZnovu}/>
																		</div>
																	</div>

																	<div className={'row'}>
																		<div className={'col-sm-6 col-md-6 col-lg-5'}>
																			<input type="submit"
																				   className={'btn width--100--percent'}
																				   name={'submit'} value={'Potvrdit'}/>
																		</div>
																	</div>
																</form>
															}
														</div>
														:
														''
													}

													{this.state.step === 3 ?
														<div>
															{this.state.showSpinner ?
																<div className={"local-spinner"}></div> :
																<form onSubmit={this.potvrditSms}>
																	<div className={'row padding--bottom--5'}>
																		<div className={'col-sm-6 col-md-6 col-lg-5'}>
																			<label htmlFor="">Zadejte SMS kód:</label>
																			<input onChange={this.onChange} type="text"
																				   name={'pin'}
																				   placeholder={''}
																				   value={this.state.pin}/>
																		</div>
																	</div>

																	<div className={'row'}>
																		<div className={'col-sm-6 col-md-6 col-lg-5'}>
																			<input type="submit"
																				   className={'btn width--100--percent'}
																				   name={'submit'} value={'Potvrdit'}/>
																		</div>
																	</div>
																	<div className={"top-space--15--important"}>
																		<span onClick={this.onClickZnovuOdeslatSmsKod}	className={"pointer"}>Poslat nový SMS klíč</span>
																	</div>
																</form>
															}
														</div>
														:
														''
													}

												</div>
												:
												''
											}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ZapomenuteHeslo;
