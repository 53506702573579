import React, {Component} from "react";
import {loggedIn} from "../../utils/Api";
import Loading from "../utils/Loading";
import SendSms from "./SendSms";
import Header from "../Header";
import {parse_query_string} from "../../utils/urlparse";
import DetailZaznamu from "../DetailZaznamu/DetailZaznamu";
import {Redirect} from "react-router";
import {getContainer} from "../../utils/generic";
import DetailZaznamuSjednani from "../DetailZaznamu/DetailZaznamuSjednani";
import "./../../styles/validation-fix.css";
import SmsSjednani from "./SmsSjednani";

export default class Validation extends Component {

	constructor(props) {
		super(props);
		this._client = getContainer().getMyplannClient();
		this.state = {
			token: parse_query_string(props.location.search.substring(1)).token,
      page: 1,
      totalPages: 1,
      showHtmlDetail: true,
      maxHeight : window.innerHeight,
		};
	}

  updateWindowDimensions () {
    this.setState({ maxHeight: window.innerHeight });
  }

	componentDidMount() {
		this.reload();
    this.setState({showHtmlDetail: true});
		this._client.validateToken(this.state.token).then((res) => {
			this.setState({validToken: res});
		});
    window.addEventListener('resize', this.updateWindowDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions.bind(this));
  }

	reload = () => {
		this._client.getContractData(this.state.token).then(response => {
			if (response) {
				if (response.data && response.data.data && response.data.data.smlouvaInfo) {
				  let smlouvaInfo = response.data.data.smlouvaInfo;
          let page = this.state.page;
          let totalPages = this.state.totalPages;
          if(Array.isArray(smlouvaInfo.onlineSjednani)) {
            totalPages = smlouvaInfo.onlineSjednani.length + 1;
          }
          let f = false;
          if(smlouvaInfo.podepsana && !smlouvaInfo.podepsanaVcSmluv && Array.isArray(smlouvaInfo.onlineSjednani)) {
              // klient podepsal zaznam ale nema podepsane vsechny smlouvy
              smlouvaInfo.onlineSjednani.forEach((val, index) => {
                if(!val.podepsal_klient && page < index + 2 && !f) {
                   page = index + 2;
                   f = true;
                }
              })
          }
					this.setState({smlouvaInfo: response.data.data.smlouvaInfo, page:page, totalPages: totalPages});
				} else {
					this.setState({smlouvaInfo: false});
				}
			}
		});
	};

  onSmsVerified() {
    let self = this;
    this.setState({
      showHtmlDetail: false
    }, () => { self.reload(); });
  }

	render = () => {
		if (loggedIn()) {
			return <Redirect to={{
				pathname: '/udalosti',
				token: {contractSMSSignToken: this.state.token}
			}}/>
		}

		let self = this;

    let zaznamPodepsan = undefined;
    let podepsano = undefined;
    let cislo_smlouvy = undefined;
    if(this.state.page !== 1) {
      zaznamPodepsan = this.state.smlouvaInfo.podepsana || this.state.zaznamPodepsan;
      podepsano= this.state.smlouvaInfo.onlineSjednani[this.state.page - 2].podepsal_klient;
      cislo_smlouvy=this.state.smlouvaInfo.onlineSjednani[this.state.page - 2].cislo_smlouvy;
    }


		return (
			<div className={'validation-page'} style={{maxHeight: this.state.maxHeight}}>
        <div>
          <Loading show={true}/>
          <Header showPartnerLogo={true} />
        </div>
        <div className={'container-wrapper'}>
				  <div className="container">
					<div className="row row--smaller-spaces align-items-start justify-content-md-center">
						<div style={this.state.page > 1 ? {maxWidth: '900px'} : {}} className={this.state.page === 1 ? 'col-lg-6 col-md-8' : 'col-md-12'}>
							<div className="cell validation">
								{this.state.validToken === undefined || this.state.smlouvaInfo === undefined ?
									<div className={'local-spinner'}></div> :
									<div>
										{this.state.validToken && this.state.smlouvaInfo ? (
                      <div>
                        {this.state.page === 1 ? <>

                          <h1 className="padding--bottom--20">{this.state.totalPages > 1 ? <>Podpis smluvní dokumentace - {this.state.page} ze {this.state.totalPages}</> : <>Potvrzení nového záznamu</>}</h1>
                          <DetailZaznamu
                            smlouvaInfo={this.state.smlouvaInfo} showHtmlDetail={this.state.showHtmlDetail}
                          />
                        </> : <>
                          <h1 className="padding--bottom--20">Podpis smluvní dokumentace - {this.state.page} ze {this.state.totalPages}</h1>
                          <DetailZaznamuSjednani hidePodpis={true} key={this.state.page} onSmsVerified={this.onSmsVerified.bind(this)} token={this.state.token} zaznamPodepsan={this.state.smlouvaInfo.podepsana || this.state.zaznamPodepsan} podepsano={this.state.smlouvaInfo.onlineSjednani[this.state.page - 2].podepsal_klient} cislo_smlouvy={this.state.smlouvaInfo.onlineSjednani[this.state.page - 2].cislo_smlouvy}/>
                        </>}

												</div>
											) :
											<div>
												<h1 className="text-red">Záznam nebyl nalezen nebo byl již podepsán</h1>
											</div>
										}
                    {(this.state.smlouvaInfo && this.state.smlouvaInfo.podepsanaVcSmluv && this.state.page > 1) && <a style={{float:'left'}} className={'btn'} onClick={()=>{self.setState({page: this.state.page-1}); }} >Zobrazit předchozí záznam</a>}
                    {(this.state.smlouvaInfo && this.state.smlouvaInfo.podepsanaVcSmluv && this.state.page < this.state.totalPages) && <a style={{float:'right'}} className={'btn'} onClick={()=>{self.setState({page: this.state.page+1}); }} >Zobrazit další záznam</a>}
                    <div style={{clear: 'both'}} />
									</div>
								}
							</div>
						</div>
					</div>
				</div>
        {this.state.validToken && this.state.smlouvaInfo ? (
          <div className={'container container-sms'}>
            <div className="row row--smaller-spaces align-items-start justify-content-md-center">
              <div style={this.state.page > 1 ? {maxWidth: '900px'} : {}} className={this.state.page === 1 ? 'col-lg-6 col-md-8' : 'col-md-12'}>
                <div className="cell validation" style={{padding: 0}}>
                  <div style={{marginTop: 0}} className={"validation__sign-box"}>
                    {this.state.page === 1 ? <SendSms token={this.state.token} inModal={false} onSmsVerified={this.onSmsVerified.bind(this)}/> : null}
                    {this.state.page !== 1 ? (
                      <>
                        {zaznamPodepsan  === true && podepsano === 0 && <SmsSjednani hideHeading={true} onSmsVerified={this.onSmsVerified.bind(this)} token={this.state.token} cislo_smlouvy={cislo_smlouvy} />}
                        {zaznamPodepsan  === true && podepsano === 1 && <div className="validation__sign-box"><div><span><span className="validation__info">Smlouva byla úspěšně podepsána.</span></span></div></div>}
                        {(zaznamPodepsan  === false || zaznamPodepsan === undefined) && <div className="text-red">K podpisu smlouvy je potřeba nejprve podepsat záznam.</div>}
                      </>
                    ) : null}


                  </div>
                </div>

              </div>
            </div>

          </div>
        ) : null}
        </div>

			</div>
		)
	}
}
