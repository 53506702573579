class Uzivatel {
	constructor(data) {
		this.data = data;
	}

	getExpirationAt = () => {
		return this.data.expirationAt ? this.data.expirationAt : null;
	};

	hasExpired = () => {
		let now = new Date();
		let curTime = now.getTime() / 1000;
		let exp = this.getExpirationAt();
		let expired = true;
		if (exp) {
			expired = exp <= curTime;
		}
		return expired;
	};

	userName = () => {
		return this.data.username;
	};

	firstLogin = () => {
		return this.data.firstLogin;
	};

	changePassword = () => {
		return this.data.zmenahesla;
	};

	readAccessOnly = () => {
		return this.data.readOnly ? (this.data.readOnly * 1) === 1 : false;
	};
}

export default Uzivatel;