// all api calls should be done using
// functions exported from this module

// axios is a library for simplifying
// work with ajax requests
import axios from "axios";
// we need to import history as well
// (to redirect user to login if he gets logged out etc.)
import * as PubSub from "pubsub-js";
import {getContainer, setCookie} from "./generic";
import alertDialog from "./alertDialog";

// connector base for myplann api
// base url and auth token are defined in .env file
axios.defaults.withCredentials = true;
const api = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
	timeout: 600000, // timeout 10 minutes to upload files
	crossDomain: true,
	headers: {
		'auth': process.env.REACT_APP_AUTH_TOKEN
	}
});

export function handleHttpError(error) {
	if (error.response) {
		// The request was made and the server responded with a status code
		// that falls out of the range of 2xx

		if (error.response.status === 401) {
			logout();
		}

		if (error.response) {
			if (error.response.data) {
				if (error.response.data.exception) {
					if (error.response.data.exception.instanceOf.indexOf('ReadOnlyAccessException') !== -1) {
						alertDialog("Není vám umožněno editovat", "Váš přístup do systému je pouze pro čtení. Kontaktuje svého poradce pro více informací.");
					}
				}
			}
		}

	} else if (error.request) {
		// The request was made but no response was received
		// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
		// http.ClientRequest in node.js
		// logout();
		console.log(error);
		PubSub.publish('apiError', {message: 'Ztráta připojení.'});
	} else {
		// Something happened in setting up the request that triggered an Error

		PubSub.publish('apiError', error);
	}
	// console.log(error.config);
}

// use this to post a json via api
export function put(url, data, hideSpinner = false) {
	PubSub.publish('requestStarted', {target: this, hideSpinner: hideSpinner});
	return api.put(url, data).then((response) => {
		PubSub.publish('requestFinished', {target: this, hideSpinner: hideSpinner});
		return response;
	}).catch((e) => {
		handleHttpError(e);
		PubSub.publish('requestFinishedWithError', {target: this, error: e});
		PubSub.publish('requestFinished', {target: this, hideSpinner: hideSpinner});
		throw e;
	});
}

// use this to post a json via api
export function post(url, data, config

?, hideSpinner = false
)
{
	PubSub.publish('requestStarted', {target: this, hideSpinner: hideSpinner});
	return api.post(url, data, config).then((response) => {
		PubSub.publish('requestFinished', {target: this, hideSpinner: hideSpinner});
		return response;
	}).catch((e) => {
		handleHttpError(e);
		PubSub.publish('requestFinishedWithError', {target: this, error: e});
		PubSub.publish('requestFinished', {target: this, hideSpinner: hideSpinner});
		throw e;
	});
}

// use this to get a json via api
export function get(url, handleError = true, config = null, progress = false, hideSpinner = false) {
	PubSub.publish('requestStarted', {target: this, progress, hideSpinner});
	return api.get(url, config).then((response) => {
		PubSub.publish('requestFinished', {target: this, hideSpinner});
		return response;
	}).catch((e) => {
		if (handleError) {
			handleHttpError(e);
		}
		PubSub.publish('requestFinishedWithError', {target: this, error: e});
		PubSub.publish('requestFinished', {target: this});
		throw e;
	});
}

// use this to get a json via api
export function httpDelete(url) {
	PubSub.publish('requestStarted', {target: this});
	return api.delete(url).then((response) => {
		PubSub.publish('requestFinished', {target: this});
		return response;
	}).catch((e) => {
		handleHttpError(e);
		PubSub.publish('requestFinishedWithError', {target: this, error: e});
		PubSub.publish('requestFinished', {target: this});
		throw e;
	});
}

// logs user in and saves data to sessionStorage
export function login(username = undefined, password = undefined, signInToken = undefined, csrfToken = undefined) {
	return publishRequest(_login, username, password, signInToken, csrfToken);
}

function _login(username = undefined, password = undefined, signInToken = undefined, csrfToken = undefined) {
	return api.post('/sign/in', {
		login: username,
		password,
		signInToken,
    csrfToken,
	}).then(response => {
		window.userObject = null;
		getContainer().set('uzivatel', null);
    sessionStorage.removeItem('user');
		if (typeof response.data === 'object') {
      sessionStorage.setItem('user', JSON.stringify(response.data));
		}
		return response;
	})
}

export function getCSRFToken() {
  return publishRequest(_getCSRFToken);
}

function _getCSRFToken() {
  return api.post('/sign/token', {}).then(response => {
    return response;
  })
}

// logs user out and redirects to /login
export function logout(redirect : boolean = true) {
	getContainer().getMainCache().clear();
  sessionStorage.removeItem("user");
	setCookie('PHPSESSID', '', -1);
	return get('/sign/out').then((r) => {
		if (redirect) {
			window.location.href = "/login"; // resi problem s Cookie
		}
		return r;
	});
	// history.push("/login");
}

export function forcedChangePassword(password, password1) {
	return publishRequest(_forcedChangePassword, password, password1);
}

function _forcedChangePassword(password, password1) {
	return getContainer().getMyplannClient()._forced_zmenitHeslo(password, password1).then(response => {
		return _login(getContainer().getUzivatel().userName(), password);
	});
}

export function changePassword(oldPassword, newPassword) {
	return publishRequest(getContainer().getMyplannClient()._profil_zmenitHeslo, oldPassword, newPassword);
}

function publishRequest(request, ...params) {
	PubSub.publish('requestStarted', {target: this});
	return request(...params).then(response => {
		PubSub.publish('requestFinished', {target: this});
		return response;
	}).catch((e) => {
		PubSub.publish('requestFinishedWithError', {target: this, error: e});
		PubSub.publish('requestFinished', {target: this});
		throw e;
	});
}

// get user data from local storage
export function getUser() {
	return sessionStorage.getItem('user');
}

// get user data from local storage
export function getUserObject() {
	if (window.userObject === undefined || window.userObject === null) {
		window.userObject = JSON.parse(sessionStorage.getItem('user'));
	}

	return window.userObject;
}

// checks if user is logged in
export function loggedIn() {
	return getUser() ? true : false;
}

// returns axians instance api configuration
export function getApi() {
	return api;
}
