export default class SmartformClientResponse {
	constructor(response) {
		this.response = response;
		this.data = response.data;
	}

	isOk = () => {
		return this.data && this.data.resultCode === 'OK';
	};

	getErrorMessage = () => {
		return this.data.errorMessage;
	};

	getId = () => {
		return this.data.id;
	};

	getInputSuggestions = () => {
		if (this.isOk() && this.data.suggestions) {
			return this.data.suggestions.map((item, index) => {
				return {
					index: index,
					name: item.fieldType,
					value: item.values[item.fieldType],
					label: item.values[item.fieldType],
				};
			});
		}
		return [];
	};

	getInputSuggestionsFor = (valueField: SmartformClientRequestFieldType, labelField: ?SmartformClientRequestFieldType) => {
		if (this.isOk() && this.data.suggestions) {
			return this.data.suggestions.map((item, index) => {
				return {
					index: index,
					name: valueField,
					value: item.values[valueField],
					label: item.values[labelField ? labelField : valueField],
				};
			});
		}
		return [];
	};

	getSuggestionValuesByIndex = (index) => {
		if (this.isOk() && this.data.suggestions) {
			return this.data.suggestions[index].values;
		}
		return {};
	};
}