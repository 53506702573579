import React, {Component} from "react";


export default class GdprText extends Component {


  render = () => {


    return (
      <div className={'p-padding'}>
        <p>
          Já, svým podpisem níže, učiněným v elektronické podobě, <strong>výslovně</strong> potvrzuji, že jsem se v
          plném rozsahu seznámil(a) s níže uvedenými informacemi a dále, že jsem učinil(a) a udělil(a) osobně
          (nikoli v zastoupení jinou osobou) níže uvedená prohlášení a souhlasy a současně potvrzuji, že veškeré
          výše uvedené identifikační údaje mé osoby, příp. mých nezletilých dětí jsou přesnými a pravými údaji
          identifikujícími moji osobu.
        </p>

        <p>
          <strong>
            Informace dle Nařízení Evropského parlamentu a Rady (EU) 2016/679 o ochraně fyzických osob v souvislosti
            se zpracováním osobních údajů (obecné nařízení o ochraně osobních údajů):
          </strong>
          <ol className={'list'}>
            <li>
              <strong>totožnost a kontaktní údaje správce:</strong> SAB servis, s.r.o., se sídlem Praha 1 - Nové
              Město, Jungmannova 748/30, PSČ 11000, IČO: 24704008, zapsaná v OR u Městského soudu v Praze, oddíl C,
              vložka 167427;
              <br/>
              kontaktní údaje pověřence pro ochranu osobních údajů: Miroslav Švenda, Jánský vršek 323/13, 118 00
              Praha 1 – Malá Strana, e-mail: svenda@sabservis.cz, tel: +420257223191;
            </li>
            <li>
              <strong>účely zpracování osobních údajů:</strong>
              <ul className={'list'}>
                <li>
                  poskytování služeb spočívajících ve zprostředkování uzavírání smluv, zejména zprostředkování
                  pojištění, poskytování investičních služeb, zprostředkování spotřebitelských úvěrů;
                </li>
                <li>
                  plnění zákonných povinností správce jako investičního zprostředkovatele a samostatného
                  zprostředkovatele spotřebitelského úvěru vyplývajících ze zvláštních předpisů (např. zákona č.
                  170/2018 Sb., zákona č. 256/2004 Sb., zákona č. 247/2016 Sb., zákona č. 253/2008 Sb.);
                </li>
                <li>
                  ukládání a uchovávání osobních údajů v databázi klientů (zákazníků) správce a současné předávání a
                  sdílení osobních údajů s příjemci uvedenými pod bodem 4. níže;
                </li>
                <li>
                  vnitřní potřeby, zejména ochrana práv a právem chráněných zájmů správce, sledování kvality služeb
                  a spokojenosti klientů a optimalizace poskytovaných služeb a produktů;
                </li>
                <li>
                  obchodní a (tele)marketingové, zejména nabízení služeb včetně zasílání obchodních sdělení,
                  informování o produktech a službách, a to včetně cílených nabídek produktů a služeb založených na
                  automatizovaném zpracování osobních údajů, vč. profilování.
                </li>
              </ul>
            </li>
            <li>
              <strong>právní základy zpracování:</strong><br/>
              souhlas subjektu údajů, plnění zákonných (právních) povinností správce (zejm. dle bodu 2. b. výše)
            </li>
            <li>
              <strong>kategorie příjemců osobních údajů:</strong><br/>
              osoby fyzické či právnické tvořící se správcem koncern a třetí osoby spolupracující se správcem;
              smluvní partneři správce, tj. zejména vázaní zástupci správce; obchodní partneři správce, tj. osoby,
              které zastupuje správce při uzavírání smluv se subjektem údajů, zejména banky, pojišťovny, investiční
              společnosti a fondy, penzijní společnosti, poskytovatelé spotřebitelských úvěrů, zdravotní pojišťovny,
              družstevní záložny; orgány veřejné moci při plnění zákonných povinností správce, např. Česká národní
              banka, Ministerstvo financí České republiky, Finanční analytický úřad
            </li>
            <li>
              <strong>osobní údaje nebudou předávány do států mimo EU, ani mezinárodní organizaci.</strong>
            </li>
            <li>
              <strong>doba, po kterou budou osobní údaje uloženy (zpracovávány):</strong><br/>
              po dobu využívání služeb správce, vždy však do odvolání souhlasu se zpracováním, v případě údajů
              potřebných pro plnění zákonných povinností správce po dobu stanovenou příslušnými právními předpisy
            </li>
            <li>
              <strong>upozorňujeme Vás na Vaše právo:</strong>
              <ul className={'list'}>
                <li>
                  požadovat po správci kdykoli přístup k Vašim osobním údajům;
                </li>
                <li>
                  požadovat po správci kdykoli opravu Vašich osobních údajů nebo jejich výmaz, popř. omezení jejich
                  zpracování;
                </li>
                <li>
                  dále máte právo vznést námitku proti zpracování Vašich osobních údajů;
                </li>
                <li>
                  na přenositelnost osobních údajů (resp. požádat o přenos Vašich osobních údajů přímo jinému
                  správci);
                </li>
                <li>
                  kdykoli odvolat Váš souhlas se zpracováním Vašich osobních údajů, aniž je tím ovšem dotčena
                  zákonnost zpracování založená na souhlasu uděleném před jeho odvoláním;
                </li>
                <li>
                  podat stížnost u dozorového úřadu, kterým je Úřad pro ochranu osobních údajů, sídlo: Pplk. Sochora
                  27, 170 00 Praha 7, www.uoou.cz;
                </li>
              </ul>
            </li>
            <li>
              <strong>dále Vás upozorňujeme na skutečnost, že poskytnutí Vašich osobních údajů je (smluvním)
                předpokladem toho, abychom Vám mohli poskytnout poradenské či další služby v plném rozsahu, tzn., že
                bez poskytnutí osobních údajů může dojít k omezení rozsahu služeb poskytovaných Vaší osobě.</strong>
            </li>
          </ol>
        </p>

        <p>
          <strong>Souhlas se zpracováním osobních údajů a s šířením obchodních sdělení</strong><br/>
          Prohlašuji, že jsem si v plném rozsahu prostudoval(a) informace poskytnuté mi v tomto dokumentu výše, a že
          jsem těmto informacím, včetně svých práv v souvislosti se zpracováním mých osobních údajů plně
          porozuměl(a), přičemž na tomto základě tímto uděluji SAB servis, s.r.o., se sídlem Praha 1 - Nové Město,
          Jungmannova 748/30, PSČ 11000, IČO: 24704008 svůj svobodný, informovaný a výslovný souhlas s tím, aby
          veškeré mnou poskytnuté osobní údaje, vč. osobních údajů mého nezletilého dítěte, tj. zejména jméno,
          příjmení, datum narození, RČ, adresa bydliště, telefonní číslo – mobilní/pevná linka, e-mailová adresa či
          jiný tzv. elektronický kontakt, včetně tzv. zvláštní kategorie osobních údajů, tj. údajů o mém zdravotním
          stavu, byly jím zpracovávány k účelům uvedeným výše pod bodem 2. <br/>
          <strong>Tento svůj souhlas uděluji správci na dobu neurčitou, tj. až do jeho odvolání.</strong>
        </p>

        <p>
          Současně uděluji dle zákona č. 480/2004 Sb. správci souhlas k občasnému elektronickému zasílání sdělení
          (novinky z oblasti financí, informace o činnosti správce, nabídky) na mou e-mailovou adresu či jiný
          elektronický kontakt. Jsem si vědom(a) svého práva tento souhlas kdykoli odvolat.
        </p>

        <p>
          <strong>Poučení a prohlášení související s přístupem klienta do systému myplann</strong><br/>
          Provozovatelem systému myplann a držitelem autorských práv k němu je společnost SAB servis, s.r.o.,
          Jungmannova 748/30, 110 00 Praha 1, IČ 24704008. SAB servis, s.r.o. jako správce systému umožňuje přístup
          na tyto stránky svým smluvním partnerům – finančním poradcům, kteří vkládají do systému myplann obsah a
          spravují klientské sekce v systému. Za obsah vložený do systému myplann odpovídají výhradně jednotliví
          finanční poradci, čemuž rozumím a beru to na vědomí. Vůči SAB servis, s.r.o. nemám, s výjimkou práv
          týkajících se mých osobních údajů, které SAB servis, s.r.o. zpracovává, žádná práva z titulu obsahu
          systému myplann a žádná nebudu jakýmkoli způsobem uplatňovat.
        </p>

        <p>
          Při používání systému myplann se budu řídit veškerými právními předpisy v souladu s obecně uznávanými
          zásadami dobrých mravů. Služby systému myplann nebudu zneužívat, nebudu do systému zasahovat, ani se do
          něj snažit získat přístup jinak než přes určené rozhraní. Při porušení těchto zásad beru na vědomí, že mi
          může být přístup do systému myplann ukončen.
        </p>

        <p>
          Podmínkou udělení přístupu do systému myplann je poskytnutí souhlasu se zpracováním svých osobních údajů
          společnosti SAB servis, s.r.o. Jsem si vědom toho, že mé osobní údaje, které jsem poskytl při přihlášení
          do systému myplann nebo při jeho používání, budou zpracovávány v souladu s tímto souhlasem se zpracováním
          osobních údajů.
        </p>

        <p>
          Výše uvedené prohlášení činím pro účely prvního přístupu do systému myplann a platí i pro veškeré další
          přístupy do systému. Toto prohlášení, včetně souhlasů obsažených v tomto dokumentu, je právně závazným
          dokumentem, čehož jsem si vědom.
        </p>

        <p>
          Po zaškrtnutí “Souhlas s elektronickou formou komunikace”, vložením čísla dokladu totožnosti a vložením
          svého telefonního čísla, vyjadřuji výslovný souhlas se zpracováním svých osobních údajů, zasílání
          obchodních sdělení a s elektronickou formou komunikace a žádám, aby elektronická komunikace, vč. zasílání
          jakýchkoli přístupových či ověřovacích hesel či kódů, byla tímto správcem s mojí osobou vedena výlučně
          prostřednictvím těchto elektronických kontaktů:
        </p>

        <p>
          mobilní telefonní číslo, které zadávám v dalším kroku udělení souhlasů zde v MyPlann a
        </p>

        <p>
          e-mailová adresa: {this.props.email}
        </p>

        <p>
          Souhlasím s tím, aby správce spol. SAB servis zpracovával tyto moje elektronické kontakty též za účelem
          elektronické komunikace s mojí osobou a to včetně možnosti z mé strany elektronicky podepisovat smluvní
          dokumenty nebo činit jiné právní kroky, vydávat instrukce apod. Výslovně prohlašuji, že se jedná o moje
          osobní kontaktní údaje, když k předanému mobilnímu číslu a e-mailové adrese mám přístup pouze já osobně a
          zavazuji se provést nezbytná opatření k tomu, abych zamezil(a) možnosti zneužití těchto komunikačních
          prostředků třetí osobou.
        </p>
      </div>
    );
  }
}
