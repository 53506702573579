/**
 * Get name of icon by file mimetype
 *
 * @param mimetype string|null
 * @returns string|null
 */
export function getFileIcon(mimetype) {
	if (mimetype) {

		let mimeSplit = mimetype.split("/");

		if (mimeSplit.length === 2) {
			let allowTypes = [
				'msword',
				'vnd.openxmlformats-officedocument.wordprocessingml.document',
				'vnd.ms-powerpoint',
				'vnd.openxmlformats-officedocument.presentationml.presentation',
				'vnd.ms-excel',
				'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				'pdf',
				'rtf',
				'plain',
				'vnd.oasis.opendocument.spreadsheet',
				'vnd.oasis.opendocument.text',
				'vnd.oasis.opendocument.presentation',
				'gif',
				'jpg',
				'jpeg',
				'png',
				'x-rar',
				'x-rar-compressed',
				'x-zip-compressed',
				'x-zip',
				'zip'];

			for (let i = 0; i < allowTypes.length; i++) {
				if (allowTypes[i] === mimeSplit[1]) {
					if (i <= 1) {
						return 'word';
					} else if (i <= 3) {
						return 'powerpoint';
					} else if (i <= 5) {
						return 'excel';
					} else if (i <= 6) {
						return 'pdf';
					} else if (i <= 11) {
						return 'text';
					} else if (i <= 15) {
						return 'image';
					} else if (i <= 20) {
						return 'archive';
					}
				}
			}
		}
	}

	return 'unknown';
}