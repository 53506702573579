import React, {Component} from "react";
import PojisteneOsobyRizika from "./PojisteneOsobyRizika";
import {getContainer} from "../../../utils/generic";

export default class PojisteneOsoby extends Component {
	constructor(props) {
		super(props);

		let state = {
			activeTabIndex: props.activeTabIndex ? props.activeTabIndex : -1,
			pojisteneOsoby: props.pojisteneOsoby ? props.pojisteneOsoby : [],
			onChange: props.onChange ? props.onChange : (e, index) => {
				let name = e.target.name.replace("osoby[" + index + "]", "");
				this.state.pojisteneOsoby[index][name] = e.target.value;
				this.setState({});
			}
		};

		state.pojisteneOsoby.forEach((item, index) => {
			if (item.unsaved === true) {
				delete state.pojisteneOsoby[index];// = undefined;
			} else {
				if (state.activeTabIndex === -1) {
					state.activeTabIndex = index;
				}

        state.pojisteneOsoby[index].__rizika__.map((riziko, index2) => {
          riziko.smazano = 0;
          if (riziko.unsaved === true) {
            delete state.pojisteneOsoby[index].__rizika__[index2];// = undefined;
          }
          return null;
        });
			}
		});
		this.state = state;

		this._uzivatel = getContainer().getUzivatel();
	}

	changeTab = (e, index) => {
		this.setState({
			activeTabIndex: index
		});
	};

	createTab = (e) => {
		var maxIndex = -1;
		this.state.pojisteneOsoby.map((item, index) => {
			if (index > maxIndex) {
				maxIndex = index;
			}
			return '';
		});

		let newIndex = maxIndex + 1;
		let pojisteneOsoby = this.state.pojisteneOsoby;
		pojisteneOsoby[newIndex] = {
			id: null,
			jmeno: "",
			prijmeni: "",
			dospely_dite: 'dospely',
			rodne_cislo: "",
			unsaved: true,
      __rizika__: []
		};

		this.setState({
			activeTabIndex: newIndex,
			pojisteneOsoby
		});

		this.props.onFormChange();
	};

	onChange = (e, index) => {
		// this.setState({ [e.target.name]: e.target.value });
		this.state.onChange(e, index);
		this.props.onFormChange();
	};

	render() {
		return (
			<div className={'portfolio-form-subform'}>
				<div className={'portfolio-form-subform__header padding--vertical--10'}>
					Pojištěné osoby
				</div>
				<div className={'portfolio-form-subform__triggers'}>
					{this._uzivatel.readAccessOnly() ? '' :
						<div
							className={'portfolio-form-subform__triggers__trigger portfolio-form-subform__triggers__trigger--plus pointer'}
							onClick={(e) => {
								this.createTab(e)
							}}>
							+
						</div>
					}
					{this.state.pojisteneOsoby.map((osoba, index) => {
						return (
							<div key={index}
								 className={'portfolio-form-subform__triggers__trigger pointer' + (this.state.activeTabIndex === index ? ' active' : '')}
								 onClick={(e) => {
									 this.changeTab(e, index)
								 }}>
								{index + 1}
							</div>
						);
					})}
				</div>
				<div className={'portfolio-form-subform__content'}>
					{this.state.activeTabIndex === -1 ?
						<span>
                            {this._uzivatel.readAccessOnly() ?
								<span className={'text-weight--400 font-size--11'}>
                                    Nejsou uloženy žádné pojištěné osoby.
                                </span>
								:
								<span className={'text-weight--400 font-size--11'}>
                                    Přidejte pojištěnou osobu pomocí tlačítka <span
									className={'portfolio-form-subform__trigger-demo'} onClick={(e) => {
									this.createTab(e)
								}}>+</span>
                                </span>
							}
                        </span>
						: ''}
					{this.state.pojisteneOsoby.map((osoba, index) => {
						if (this.state.activeTabIndex !== index) {
							return '';
						}
						return (
							<div key={index} className="portfolio-form-subform__osoby">
								<div className={'row input-row'} style={{marginRight: '10px'}}>
                  <div className={'col-sm-3'}>
                  <label htmlFor={'osoby[' + index + ']dospely_dite'}>Dospělý/dítě</label>
                  <select tabIndex={0} onChange={(e) => {
                    this.onChange(e, index)
                  }} name={'osoby[' + index + ']dospely_dite'} value={osoba.dospely_dite}>
                    <option value="dospely">Dospělý</option>
                    <option value="dite">Dítě</option>
                  </select>
                  </div>

                  <div className={'col-sm-3'}>
                  <label htmlFor={'osoby[' + index + ']jmeno'}>Jméno</label>
                  <input tabIndex={1} onChange={(e) => {
                    this.onChange(e, index)
                  }} type="text" name={'osoby[' + index + ']jmeno'} value={osoba.jmeno}/>
                  </div>

                  <div className={'col-sm-3'}>
                  <label htmlFor={'osoby[' + index + ']pracovni_neschopnost'}>Příjmení</label>
                  <input tabIndex={2} onChange={(e) => {
                    this.onChange(e, index)
                  }} type="text" name={'osoby[' + index + ']prijmeni'}
                         value={osoba.prijmeni}/>
                  </div>

                  <div className={'col-sm-3'}>
                  <label htmlFor={'osoby[' + index + ']rodne_cislo'}>Rodné číslo</label>
                  <input tabIndex={3} onChange={(e) => {
                    this.onChange(e, index)
                  }} type="text" name={'osoby[' + index + ']rodne_cislo'}
                         value={osoba.rodne_cislo}/>
                  </div>
                </div>

                <PojisteneOsobyRizika pojisteneOsobyRizika={osoba.__rizika__} onFormChange={this.props.onFormChange}/>

							</div>
						);
					})}
				</div>
			</div>
		);
	}
}
