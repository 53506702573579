import React, {Component} from "react";

class DetailZaznamu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			smlouvaInfo: props.smlouvaInfo,
      showHtmlDetail: props.showHtmlDetail === undefined ? true : props.showHtmlDetail,
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			smlouvaInfo: nextProps.smlouvaInfo,
      showHtmlDetail: nextProps.showHtmlDetail === undefined ? true : nextProps.showHtmlDetail,
		});
	}

	render = () => {
		return (
			<div>
				{this.state.smlouvaInfo === undefined || this.state.smlouvaInfo === null ? "" :
					<div className="event-detail">

						<h2 className="padding--bottom--10">Informace o záznamu</h2>

						<div className="responsive-table responsive-table--second-next-first">
							<div className="tr">
								<div className="td"><strong className="text-weight--600 text-nowrap">Klient:</strong>
								</div>
								<div className="td width--100--percent">{this.state.smlouvaInfo.klient}</div>
							</div>
							<div className="tr">
								<div className="td"><strong className="text-weight--600 text-nowrap">Oblast:</strong>
								</div>
								<div className="td width--100--percent">{this.state.smlouvaInfo.oblast}</div>
							</div>
							{this.state.smlouvaInfo.producent !== null ?
							<div className="tr">
								<div className="td"><strong className="text-weight--600 text-nowrap">Producent:</strong>
								</div>
								<div className="td width--100--percent">{this.state.smlouvaInfo.producent}</div>
							</div>
								: ''}
							{this.state.smlouvaInfo.datumOdeslani !== null ?
							<div className="tr">
								<div className="td"><strong className="text-weight--600 text-nowrap">Datum
									odeslání:</strong></div>
								<div className="td width--100--percent">{this.state.smlouvaInfo.datumOdeslani}</div>
							</div>
								: ''}
						</div>
            {this.state.smlouvaInfo.htmlDetail && this.state.showHtmlDetail ?
            <div className="width--100--percent" dangerouslySetInnerHTML={{__html: this.state.smlouvaInfo.htmlDetail}} />
              : ''}

            {this.state.smlouvaInfo.poradce_ip !== null && this.state.smlouvaInfo.poradce_podpis && this.state.smlouvaInfo.poradce_datum_podpisu !== null ?
              <div className="responsive-table responsive-table--second-next-first" style={{marginTop: '20px'}}>
                <div className="tr">
                  <div className="td" style={{verticalAlign:'top'}}><strong className="text-weight--600 text-nowrap">Podpis poradce:</strong></div>
                  <div className="td width--100--percent">
                    <span dangerouslySetInnerHTML={{__html : removeTags(this.state.smlouvaInfo.poradce_podpis.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '<br>' + '$2'))}} /> <br />
                    IP: {this.state.smlouvaInfo.poradce_ip} <br />
                    {this.state.smlouvaInfo.poradce_datum_podpisu}
                  </div>
                </div>
              </div>
              : ''}


					</div>
				}
			</div>
		);
	}
}

function removeTags(html)
{
  var html = html.replace("<br>","||br||");
  var tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  html = tmp.textContent||tmp.innerText;
  return html.replace("||br||","<br>");
}

export default DetailZaznamu;
