import {get, getApi, post, put} from "../utils/Api";
import DropzoneFile from "../components/Portfolio/DropzoneFile";
import * as PubSub from "pubsub-js";
import alertDialog from "../utils/alertDialog";
import MainCache from "./MainCache";

class MyplannClient {

    constructor(cache : MainCache) {
    	this.mainCache = cache;
    }

	_sign_zapomenuteHesloOdeslatToken = (username, origin) => {
		return getApi().post("/sign/zapomenuteHesloOdeslatToken?username=" + username + '&origin=' + origin);
	};

	_sign_zapomenuteHesloZvalidovatToken = (id, token) => {
		return getApi().post("/sign/zapomenuteHesloZvalidovatToken?un=" + id + "&token=" + token);
	};

	_sign_vytvoreniPristupuZvalidovatToken = (token) => {
		return getApi().post("/sign/vytvoreniPristupuZvalidovatToken?token=" + token);
	};

  _sign_vytvoreniPristupuNastaveniHesla = (token, password) => {
    return getApi().put("/sign/vytvoreniPristupuNastaveniHesla", {
      token, password
    });
  };

  _sign_vytvoreniPristupuPotvrzeni = (token, pin) => {
    return getApi().put("/sign/vytvoreniPristupuPotvrzeni", {
      token,
      pin
    });
  };

  _sign_zapomenuteHesloZmenitHeslo = (id, token, password) => {
		return getApi().put("/sign/zapomenuteHesloZmenitHeslo", {
			id,
			token,
			password
		});
	};

	_sign_zapomenuteHesloPotvrditZmenuHesla = (id, token, pin) => {
		return getApi().put("/sign/zapomenuteHesloPotvrditZmenuHesla", {
			id,
			token,
			pin
		});
	};

	userInfo = (useCache = true) => {
		let cachedResponse = this.mainCache.userInfo();

		if (useCache && cachedResponse) {
			return new Promise((resolve, reject) => {
				resolve(cachedResponse);
			});
		} else {
			return get("/user/info", false).then((response) => {
				if (response && response.data && response.data.data) {
					this.mainCache.userInfo(response.data.data);
					return response.data.data;
				} else {
					return null;
				}
			});
		}
	};

	updateUserInfo = (newData) => {
		return this.mainCache.userInfo(newData);
	};

	_forced_zmenitHeslo = (password, password2) => {
		return getApi().post('/user/zmenaHesla?zmenaProvedena=1', {
			password,
			password2
		});
	};

	_profil_zmenitHeslo = (aktualniHeslo, heslo) => {
		return getApi().put('/user/info', {
			aktualniHeslo: aktualniHeslo,
			password: heslo
		});
	};

	_producenti_index = () => {
		return get("/producenti/index");
	};

	majetekProdejZrusit = (id_prodeje: Number) => {
		return put(this.buildURL('/prodeje/majetekProdejZrusit', {
			id: id_prodeje
		}));
	};

	majetekNakupZrusit = (id_nakupu: Number) => {
		return put(this.buildURL('/nakupy/majetekNakupZrusit', {
			id_nakupu
		}));
	};

	majetekPronajemZrusit = (id_majetek: Number) => {
		return put(this.buildURL('/pronajmy/majetekPronajemZrusit', {
			id_majetek
		}));
	};

	majetekProdejStavy = (id_majetek: Number) => {
		return get(this.buildURL('/prodeje/majetekProdejStavy', {
			id_majetek
		}));
	};

	pronajmy = (id_majetek: Number) => {
		return get(this.buildURL('/pronajmy/pronajmy', {
			id_majetek
		}));
	};

	nakupy = () => {
		return get(this.buildURL('/nakupy/nakupy', {}));
	};

	nakupyPrehled = (id_nakupu: Number = undefined) => {
		return get(this.buildURL('/nakupy/nakupyPrehled', {
			id_nakupu
		}));
	};

	updateNakupMajetku = (id_nakupu, data) => {
		// return this.dummy();
		return put(this.buildURL('/nakupy/nakupMajetek',
			{
				id_nakupu
			}),
			data
		);
	};

	createNakupMajetku = (druh, data) => {
		// return this.dummy();
		return post(this.buildURL('/nakupy/nakupMajetek', {
			druh
		}), data);
	};

	majetekKProdeji = () => {
		return get(this.buildURL('/prodeje/majetekKProdeji'));
	};

	majetekKPronajmu = () => {
		return this.majetekKProdeji();
	};

	prodejMajetek = (id_majetek) => {
		return post(this.buildURL('/prodeje/prodejMajetek', {
			id_majetek,
			potvrzeno: 1
		}));
	};

	pronajmyMajetek = (id_majetek, type: String) => {
		return post(this.buildURL('/pronajmy/pronajemMajetek', {
			id_majetek,
			potvrzeno: 1,
			type
		}));
	};

  prvniPrihlaseni_odeslatZnovuSms = (telefon: String, telefon_original: String, gold: boolean) => {
    return put(this.buildURL('/user/prvniPrihlaseniOdeslatZnovuSms', {
      telefon: telefon ? encodeURIComponent(telefon) : undefined,
      telefon_original: telefon_original ? encodeURIComponent(telefon_original) : undefined,
      gold: gold ? encodeURIComponent(gold) : undefined,
    }));
  };

  souhlas_odeslatZnovuSms = (telefon: String, telefon_original: String, token: String) => {
    return put(this.buildURL('/gdpr/odeslatZnovuSms', {
      telefon: telefon ? encodeURIComponent(telefon) : undefined,
      telefon_original: telefon_original ? encodeURIComponent(telefon_original) : undefined,
      token: encodeURIComponent(token),
    }));
  };

  souhlas_souhlasUdelen = (code: String, telefon: String, telefon_original: String, gold: boolean, deti: Array, token: String) => {
    return getApi().post("/gdpr/verify?souhlasUdelen=1&smsCode=" + code + "&telefon=" + encodeURIComponent(telefon)+ "&token=" + encodeURIComponent(token) + "&telefon_original=" + encodeURIComponent(telefon_original) + "&gold=" + (gold ? 1 : 0),JSON.stringify(deti));
  };

  souhlas_modryPotvrzen = (token: String) => {
    return getApi().post("/gdpr/verify-blue?token=" + encodeURIComponent(token));
  };


  prvniPrihlaseni_souhlasUdelen = (code: String, telefon: String, telefon_original: String, gold: boolean, deti: Array) => {
		return getApi().post("/user/prvniPrihlaseni?souhlasUdelen=1&smsCode=" + code + "&telefon=" + encodeURIComponent(telefon) + "&telefon_original=" + encodeURIComponent(telefon_original) + "&gold=" + (gold ? 1 : 0),JSON.stringify(deti));
	};

  kontrolaDokladu = (type: String, number: String) => {
    return getApi().get("/user/kontrolaDokladu?type=" + encodeURIComponent(type) + "&number=" + encodeURIComponent(number));
  };

  kontrolaDokladuSouhlas = (type: String, number: String, token: String) => {
    return getApi().get("/gdpr/kontrolaDokladuSouhlas?type=" + encodeURIComponent(type) + "&number=" + encodeURIComponent(number)+ "&token=" + encodeURIComponent(token));
  };

  uploadSmlouvyFiles = (id_portfolio, type, files: DropzoneFile[], hideSpinner = false) => {

		let fdata = new FormData();

		files.forEach((item, i) => {
			let file = files[i];
			fdata.append('files[' + i + ']', file.getFile(), file.getName());
			fdata.append('file_ids[' + i + ']', file.getId());
			fdata.append('file_custom_names[' + i + ']', file.getCustomName());
			fdata.append('id_portfolio', id_portfolio);
			fdata.append('type', type);
		});

		const config = {
			onUploadProgress: function (progressEvent) {
				let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
				PubSub.publish('uploadSmlouvyFilesProgress', {
					data: {
						id_portfolio,
						type,
						files,
					},
					target: this,
					percentCompleted: percentCompleted,
					originalEvent: progressEvent
				});
			},
			headers: {'content-type': 'multipart/form-data'}
		};

		return post("/portfolio/smlouvyForm", fdata, config, hideSpinner).then(
			response => {
				return response;
			}
		);
	};

	uploadPropertyImages = (idProperty, files: DropzoneFile[], hideSpinner = false) => {

		let fdata = new FormData();
		files.forEach((item, i) => {
			fdata.append('files[' + i + ']', item.getFile(), item.getName());
			fdata.append('file_custom_names[' + i + ']', item.getCustomName());
			fdata.append('id_majetek', idProperty);
		});

		const config = {
			onUploadProgress: function (progressEvent) {
				let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
				PubSub.publish('uploadSmlouvyFilesProgress', {
					data: {
						idProperty,
						files,
					},
					target: this,
					percentCompleted: percentCompleted,
					originalEvent: progressEvent
				});
			},
			headers: {'content-type': 'multipart/form-data'}
		};

		return post("/portfolio/majetek-upload?id=" + idProperty, fdata, config, hideSpinner).then(
			response => {
				return response;
			}
		);
	};

	__dokumenty_index = (id = undefined, id_portfolio = undefined, id_majetek = undefined, perPage = undefined) => {
		return get(this.buildURL('/dokumenty/index', {
			id: id ? id : undefined,
            id_portfolio: id_portfolio ? id_portfolio : undefined,
            id_majetek: id_majetek ? id_majetek : undefined,
            perPage: perPage ? perPage : undefined
		}));
	};

	__mplanHomeImage = () => {
		return get('/nastaveni/get-mplann-image');
	};

  __dokumenty_download = (documentID) => {
    let url = '/dokumenty/download?id=' + documentID;
    return get(url + '&justCheck=1', true).then((r) => {
      if (r) {
        window.location.href = process.env.REACT_APP_BASE_URL + url;
      }
      return r;
    }).catch((response) => {
      let notFoundMsg = this.getExceptionMessageFromResponse(response.response, 'FileNotFoundException');
      if (notFoundMsg !== null) {
        alertDialog("Soubor nenalezen",
          "Omlouváme se, ale tento soubor již neexistuje. V případě dotazů se obraťte na svého poradce.");
      }
      throw response;
    });
  };

  _ukol_dilci_change_state = (dilciId: String, confirm: boolean) => {
    return getApi().get("/ukol/dilcichangestate?id=" + encodeURIComponent(dilciId) + "&confirm=" + encodeURIComponent(confirm));
  };

  __ukol_attachment_download = (attachmentID) => {
    let url = '/ukol/download?id=' + attachmentID;
    return get(url + '&justCheck=1', true).then((r) => {
      if (r) {
        window.location.href = process.env.REACT_APP_BASE_URL + url;
      }
      return r;
    }).catch((response) => {
      let notFoundMsg = this.getExceptionMessageFromResponse(response.response, 'FileNotFoundException');
      if (notFoundMsg !== null) {
        alertDialog("Soubor nenalezen",
          "Omlouváme se, ale tento soubor již neexistuje. V případě dotazů se obraťte na svého poradce.");
      }
      throw response;
    });
  };

  __dokumenty_download_url = (documentID) => {
    return process.env.REACT_APP_BASE_URL + '/dokumenty/download?id=' + documentID;
  };

  __ukol_attachment_download_url = (documentID) => {
    return process.env.REACT_APP_BASE_URL + '/ukol/download?id=' + documentID;
  };

	_intervence_dokumentStahnout = (id, id_intervence) => {
		let url = '/intervence/dokumentStahnout?id_dokument=' + id + '&id=' + id_intervence;
		return get(url + '&justCheck=1', true).then((r) => {
			if (r) {
				window.location.href = process.env.REACT_APP_BASE_URL + url;
			}
			return r;
		}).catch((response) => {
			let notFoundMsg = this.getExceptionMessageFromResponse(response.response, 'FileNotFoundException');
			if (notFoundMsg !== null) {
				alertDialog("Soubor nenalezen",
					"Omlouváme se, ale tento soubor již neexistuje. V případě dotazů se obraťte na svého poradce.");
			}
			throw response;
		});
	};

	_intervence_dokumentStahnout_url = (id, id_intervence) => {
		return process.env.REACT_APP_BASE_URL + '/intervence/dokumentStahnout?id_dokument=' + id + '&id=' + id_intervence;
	};

  getNastaveni = (handleError = true) => {
    return get("/nastaveni/index", handleError).then((resp) => {
      if (resp && resp.data) {
        if (resp.data.data) {

          sessionStorage.setItem(
            'logo',
            resp.data.data.logo ? resp.data.data.logo : ''
          );

          return resp.data.data;
        }
      } else {
        throw(resp);
      }
    });
  };

	_producenti_index = (oblast) => {
		let cachedResponse = this.mainCache.producenti(oblast);
        if (cachedResponse) {
            return new Promise((resolve, reject) => {
            	resolve(cachedResponse);
			});
        } else {
            return get("/producenti/index?oblast=" + oblast, true, null, false, true).then((r) => {
            	this.mainCache.producenti(oblast, r);
            	return r;
			});
        }
	};

	_producenti_produkty = (oblast, id_producent) => {
        let cachedResponse = this.mainCache.produkty(oblast, id_producent);
        if (cachedResponse) {
            return new Promise((resolve, reject) => {
                resolve(cachedResponse);
            });
        } else {
            return get("/producenti/produkty?oblast=" + oblast + "&id_producent=" + id_producent, true, null, false, true).then((r) => {
                this.mainCache.produkty(oblast, id_producent, r);
                return r;
            });
        }
	};

	getProducentiSelectOptions = (oblast) => {
		return new Promise((resolve, reject) => {
			this._producenti_index(oblast).then((response) => {
				if (response && response.data) {
					let data = [];
					data = response.data.data.map((item) => {
						return {
							produkty: item.produkty,
							label: item.nazev,
							value: item.nazev,
							id: item.id
						}
					});
					resolve(data);
				}
			}).catch(() => {
				reject();
			});
		});
	};

	_portfolio_grafy = () => {
		return get("/portfolio/grafy");
	};

	getGrafyData = () => {
		var _self = this;
		var promise1 = new Promise(function (resolve, reject) {
			_self._portfolio_grafy().then((resp) => {
				if (resp && resp.data && resp.data.data) {
					var finalDataOblasti = [];
					finalDataOblasti = _self.formatGrafyDataToGoogleChartData(resp.data.data['oblasti']);
					var finalDataSmlouvy = [];
					finalDataSmlouvy = _self.formatGrafyDataToGoogleChartData(resp.data.data['smlouvy']);
					var grafyPovoleny = resp.data.data.grafyPovoleny;
					resolve({
						smlouvy: {
							raw: resp.data.data['smlouvy'],
							data: finalDataSmlouvy,
						},
						smlouvyColors: _self.getGoogleChartsColors(resp.data.data['smlouvy']),
						oblasti: {
							raw: resp.data.data['oblasti'],
							data: finalDataOblasti,
						},
						oblastiColors: _self.getGoogleChartsColors(resp.data.data['oblasti']),
						grafyPovoleny: grafyPovoleny
					});
				}
			});
		});
		return promise1;
	};

	formatGrafyDataToGoogleChartData = (data) => {
		let dataFinal = [
			['Oblast', 'Kč', {role: 'tooltip'}]
		];

		if (data && data.item && data.item.plus && data.item.plus.hodnota) {
			let valuesPlus = data.item.plus.hodnota;
			let namesPlus = data.item.plus.nazev;
			let percPlus = data.item.plus.proc;

			for (let key in valuesPlus) {
				if (valuesPlus.hasOwnProperty(key) && valuesPlus[key] !== 0) {
					let valueInCzk = valuesPlus[key].toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " Kč";
					dataFinal.push([
						namesPlus[key], valuesPlus[key], namesPlus[key] + "\n" + valueInCzk + " (" + percPlus[key].toFixed(1) + "%)"
					]);
				}
			}
		}

		if (data && data.item && data.item.minus && data.item.minus.hodnota) {
			let valuesMinus = data.item.minus.hodnota;
			let namesMinus = data.item.minus.nazev;
			let percMinus = data.item.minus.proc;

			for (let key in valuesMinus) {
				if (valuesMinus.hasOwnProperty(key) && valuesMinus[key] !== 0) {
					let valueInCzk = valuesMinus[key].toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " Kč";
					dataFinal.push([
						namesMinus[key], valuesMinus[key], namesMinus[key] + "\n" + valueInCzk + " (" + percMinus[key].toFixed(1) + "%)"
					]);
				}
			}
		}
		return dataFinal;
	};

	getGoogleChartsColors = (data) => {
		let colors = [];

		if (data && data.item && data.item.plus && data.item.plus.hodnota) {
			let valuesPlus = data.item.plus.hodnota;
			let colorsPlus = data.item.plus.barva;

			for (let key in valuesPlus) {
				if (valuesPlus.hasOwnProperty(key) && valuesPlus[key] !== 0) {
					colors.push(colorsPlus[key]);
				}
			}
		}

		if (data && data.item && data.item.minus && data.item.minus.hodnota) {
			let valuesMinus = data.item.minus.hodnota;
			let colorsMinus = data.item.minus.barva;

			for (let key in valuesMinus) {
				if (valuesMinus.hasOwnProperty(key) && valuesMinus[key] !== 0) {
					colors.push(colorsMinus[key]);
				}
			}
		}

		return colors;
	};


	editovatDokumentKeSmlouve = (id, data) => {
		return put("/dokumenty/index?id=" + id, data);
	};

  getContractData = (token) => {
    return get('/notificator/getContractData?token=' + token);
  };

  getGdprData = (token) => {
    return get('/gdpr/getGdprData?token=' + token);
  };

  validateGdprToken = (token) => {
    return get("/gdpr/checkGdprToken?token=" + token).then((res) => {
      if (res) {
        return res.data.data.valid;
      }
    });
  };

	validateToken = (token) => {
		return get("/token/checkZaznamZJednaniToken?token=" + token).then((res) => {
			if (res) {
				return res.data.data.valid;
			}
		});
	};

	sjednaniPdf = (cisloSmlouvy, token) => {
    return get("/notificator/sjednaniPdf?smlouva=" + cisloSmlouvy + '&token=' + (token ? token : ''), true,{responseType: 'blob'}).then((res) => {
      if (res) {
        return res.data;
      }
    });
  }

	canBeSigned = (token) => {
		return get("/notificator/canSignZaznamZJednani?token=" + token).then((res) => {
			if (res) {
				return res.data.data;
			}
		});
	};

	sendSms = (token) => {
		return put("/notificator/sendZaznamZJednaniSms?token=" + token, {}).then((res) => {
			if (res) {
				if (res.data.data.status === null) {
					return "201";
				}
				return res.data.data.status;
			}
		});
	};

	verifySms = (smsToken, token) => {
		return put("/notificator/verifyZaznamZJednaniSms?smsToken=" + smsToken + "&token=" + token, {}).then((res) => {
			if (res) {
				return res.data.data.status;
			}
		});
	};

	uploadDokumentyFiles = (files: DropzoneFile[], hideSpinner = false) => {
		let fdata = new FormData();

		files.forEach((item, i) => {
			let file = files[i];
			fdata.append('files[' + i + ']', file.getFile(), file.getName());
			fdata.append('file_custom_names[' + i + ']', file.getCustomName());
			fdata.append('type', file.getMimeType());
		});

		const config = {
			onUploadProgress: function (progressEvent) {
				let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
				PubSub.publish('uploadSmlouvyFilesProgress', {
					data: {
						files,
					},
					target: this,
					percentCompleted: percentCompleted,
					originalEvent: progressEvent
				});
				if (percentCompleted === 100) {
					PubSub.publish('refreshAllDocuments');
				}
			},
			headers: {'content-type': 'multipart/form-data'}
		};

		return post("/dokumenty/upload", fdata, config, hideSpinner).then(
			response => {
				return response;
			}
		);
	};

	buildURL = (path: String, params: ?Object) => {
		let p = [];
		if (params) {
			for (let paramsKey in params) {
				if (params.hasOwnProperty(paramsKey)) {
					if (params[paramsKey] !== undefined) {
						p.push(encodeURIComponent(paramsKey) + "=" + encodeURIComponent(params[paramsKey]));
					}
				}
			}
		}
		if (path.endsWith('/')) {
			throw new Error('url path must not end with slash');
		}
		if (path.endsWith('?')) {
			throw new Error('url path must not end with question mark');
		}
        if (p.length > 0) {
            return path + '?' + p.join('&');
        }
        return path;
	};

	dummy = (payload = undefined) => {
		return new Promise((resolve, reject) => {
			resolve(payload);
		})
	};

	getExceptionMessageFromResponse = (response, exceptionClass: string): ?string => {
		if (response) {
			if (response.data) {
				if (response.data.exception) {
					if (response.data.exception.instanceOf.indexOf(exceptionClass) !== -1) {
						return response.data.exception.message;
					}
				}
			}
		}
		if (response.response !== undefined) {
			return this.getExceptionMessageFromResponse(response.response, exceptionClass);
		}
		return null;
	};

	getMplanConfiguration = (handleError = true) => {
		return new Promise((resolve, reject) => {
			get("/nastaveni/MplanConfiguration", handleError).then((response) => {
				if (response && response.data && response.data.data) {

					if (response.data.data.maxFileUploadSize) {
            sessionStorage.setItem("max_file_upload_size", response.data.data.maxFileUploadSize);
					}

					if (response.data.data.allowedMimetypes) {
            sessionStorage.setItem("allowed_mimetypes", response.data.data.allowedMimetypes);
					}

					if (response.data.data.allowedImageMimetypes) {
            sessionStorage.setItem("allowed_image_mimetypes", response.data.data.allowedImageMimetypes);
					}

					if (response.data.data.allowNakupMajetku !== undefined) {
            sessionStorage.setItem("allow_nakup_majetku", response.data.data.allowNakupMajetku);
					}

					if (response.data.data.propertyMaterials) {
            sessionStorage.setItem("propertyMaterials", JSON.stringify(response.data.data.propertyMaterials));
					}

					if (response.data.data.propertyStatus) {
            sessionStorage.setItem("propertyStatus", JSON.stringify(response.data.data.propertyStatus));
					}

					if (response.data.data.propertyTypes) {
            sessionStorage.setItem("propertyTypes", JSON.stringify(response.data.data.propertyTypes));
					}

					if (response.data.data.propertyOwnership) {
            sessionStorage.setItem("propertyOwnership", JSON.stringify(response.data.data.propertyOwnership));
					}

					if (response.data.data.propertyParameters) {
            sessionStorage.setItem("propertyParameters", JSON.stringify(response.data.data.propertyParameters));
					}

					if (response.data.data.propertyRegion) {
            sessionStorage.setItem("propertyRegion", JSON.stringify(response.data.data.propertyRegion));
					}

					if (response.data.data.propertyDisposition) {
            sessionStorage.setItem("propertyDisposition", JSON.stringify(response.data.data.propertyDisposition));
					}

					resolve(response.data.data);
				} else {
					reject(response);
				}
			});
		});
	};

    getPortfolioSmlouvy = (perPage, small, onlyServices, withoutServices) => {
    	return get(this.buildURL("/portfolio/smlouvy", {
        	perPage: perPage ? perPage : undefined,
            orderByDatum: small ? 'desc' : undefined,
            "filter[typ]": onlyServices ? 'sluzba' : undefined,
        	"omitFilter[typ]": withoutServices ? 'sluzba' : undefined,
		}));
    };

  /**
   * Vrati seznam aktivnich leadovacu pro aktualniho klienta
   *
   * @returns {Promise<*>}
   */
  getLeadovace = () => {
    return get("/leadovac/index").then((res) => {
      return res.data;
    });
  };


	/**
	 * Vrati specifickou url leadovace pro iframe
	 *
	 * @param type
	 * @returns {Promise<*>}
	 */
	getLeadovacUrl = (type) => {
		return get("/leadovac/url?type=" + type).then((res) => {
			return res.data;
		});
	};


}

export default MyplannClient;
