import {Component} from "react";
import ReactDOM from 'react-dom';
import DropzoneFile from "../DropzoneFile";
import * as PubSub from "pubsub-js";
import {getContainer} from "../../../utils/generic";

class BaseDokumentyForm extends Component {

	constructor(props) {
		super(props);
		this.files = [];
		this._uzivatel = getContainer().getUzivatel();
		this._client = getContainer().getMyplannClient();
	}

	UNSAFE_componentWillMount = function () {
		this._clearFiles = PubSub.subscribe('clearBaseDokumentyForm', this.clear.bind(this));
		this._scrollToComponent = PubSub.subscribe('scrollToDokumentyForm', this.scrollToComponent.bind(this));
	};

	componentWillUnmount = function () {
		PubSub.unsubscribe(this._clearFiles);
		PubSub.unsubscribe(this._scrollToComponent);
	};

	/**
	 * @returns {MyplannClient}
	 */
	getMyplannClient = () => {
		return this._client;
	};

	// submits the form data
	// supposes that onSubmit has been passed as prop
	submit = (e) => {
		e.preventDefault();
		let data = this.state;
		this.props.onSubmit(data, this.files);
	};

	// this is two-way binding - see comments at input
	// and read https://reactjs.org/docs/two-way-binding-helpers.html
	// (link shows that this can also be done in a different way)
	onChange = e => {
		this.setState({[e.target.name]: e.target.value});
	};

	onFieldChange = (name, value) => {
		this.setState({[name]: value});
	};

	onDrop = (files: DropzoneFile[]) => {
		this.files = files;
	};

	clear = (msg, data) => {
		this.files = [];
		PubSub.publish('reloadDokumentyDropzone');
	};

	scrollToComponent = () => {
		const element = ReactDOM.findDOMNode(this);
		element.scrollIntoView({
			block: 'end',
			behavior: 'smooth'
		});
	};
}

export default BaseDokumentyForm;
