import React, {Component} from "react";
import {Redirect} from "react-router-dom";
import PropTypes from "prop-types";

import {getContainer} from "../../utils/generic";
import {changePassword, forcedChangePassword, login} from "../../utils/Api";

class ZmenaHeslaForm extends Component {
	constructor(props) {
		super(props);
		this._client = getContainer().getMyplannClient();
		this.state = {
			passwordChanged: false,
			heslo: "",
			hesloZnovu: "",
			aktualniHeslo: "",
			message: ""
		};
	}

	onChange = (e) => {
		this.setState({[e.target.name]: e.target.value});
	};

	charAtIsUpper = function (string, atpos){
		let chr = string.charAt(atpos);
		return /[A-Z]|[\u0080-\u024F]/.test(chr) && chr === chr.toUpperCase();
	};

	charAtIsLower = function (string, atpos){
		let chr = string.charAt(atpos);
		return /[a-z]/.test(chr) && chr === chr.toLowerCase();
	};

	charAtIsNumber = function (string, atpos){
		let chr = string.charAt(atpos);
		return /[0-9]/.test(chr) && !isNaN((chr *1));
	};

	getStringLowerCaseCount = (str) => {
		let letters = [];
		for (let i = 0; i < str.length; i++) {
				if (this.charAtIsLower(str, i)) {
					letters.push(str.charAt(i));
				}
		}
		return letters.length;
	};

	getStringUpperCaseCount = (str) => {
		let letters = [];
		for (let i = 0; i < str.length; i++) {
				if (this.charAtIsUpper(str, i)) {
					letters.push(str.charAt(i));
				}
		}
		return letters.length;
	};

	getStringNumberCount = (str) => {
		let letters = [];
		for (let i = 0; i < str.length; i++) {
			if (this.charAtIsNumber(str, i)) {
					letters.push(str.charAt(i));
			}
		}
		return letters.length;
	};

	isPasswordValid = (password : string = null, data = {}) => {
		data.errors = [];
		if (password !== null && password.length >= 8) {
			if (this.getStringLowerCaseCount(password) <= 0) {
					data.errors.push('Heslo musí obsahovat alespoň 1 malé písmeno.');
			}
			if (this.getStringUpperCaseCount(password) <= 0) {
					data.errors.push('Heslo musí obsahovat alespoň 1 velké písmeno.');
			}
			if (this.getStringNumberCount(password) <= 0) {
					data.errors.push('Heslo musí obsahovat alespoň 1 číslo.');
			}
		} else {
			data.errors.push('Heslo musí mít alespoň 8 znaků.');
		}
		return data.errors.length <= 0;
	};

	onSubmit = (e) => {
		e.preventDefault();

		let data = {
			errors: []
		};
		let isPasswordValid = this.isPasswordValid(this.state.heslo, data);

		if (isPasswordValid) {
			if (this.state.heslo === this.state.hesloZnovu) {
				const _changePassword = this.props.forcedChange ?
					forcedChangePassword :
					changePassword;
				const params = this.props.forcedChange ?
					[this.state.heslo, this.state.hesloZnovu] :
					[this.state.aktualniHeslo, this.state.heslo];
				_changePassword(...params).then((res) => {
					if (res) {
						this.setPasswordChanged();
					}
				}).catch((e) => {
					console.error(e);
					this.setServerError(e);
				});

			} else {
				this.setState({
					hasError: true,
					message: "Hesla se musí shodovat."
				});
			}
		} else {
			if (data.errors.length > 0) {
				this.setState({
					hasError: true,
					message: data.errors.join('|')
				});
			} else {
				this.setState({
					hasError: true,
					message: "Heslo je příliš krátké nebo nemá správný formát."
				});
			}
		}
	};

	setPasswordChanged = () => {
	  // heslo zmeneno - prihlasim a presmeruji na homepage
	  let username = getContainer().getUzivatel().userName();
	  let password = this.state.heslo;
	  login(username,password).then(() => {
      window.location.href = '/';
    });

	  //puvodni pristup
		// this.setState({
		// 	passwordChanged: true,
		// 	aktualniHeslo: "",
		// 	heslo: "",
		// 	hesloZnovu: "",
		// 	hasError: false,
		// 	message: "Heslo změněno"
		// });
	};

	setServerError = (e) => {
		if (e.response) {
			if (this._client.getExceptionMessageFromResponse(e.response, 'Myplann\\Exception\\PasswordNotMatchAuthException')) {
				this.setState({
					hasError: true,
					message: "Nepodařilo se změnit Vaše heslo. Původní heslo není správné."
				});
			} else {
				this.setState({
					hasError: true,
					message: 'Nepodařilo se změnit Vaše heslo. Kontaktujte Vašeho poradce.'
				});
			}
		} else {
			this.setState({
				hasError: true,
				message: 'Nepodařilo se změnit Vaše heslo. Kontaktujte Vašeho poradce.'
			});
		}
	};

	render = () => {
		if (this.props.forcedChange && this.state.passwordChanged) {
			return (<Redirect to={"/"} />);
		}
		return (
			<div className="change-password__form__container">
				<h2 className={"padding--bottom--5"}>Změna hesla</h2>
				<form onSubmit={this.onSubmit}>

					<Message className={this.state.hasError ? 'error' : 'success'} message={this.state.message} />

					{!this.props.forcedChange ?
						<React.Fragment>
						<div className={'row padding--top--5'}>
							<div className={'col-sm-12'}>
								<label htmlFor="">Zadejte původní heslo:</label>
							</div>
						</div>
						<div className={'row padding--bottom--10'}>
							<div className={'col-md-7 col-lg-4'}>
								<input onChange={this.onChange} type="password" name={'aktualniHeslo'} value={this.state.aktualniHeslo}/>
							</div>
						</div>
						</React.Fragment>
						:
						null
					}
					<div className={'row padding--bottom--5 padding--top--5'}>
						<div className={'col-sm-12'}>
							<label htmlFor="">Zadejte heslo dlouhé minimálně 8 znaků složené z malých a velkých písmen a čísel.</label>
						</div>
					</div>
					<div className={'row padding--top--10'}>
						<div className={'col-sm-12'}>
							<label htmlFor="">Zadejte nové heslo:</label>
						</div>
					</div>
					<div className={'row padding--bottom--5'}>
						<div className={'col-md-7 col-lg-4'}>
							<input onChange={this.onChange} type="password" name={'heslo'} value={this.state.heslo}/>
						</div>
					</div>
					<div className={'row'}>
						<div className={'col-sm-12'}>
							<label htmlFor="">Znovu:</label>
						</div>
					</div>
					<div className={'row'}>
						<div className={'col-md-7 col-lg-4'}>
							<input onChange={this.onChange} type="password" name={'hesloZnovu'} value={this.state.hesloZnovu}/>
						</div>
					</div>
					<div className={'row'}>
						<div className={'col-sm-12'}>
							<input type="submit" name={"save"} className={'btn'} value={'uložit'}/>
						</div>
					</div>
				</form>
			</div>
		);
	};
}

const Message = ({ className, message }) => (
	<div className={'row'}>
		<div className={'col-12'}>
		{message.split('|').map((text) => (
			<div><label htmlFor="" className={className}>{text}</label></div>
		))}
		</div>
	</div>
);

ZmenaHeslaForm.defaultProps = {
	forcedChange: false
};

ZmenaHeslaForm.propTypes = {
	forcedChange: PropTypes.bool
};

export default ZmenaHeslaForm;
