import React from "react";
import Modal from "react-modal";
import ModalClose from "./ModalClose";

Modal.setAppElement('#root');

/*
    Component for modal window

    props:
        - all from react-modal documentation (https://github.com/reactjs/react-modal)

        isOpen: true for open modal
        onRequestClose: action for close of modal window (ESC; click on overlay; click on close button etc.)
        modalClassName: class for modal window
        closeBtn: show close button; by defaul is true

 */
export default ({...props}) => {
	const customStyles = {
		content: {
			border: '0px',
			borderRadius: '0px',
			top: '40px',
			left: '40px',
			right: '40px',
			bottom: null,
			marginBottom: '40px',
			overflow: 'visible',
		},
		overlay: {
			backgroundColor: 'rgba(238, 238, 238, 0.85)'
		}
	};

	return (
		<Modal
			style={customStyles}
			closeTimeoutMS={300}
			{...props}
		>
			{props.onRequestClose !== undefined && (props.closeBtn === undefined || props.closeBtn) ?
				<ModalClose onClick={props.onRequestClose}/>
				: ""
			}

			{props.children}
		</Modal>
	);
};
