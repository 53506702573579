import React, {Component} from "react";
import {getContainer} from "../../utils/generic";
import ForceUpdateScrollbars from "../ForceUpdateScrollbars/ForceUpdateScrollbars";
import GdprText from "./GdprText";


export default class Blue extends Component {

  constructor(props) {
    super(props);
    this._client = getContainer().getMyplannClient();
    this._myplannClient = getContainer().getMyplannClient();
    this.state = {
      token: props.token,
      processDone: false
    };
  }



  processErrorMessage = (message) => {
    if (message === 'phone number is invalid') {
      return 'Zadané telefonní číslo není platné';
    } else if (message === 'invalid sms code') {
      return 'Zadaný SMS kód není platný';
    } else if (message === 'can not send sms') {
      return 'SMS nelze odeslat, jelikož od odeslání předchozí SMS neuběhlo alespoň půl minuty.';
    } else if (message === 'can not verify sms token') {
      return 'Potvrzení pomocí SMS nyní nelze dokončit. Obraťte se prosím na svého poradce.';
    } else if (message === 'bad number') {
      return 'Zadané telefonní číslo se neshoduje s tím, které poradce uvedl při Vaší identifikaci. Kontaktujte prosím svého poradce.';
    } else {
      return 'Při zpracování vašeho podpisu došlo k chybě, zkuste prosím stránku obnovit a podpis opakovat.';
    }
  };

  souhlasUdelen = () => {

      this._myplannClient.souhlas_modryPotvrzen(this.state.token).then((response) => {
        this.setState({processDone: true});
        return;
      }).catch((data) => {
        if (data.response) {
          if (data.response.data) {
            if (data.response.data.exception) {
              this.setState({
                hasError: true,
                message: this.processErrorMessage(data.response.data.exception.message)
              });
              this.render();
              return;
            }
          }
        }
        this.setState({
          hasError: true,
          message: 'Došlo k chybě během zpracovávání požadavku.'
        });
        this.render();
      });

  };

  render = () => {
    let self = this;

    if (this.state.processDone) {
      return (
        <ForceUpdateScrollbars
          className=""
          autoHeight={true}
          autoHeightMin={300}
          autoHeightMax={'calc(100vh - 150px)'}
          forceupdaterendertimer={1000}
          identifier={'prvniprihlaseni-scrollbar'}
        >
          <div style={{padding: '20px'}} className="scroll-wrapper">
            <h1>Dokončeno</h1>
            <div className={'p-padding'}>
              Děkujeme, souhlas byl úspěšně potvrzen.
            </div>
          </div>
        </ForceUpdateScrollbars>
      );
    }

    var deti = null;
    var hardError = false;

    return (
      <ForceUpdateScrollbars
        className=""
        autoHeight={true}
        autoHeightMin={300}
        autoHeightMax={'calc(100vh - 150px)'}
        forceupdaterendertimer={1000}
        identifier={'prvniprihlaseni-scrollbar'}
      >
        <div style={{padding: '20px'}} className="scroll-wrapper">
          <h1>Informace a souhlas se zpracováním osobních údajů</h1>
          <GdprText email={this.props.email} />


          <div className="validation__form__btn">
            <input type="submit" onClick={this.souhlasUdelen} className={'btn pointer text-center'}
                   value={'Potvrdit přečtení'}/>
          </div>

        </div>
      </ForceUpdateScrollbars>
    );
  }
}
