// this component wraps the process of adding a new
// portfolio item into one block

import React, {Component} from "react";
import NakupMajetkuFormWrapper from "./NakupMajetkuFormWrapper";
import * as PubSub from "pubsub-js";
import alertDialog from "../../../utils/alertDialog";
import confirmDialog from "../../../utils/confirmDialog";
import {getContainer} from "../../../utils/generic";
import druhyPoptavekPoMajetku, {getDruhyPoptavekPoMajetkuIDFromName} from "../../../data/druhyPoptavekPoMajetku";
import Modal from "../../utils/Modal/Modal";

export default class NakupAssetWizard extends Component {
	constructor(props) {
		super(props);
		this._ismounted = false;
		this.state = {
			type: props.asset ? props.asset.type : null,
			id: props.asset ? props.asset.id : null,
			formKey: (new Date()).valueOf(),
			message: null,
			confirmClose: false,
			propertyMaterials: JSON.parse(sessionStorage.getItem('propertyMaterials')),
			propertyStatus: JSON.parse(sessionStorage.getItem('propertyStatus')),
			propertyTypes: JSON.parse(sessionStorage.getItem('propertyTypes')),
			propertyOwnership: JSON.parse(sessionStorage.getItem('propertyOwnership')),
			propertyParameters: JSON.parse(sessionStorage.getItem('propertyParameters')),
			propertyRegion: JSON.parse(sessionStorage.getItem('propertyRegion')),
			propertyDisposition: JSON.parse(sessionStorage.getItem('propertyDisposition')),
		};
		this._dateHelper = getContainer().getDateHelper();
		this._myplannClient = getContainer().getMyplannClient();
	}

	componentDidMount() {
		this._ismounted = true;

		this.loadFormConfiguration();

		if (this.state.propertyMaterials === null || this.state.propertyStatus === null  ||
			this.state.propertyTypes === null  || this.state.propertyOwnership === null  ||
			this.state.propertyParameters === null  || this.state.propertyRegion === null  ||
			this.state.propertyDisposition === null) {
			this._myplannClient.getMplanConfiguration().then(
				response => {
					this.loadFormConfiguration();
				}
			);
		}
	}

	loadFormConfiguration = () => {
		try {
			const propertyMaterials = JSON.parse(sessionStorage.getItem('propertyMaterials'));
			const propertyStatus = JSON.parse(sessionStorage.getItem('propertyStatus'));
			const propertyTypes = JSON.parse(sessionStorage.getItem('propertyTypes'));
			const propertyOwnership = JSON.parse(sessionStorage.getItem('propertyOwnership'));
			const propertyParameters = JSON.parse(sessionStorage.getItem('propertyParameters'));
			const propertyRegion = JSON.parse(sessionStorage.getItem('propertyRegion'));
			const propertyDisposition = JSON.parse(sessionStorage.getItem('propertyDisposition'));

			this.setState({
				propertyMaterials,
				propertyStatus,
				propertyTypes,
				propertyOwnership,
				propertyParameters,
				propertyRegion,
				propertyDisposition
			});

		} catch (e) {
			console.log(e);
		}
	};

	componentWillUnmount() {
		this._ismounted = false;
	}

	// we need this for the same reasons described
	// in Appartment.js
	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.asset && nextProps.asset.id !== null) {
			this.setState({
				type: nextProps.asset.type,
				id: nextProps.asset.id,
				formKey: (new Date()).valueOf(),
				message: null
			});
		} else {
			setTimeout(() => {
				if (this._ismounted) {
					this.setState({
						id: null,
						type: null,
						formKey: (new Date()).valueOf(),
						message: null,
						confirmClose: false,
					});
				}
			}, 300);
		}
	}

	reset = () => {
		this.setState({id: null, type: null});
	};

	/**
	 * @return {MyplannClient}
	 */
	getMyplannClient = () => {
		return this._myplannClient;
	};

	assetWizardDelete = (name, data) => {
	};

	handleError = (response) => {
		let missingRealEstateAgenciesMsg = this.getMyplannClient().getExceptionMessageFromResponse(response, 'Myplann\\Exception\\MissingRealEstateAgencies');
		if (missingRealEstateAgenciesMsg !== null) {
			alertDialog("Došlo k chybě", "Váš poradce nemá přiřazenou realitní kancelář a proto nelze s poptávkami po majetku pracovat. Obraťte se prosím na svého poradce.");
		} else {
			alertDialog("Došlo k chybě", "Omlouváme se, ale poptávku po majetku se nepodařilo upravit. Obraťte se prosím na svého poradce.");
		}
	};

	assetWizardSubmit = data => {

		if (data.id) {
			this.getMyplannClient().updateNakupMajetku(data.id, data.data).then(
				response => {
					if (response) {
						PubSub.publish('onNakupMajetkuAktualizovan', {data: data});

						if (this.props.resetAfterSubmit) {
							this.reset();
						}

						this.props.onSubmit(data);
					}
				},
				response => {
					this.handleError(response);
				}
			);
		} else {
			this.getMyplannClient().createNakupMajetku(data.type, data.data).then(
				response => {
					if (response) {
						PubSub.publish('onNakupMajetkuPridan', {data: data});
						this.setState({
							message: "Nová poptávka po majetku byla vytvořena."
						});

						if (this.props.resetAfterSubmit) {
							this.reset();
						}

						this.props.onSubmit(data);
					}
					return response;
				}
			).catch((response) => {
				this.handleError(response);
				throw response;
			});
		}
	};

	selectAssetType = type => {
		this.setState({type, message: null});
	};

	subformSubmit = data => {
		let d = {
			type: this.state.type,
			id: this.state.id,
			data
		};
		this._dateHelper.reformatDates(d.data, true);
		this.assetWizardSubmit(d);

		this.setState({
			confirmClose: false,
		});
	};

	zrusitNakup = (id_nakupu) => {
		confirmDialog("Zrušení nákupu", "Opravdu chcete zrušit nákup tohoto majetku?", "Zrušit nákup").then(
			result => {
				this.getMyplannClient().majetekNakupZrusit(id_nakupu).then((response) => {
					if (response && response.data.data.success) {
						this.props.onZrusit(id_nakupu);
					}
				});
			},
			result => {
				// `cancel` callback
			}
		);
	};

	closeModal = () => {
		if (this.state.confirmClose) {
			confirmDialog("Neuložené změny", "Opravdu si přejete zavřít okno bez uložení změn?", "zavřít okno").then(
				result => {
					this.setState({
						confirmClose: false,
					});

					this.props.closeModal();
				},
				result => {
				}
			);
		} else {
			this.props.closeModal();
		}
	};

	onFormChange = () => {
		if (!this.state.confirmClose) {
			this.setState({
				confirmClose: true,
			})
		}
	};

	renderList = () => {
		return (
			<div>
				<h1>Koupě nemovitostí</h1>
				{this.state.message !== null ?
					<div className={"padding--bottom--20 padding--top--10"}>
						<p className={"success"}>{this.state.message}</p>
					</div>
					: ""
				}
				<ul className="links-center">
					{druhyPoptavekPoMajetku.map(i => (
						<li key={i.id}>
							<span className="links-center-a" onClick={() => this.selectAssetType(i.id)}>
								{i.name}
								{i.desc !== undefined ?
									<p>{i.desc}</p>
									:
									''
								}
							</span>
						</li>
					))}
				</ul>
			</div>
		);
	};

	render() {
		let type = getDruhyPoptavekPoMajetkuIDFromName(this.state.type);
		const Form = type !== null && druhyPoptavekPoMajetku[type].form;

		return (
			<Modal
				isOpen={this.props.modalIsOpen}
				onRequestClose={this.closeModal}
			>
				{Form ?
					<NakupMajetkuFormWrapper Specific={Form}
											 onSubmit={this.subformSubmit.bind(this)}
											 asset={this.props.asset}
											 key={this.state.formKey}
											 zrusitNakup={this.zrusitNakup.bind(this)}
											 onFormChange={this.onFormChange.bind(this)}
											 formConfiguration={{
											 	propertyMaterials: this.state.propertyMaterials,
											 	propertyStatus: this.state.propertyStatus,
											 	propertyTypes: this.state.propertyTypes,
											 	propertyOwnership: this.state.propertyOwnership,
											 	propertyParameters: this.state.propertyParameters,
											 	propertyRegion: this.state.propertyRegion,
											 	propertyDisposition: this.state.propertyDisposition,
											 }}
					/>
					: this.renderList()}
			</Modal>
		);
	}
}
