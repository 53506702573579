import React, {Component} from "react";
import * as PubSub from "pubsub-js";
import {getContainer} from "../../../utils/generic";
import Modal from "../../utils/Modal/Modal";
import propertyAssetsTypes, {getPropertyAssetTypeIDFromName} from "../../../data/propertyAssetsTypes";

export default class ProdejMajetkuGrid extends Component {

	constructor(props) {
		super(props);
		this._myplannClient = getContainer().getMyplannClient();
		this._dateHelper = getContainer().getDateHelper();
		this._uzivatel = getContainer().getUzivatel();
		this._client = getContainer().getMyplannClient();
		this.state = {
			activeItem: null,
			majetkyKProdeji: [],
			msg: null,
			success: null,
			showNames: false,
		};
	}

	/**
	 * @return MyplannClient
	 */
	getMyplannClient = () => {
		return this._myplannClient;
	};

	UNSAFE_componentWillMount = function () {
		this._onMajetekSmazan = PubSub.subscribe('onMajetekSmazan', this.reload.bind(this));
		this._onMajetekPridan = PubSub.subscribe('onMajetekPridan', this.reload.bind(this));
		this._onMajetekPronajat = PubSub.subscribe('onMajetekPronajat', this.reload.bind(this));
	};

	componentWillUnmount = function () {
		PubSub.unsubscribe(this._onMajetekSmazan);
		PubSub.unsubscribe(this._onMajetekPridan);
		PubSub.unsubscribe(this._onMajetekPronajat);
	};

	componentDidMount() {
		this.reload();
	}

	reload = () => {
		this.getMyplannClient().majetekKProdeji().then(response => {
			if (response) {

				let showNames = false;
				let name = null;

				if (response.data && response.data.data && response.data.data.db) {
					response.data.data.db.forEach((result) => {
						this._dateHelper.reformatDates(result);
						this._dateHelper.reformatDates(result.formData);

						if (name !== null && result.jmeno !== name) {
							showNames = true;
						}

						name = result.jmeno;
					});
				}

				this.setState({majetkyKProdeji: response.data.data.db, showNames});
			}
		});
	};

	UNSAFE_componentWillReceiveProps(nextProps) {
		// this.setState({
		//     title: nextProps.title,
		//     data: nextProps.data,
		//     id: nextProps.id
		// });
	}

	confirmMajetek = (e, index) => {
		if (!this._uzivatel.readAccessOnly()) {
			let m = this.state.majetkyKProdeji[index].formData;

			if (m && m.id) {
				this._client.__dokumenty_index(undefined, undefined, m.id).then((result) => {
					if (result) {
						m.documents = result.data.data.db;

						this.setState({
							activeItem: m,
							msg: null,
							success: null
						});
					}
				});
			} else {
				this.setState({
					activeItem: m,
					msg: null,
					success: null
				});
			}
		}
	};

	confirmMajetekFail = (e) => {
		this.setState({
			activeItem: null
		});
	};

	confirmMajetekSuccess = (e) => {
		this.prodejMajetek(this.state.activeItem).then(r => {
		});
	};

	prodejMajetek = (majetekKProdeji) => {
		return this.getMyplannClient().prodejMajetek(majetekKProdeji.id).then(response => {
			if (response) {
				if (response.data.data.success) {
					this.reload();

					this.setState({
						activeItem: null,
						success: "Nový prodej majetku byl vytvořen."
					});

					PubSub.publish('onMajetekProdan', {data: this.state.activeItem});
				}
			}
			return response;
		}).catch(e => {
			this.onReject(e);
		});
	};

	onReject = (error) => {
		if (error.response && error.response.data && error.response.data.exception) {
			this.setState({
				msg: error.response.data.exception.message,
				activeItem: null,
			});
		}
		return undefined;
	};

	render = () => {

		let type = null;
		if (this.state.activeItem && this.state.activeItem.druh) {
			type = getPropertyAssetTypeIDFromName(this.state.activeItem.druh);
		}
		const Form = type !== null && propertyAssetsTypes[type].form;

		return (
			<div className={'ProdejMajetkuGrid'}>
				<h1 className={"padding--right--25"}>Prodej nemovitostí</h1>
				<p className={"padding--bottom--20"}>Kliknutím na daný záznam vyberte majetek k prodeji.</p>

				{this.state.majetkyKProdeji === undefined || this.state.majetkyKProdeji.length <= 0 ?
					<p className={"error"}>
						Nemáte žádný majetek vhodný k prodeji.
					</p> : ""}

				{this.state.msg !== null ?
					<p className={"error padding--bottom--10 text-weight--400"}>Došlo k chybě a prodej majetku se
						nepodařilo dokončit. Chyba: {this.state.msg}</p>
					:
					''
				}

				{this.state.success !== null ?
					<div className={"padding--bottom--20 padding--top--10"}>
						<p className={"success"}>{this.state.success}</p>
					</div>
					: ""
				}

				<div
					className="responsive-table responsive-table--sm responsive-table--first-icon responsive-table--second-next-first">
					{this.state.majetkyKProdeji.map((item, index) => {
						return (

							<div
								className={"tr table-row pointer" + (this._uzivatel.readAccessOnly() ? " cursor-not-allowed" : "")}
								key={index} onClick={(e) => {
								this.confirmMajetek(e, index);
							}}>
								<div className="td td--width--30">
									<div className={`icon oblm`}>M</div>
								</div>
								<div className={"td text-nowrap"}>
									{item.druh_nazev}
								</div>
								<div className={"td text-ellipsis property-table__address"}>
									{item.lokalita}
								</div>
								<div className={"td text-nowrap"}>
									{item.datum_koupeno}
								</div>

								{this.state.showNames === true ?
									<div className="td text-nowrap--big-screen">
										{item.jmeno}
									</div>
									: ""}
							</div>
						);
					})}
				</div>

				<Modal
					onRequestClose={this.confirmMajetekFail.bind(this)}
					isOpen={!!this.state.activeItem}
				>
					{this.state.activeItem ?
						<div>
							<h1>Chcete opravdu prodat tento majetek?</h1>
							{Form ? <Form onSubmit={(item) => {
								}}
										  onFormChange={(item) => {
										  }}
										  asset={{
											  disabled: true,
											  id: this.state.activeItem.id,
											  type: this.state.activeItem.druh,
											  data: this.state.activeItem,
											  documents: this.state.activeItem.documents
										  }}/>
								:
								""
							}

							<div className={"modal-form-table__buttons"}>
								<span className={'btn btn--gray padding--right--10 mr-10'}
								   onClick={this.confirmMajetekFail}>Zpět</span>
								<span className={'btn'} onClick={this.confirmMajetekSuccess}>Prodat</span>
							</div>
						</div>
						:
						''
					}
				</Modal>
			</div>
		);
	};
}
