import React, {Component} from "react";
import {getContainer} from "../../utils/generic";
import ForceUpdateScrollbars from "../ForceUpdateScrollbars/ForceUpdateScrollbars";
import GdprText from "./GdprText";


export default class Gold extends Component {

  constructor(props) {
    super(props);
    this._client = getContainer().getMyplannClient();
    this._myplannClient = getContainer().getMyplannClient();
    let deti = [];
    if(props.data.child !== null && typeof props.data.child === 'object') {
      Object.keys(props.data.child).forEach(itm => {
        deti.push({
          jmeno: props.data.child[itm].jmeno,
          rc: props.data.child[itm].rc,
        });
      })
    }
    let detiShowInput = props.data.showChild;
    if(detiShowInput && deti.length === 0) {
      deti.push({
        jmeno: '',
        rc: ''
      })
    }

    this.state = {
      token: props.token,
      documentStatus: null,
      documentType: props.data.typ_dokladu.toLowerCase(),
      documentNumber: '',

      gold: false,
      deti_show_input: detiShowInput,
      deti_show: false,
      deti: deti,

      processDone: false
    };
  }

  componentDidMount = () => {

    let data = this.props.data;

    this.setState({
      telefon_original: data.phone,
      telefon_predvolba: this.parsePhone(data.phone, false),
      telefon_cislo: this.parsePhone(data.phone, true),
      name: data.name,
      rcic: data.rcic,
      documentType: data.typ_dokladu.toLowerCase(),
      //deti: deti,
      //deti_show: deti_show
    });
  }

  processErrorMessage = (message) => {
    if (message === 'phone number is invalid') {
      return 'Zadané telefonní číslo není platné';
    } else if (message === 'invalid sms code') {
      return 'Zadaný SMS kód není platný';
    } else if (message === 'can not send sms') {
      return 'SMS nelze odeslat, jelikož od odeslání předchozí SMS neuběhlo alespoň půl minuty.';
    } else if (message === 'can not verify sms token') {
      return 'Potvrzení pomocí SMS nyní nelze dokončit. Obraťte se prosím na svého poradce.';
    } else if (message === 'bad number') {
      return 'Zadané telefonní číslo se neshoduje s tím, které poradce uvedl při Vaší identifikaci. Kontaktujte prosím svého poradce.';
    } else {
      return 'Při zpracování vašeho podpisu došlo k chybě, zkuste prosím stránku obnovit a podpis opakovat.';
    }
  };

  souhlasUdelen = () => {
    let code = this.state.code;
    code = code.replace(/\s/g, '');

    if (code.length > 0) {
      this._myplannClient.souhlas_souhlasUdelen(code, '+' + this.state.telefon_predvolba + this.state.telefon_cislo, this.state.telefon_original, this.state.gold, this.state.deti_show ? this.state.deti : [], this.state.token).then((response) => {
        console.log(response.data);
        if(response.data.data.status === 'ok') {
          this.setState({processDone: true});
        } else {
          alert('Došlo k chybě');
        }
        // alert('Všechno proběhlo v pořádku. V dalším kroku si nastavíte heslo.')

        return;
      }).catch((data) => {
        if (data.response) {
          if (data.response.data) {
            if (data.response.data.exception) {
              this.setState({
                code: '',
                hasError: true,
                message: this.processErrorMessage(data.response.data.exception.message)
              });
              this.render();
              return;
            }
          }
        }
        if (data.response) {
          this.setState({processDone: true});
          return;
        }
        this.setState({
          codeSent: false,
          code: '',
          hasError: true,
          message: 'Došlo k chybě během zpracovávání požadavku.'
        });
        this.render();
      });
    } else {
      this.setState({
        hasError: true,
        message: 'Zadejte, prosím, SMS kód'
      });
    }
  };

  onClickSendAgain = () => {
    this.setState({
      codeSent: false,
      codeSentInPast: true,
      message: '',
    });
  };

  parsePhone = (phone, number) => {
    if (phone) {
      let rule = /^\+420[0-9]*$/;
      if (rule.test(phone)) {
        if (number) {
          return phone.replace('+420', '');
        } else {
          return '420';
        }
      } else {
        if (number) {
          return phone.replace('+', '');
        } else {
          return '';
        }
      }
    } else {
      if (number) {
        return '';
      } else {
        return '420';
      }
    }
  };

  onChange = (e) => {
    var value = e.target.value;
    if (e.target.name === 'telefon_cislo' || e.target.name === 'telefon_predvolba') {
      value = value.replace(new RegExp('[^0-9]', 'g'), '');
    }

    if (e.target.name === 'documentNumber') {
      value = value.replace(new RegExp('[^A-Z0-9a-z-/]', 'g'), '');
    }

    this.setState({
      [e.target.name]: value
    });

    if (e.target.name === 'documentType' || e.target.name === 'documentNumber') {
      this.setState({
        documentStatus: null,
        documentError: null,
      });
    }
  };

  overitDoklad = () => {
    if (this.state.documentType === 'op') {
      let rule = /^[1-9][0-9]{8}$/;
      if (!rule.test(this.state.documentNumber)) {
        this.setState({
          documentError: 'Zadané číslo dokladu není správné. ',
        });
        return;
      }
    }

    this._myplannClient.kontrolaDokladuSouhlas(this.state.documentType, this.state.documentNumber, this.state.token).then((response) => {
      if (response) {
        this.setState({
          documentStatus: response.data.data.status,
        });
      }
    }).catch((data) => {
      if (data.response) {
        if (data.response.data) {
          if (data.response.data.exception) {
            this.setState({
              hasError: true,
              message: this.processErrorMessage(data.response.data.exception.message)
            });
          }
        }
      }

      throw data;
    });
  };

  odeslatZnovu = () => {
    this._myplannClient.souhlas_odeslatZnovuSms('+' + this.state.telefon_predvolba + this.state.telefon_cislo, this.state.telefon_original, this.state.token).then((response) => {
      if (response) {
        this.setState({
          codeSent: true,
          hasError: false,
          codeSentTelephone: '+' + this.state.telefon_predvolba + this.state.telefon_cislo,
        });
      }
    }).catch((data) => {
      if (data.response) {
        if (data.response.data) {
          if (data.response.data.exception) {
            this.setState({
              hasError: true,
              message: this.processErrorMessage(data.response.data.exception.message)
            });
          }
        }
      }

      throw data;
    });
  };

  setGold = (gold) => {
    this.setState({
      gold: gold,
    });
  };

  setDeti = (deti_show) => {
    this.setState({
      deti_show: deti_show,
    });
  };

  addDite = () => {
    var deti = this.state.deti;
    if(deti.length >= 3) {
      return;
    }
    deti.push({jmeno: '', rc: ''});
    this.setState({
      deti: deti
    });
  };


  onDiteChange = (index, sloupec, event) => {

    var deti = this.state.deti;
    deti[index][sloupec] = event.target.value;

    if(sloupec === 'rc') {
      //kontrola rc

      var rc = event.target.value;

      if (rc.length === 0) {
        return;
      }

      var rcPattern = /^\s*(\d\d)(\d\d)(\d\d)[ /]*(\d\d\d)(\d?)\s*$/gm;
      var matches = rcPattern.exec(rc);
      if (!matches) {
        deti[index]['error'] = 'Neplatný formát rodného čísla';
        this.setState({deti: deti});
        return;
      }

      rc = rc.replace(/\D/g, '');
      deti[index][sloupec] = rc;

      matches.shift();
      var [year, month, day, ext, c] = matches;
      var yearN = parseInt(year);
      var monthN = parseInt(month);
      var dayN = parseInt(day);
      if (c === '') {
        yearN += yearN < 54 ? 1900 : 1800;
      } else {
        yearN += yearN < 54 ? 2000 : 1900;
      }

      // k měsíci může být přičteno 20, 50 nebo 70
      if (monthN > 70 && yearN > 2003) {
        monthN -= 70;
      } else if (monthN > 50) {
        monthN -= 50;
      } else if (monthN > 20 && yearN > 2003) {
        monthN -= 20;
      }

      var existDate = new Date(yearN + '/' + monthN + '/' + dayN);
      if (isNaN(existDate.getTime())) {
        deti[index]['error'] = 'Neplatný formát rodného čísla.';
        this.setState({deti: deti});
        return;
      }

      if ((new Date()).getTime() < (new Date(yearN, (monthN - 1), dayN)).getTime()) {
        deti[index]['error'] = 'Nevalidní rodné číslo.';
        this.setState({deti: deti});
        return;
      }

      if (c !== '') {
        // kontrola delitelnosti
        var mod = ("" + year + month + day + ext) % 11;
        if (mod === 10) {
          mod = 0;
        }
        mod += '';
        if (mod !== c) {
          deti[index]['error'] = true;
          this.setState({deti: deti});
          return;
        }
      }

      var actualDate = new Date();
      var actualMonth = actualDate.getUTCMonth() + 1; // cisluje od 0
      var age = actualDate.getUTCFullYear() - yearN; // kolik mi bude az budu mit po narozeninach tenhle rok
      if (actualMonth < monthN) {
        age--;
      } else if (actualMonth === monthN) {
        if (actualDate.getUTCDate() < day) {
          age--;
        }
      }
      if (age >= 18) {
        deti[index]['error'] = 'Děti lze zadat pouze do věku 18 let.';
        this.setState({deti: deti});
        return;
      }
    }
    deti[index]['error'] = false;
    this.setState({deti: deti});


  };


  delDite = (index) => {
    let arr = [...this.state.deti];
    arr.splice(index, 1);
    this.setState({deti: arr});
  }

  onSmsVerified() {
    let self = this;
    this.props.onDone();
  }

  onClickShowCodeInput = () => {
    this.setState({
      codeSent: true,
      message: ''
    });
  };

  render = () => {
    let self = this;


    if (this.state.processDone) {
      return (
        <ForceUpdateScrollbars
          className=""
          autoHeight={true}
          autoHeightMin={300}
          autoHeightMax={'calc(100vh - 150px)'}
          forceupdaterendertimer={1000}
          identifier={'prvniprihlaseni-scrollbar'}
        >
          <div style={{padding: '20px'}} className="scroll-wrapper">
            <h1>Dokončeno</h1>
            <div className={'p-padding'}>
              Děkujeme, souhlas byl úspěšně podepsán a odeslán.
            </div>
          </div>
        </ForceUpdateScrollbars>
      );
    }

    var deti = null;
    var that = this;
    var hardError = false;
    if (this.state.deti_show) {
      deti = this.state.deti.map(function (item, index, arr) {
        var errorBox = null;
        if (typeof item.error === 'string' || item.error instanceof String) {
          hardError = true;
          errorBox = (<div style={{width: '100%'}} className={'error'}> {item.error} </div>);
        }
        return (
          <div key={index + item.rc} className={'gold-document__form'}>
            <div className={'gold-document__form__input'}>
              <label>Rodné číslo dítěte: </label>
              <input style={{border: item.error ? '1px solid red' : '1px solid #dddddd'}}
                     onBlur={(event) => that.onDiteChange(index, 'rc', event)} type={'text'} defaultValue={item.rc}
                     name={'diterc'}/>
            </div>
            <div className={'gold-document__form__input'}>
              <label>Jméno dítěte: </label>
              <input onChange={(event) => that.onDiteChange(index, 'jmeno', event)} type={'text'}
                     defaultValue={item.jmeno} name={'ditename'}/>
            </div>
            {index === arr.length - 1 && self.state.deti.length < 3 ? <span style={{cursor: 'pointer', marginBottom: '5px'}} className={'ico-add'}
                                              onClick={() => that.addDite()}>&nbsp;</span> :
              <span style={{marginBottom: '5px', cursor: 'pointer'}} className={'delete__cross'}
                    onClick={() => that.delDite(index)}>&nbsp;</span>}
            {errorBox}
          </div>)
      });
    }


    return (
      <ForceUpdateScrollbars
        className=""
        autoHeight={true}
        autoHeightMin={300}
        autoHeightMax={'calc(100vh - 150px)'}
        forceupdaterendertimer={1000}
        identifier={'prvniprihlaseni-scrollbar'}
      >
        <div style={{padding: '20px'}} className="scroll-wrapper">
          <h1>Informace a souhlas se zpracováním osobních údajů</h1>
          <GdprText email={this.props.data.email} />

          <div className={'gold'}>
            <label className="btn text-center">
              <input type={'checkbox'} onClick={() => this.setGold(!this.state.gold)} checked={this.state.gold}/>
              Souhlas s elektronickou formou komunikace
            </label>
          </div>

          <div className={'gold-document' + (!this.state.gold ? ' hidden' : '')}>
            <h2>Doklad totožnosti</h2>
            <div className={'gold-document__form'}>
              <div style={{width: '230px'}} className={'gold-document__form__input'}>
                <label>Typ dokladu: </label>
                <select name={'documentType'} disabled={true} value={this.state.documentType}>
                  <option value={'op'}>Občanský průkaz (český)</option>
                  <option value={'other'}>Jiný doklad (pas, zahraniční OP)</option>
                </select>
              </div>
              <div className={'gold-document__form__input'}>
                <label>Číslo dokladu: </label>
                <input type={'text'} name={'documentNumber'} onChange={this.onChange}/>
              </div>

            </div>
            <div className={'documentError' + (this.state.documentStatus === 0 ? '' : ' hidden')}>
              Vámi zadaný doklad je neplatný.
            </div>
            <div className={'documentError' + (this.state.documentStatus === 2 ? '' : ' hidden')}>
              Nepodařilo se ověřit doklad. Kontaktujte prosím svého poradce.
            </div>
            <div className={'documentError' + (this.state.documentStatus === 3 ? '' : ' hidden')}>
              Zadané číslo dokladu se neshoduje s tím, které poradce uvedl při Vaší identifikaci. Kontaktujte prosím
              svého poradce.
            </div>
            <div className={'documentError' + (this.state.documentError !== null ? '' : ' hidden')}>
              {this.state.documentError}
            </div>


            {this.state.deti_show_input ? (
              <>
              <div className={'gold'}>
                <label style={{fontSize: '12px'}} className="btn text-center">
                  <input checked={this.state.deti_show} onClick={() => this.setDeti(!this.state.deti_show)}
                         type={'checkbox'}/>
                  Souhlas se zpracováním osobních údajů u nezletiletých dětí.
                </label>
              </div>
              <div className={'gold' + (!this.state.deti_show ? ' hidden' : '')}>
                <h2 style={{marginTop: '10px'}}>Děti</h2>
                {deti}
              </div>
            </>
            )
            : null}




            <div className={'gold-document__form__btn'}>
              <input onClick={this.overitDoklad} type={'submit'}
                     className={'btn text-center'}
                     value={'Ověřit'}/>
            </div>
          </div>


          <div className={'validation' + (this.state.documentStatus !== 1 ? ' hidden' : '')}>
            <h2>Potvrzení pomocí SMS</h2>
            <div className={'validation__sign-box top-space--reset'}>
              {this.state && this.state.codeSent ?
                <div className="padding--bottom--10 text-weight--400">
                  Zadejte, prosím, SMS kód, který jsme Vám zaslali na Vaše telefonní
                  číslo {this.state.codeSentTelephone}
                </div>
                :
                ''
              }

              {this.state && this.state.hasError && this.state.message ?
                <div className="padding--bottom--10 text-weight--600 error">
                  {this.state.message}
                </div>
                :
                ''
              }

              {this.state.codeSent ?
                hardError ? <div><span className={'error'}>Pro pokračování opravte chybu v rodném čísle.</span></div> :
                  <div className={'validation__form validation__form--width-width'}>
                    <div className={'validation__form__input'}>
                      <input placeholder={'SMS kód'} type="text" name={'code'}
                             value={this.state.code} onChange={this.onChange}/>
                    </div>
                    <div className="validation__form__btn">
                      <input type="submit" onClick={this.souhlasUdelen} className={'btn pointer text-center'}
                             value={'Ověřit a souhlasit'}/>
                    </div>
                    <div className='width--100--percent text-right text-gray padding--top--5'>
                      <button onClick={this.onClickSendAgain} className='gray-link pointer btn--text-link'>zaslat
                        znovu
                      </button>
                    </div>
                  </div>

                :
                <div className={'validation__form validation__form--width-width phone'}>
                  <div className={'validation__form__input'}>
                    <span className={'plus'}>+</span>
                    <input className={'predvolba'} placeholder={'Předvolba'} type="text"
                           name={'telefon_predvolba'} value={this.state.telefon_predvolba}
                           onChange={this.onChange}/>
                    <input className={'cislo'} placeholder={'Vaše telefoní číslo'} type="text"
                           name={'telefon_cislo'} value={this.state.telefon_cislo}
                           onChange={this.onChange}/>
                  </div>
                  <div className={'validation__form__btn'}>
                    <input onClick={this.odeslatZnovu} type={'submit'}
                           className={'btn text-center'}
                           value={'Odeslat SMS klíč'}/>
                  </div>
                  {this.state.codeSentInPast ?
                    <div className='width--100--percent text-right text-gray padding--top--5'>
                      <button onClick={this.onClickShowCodeInput} className='gray-link pointer btn--text-link'>zpět na
                        zadávání kódu
                      </button>
                    </div>
                    : ''}
                </div>
              }

              <table className={'top-space--15'}>
                <tr>
                  <td width="50px" className='padding--2 text-dark-gray'>Jméno:</td>
                  <td className='padding--2 text-dark-gray'>{this.state.name}</td>
                </tr>
                <tr>
                  <td className='padding--2 text-dark-gray'>RČ/IČ:</td>
                  <td className='padding--2 text-dark-gray'>{this.state.rcic}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </ForceUpdateScrollbars>
    );
  }
}
