// route definition for app
// sub-routes should be defined in sub-components

import React from "react";
import {Route, Router, Switch} from "react-router-dom";
import history from './history';

import Default from "./components/Default";
import LoginPage from "./components/Login/LoginPage";
import PrvniPrihlaseni from "./components/Home/PrvniPrihlaseni";
import ForcedChangePassword from "./components/Uzivatel/ForcedChangePassword";
import ZapomenuteHeslo from "./components/Login/ZapomenuteHeslo";
import Validation from "./components/Validation/Validation";
import VytvoreniPristupu from "./components/Login/VytvoreniPristupu";
import Gdpr from "./components/Gdpr/Gdpr";

const Routes = ({user}) => (
	<Router history={history}>
		<Switch>
			<Route exact path="/login" component={LoginPage}/>
			<Route exact path="/prvni-prihlaseni" component={PrvniPrihlaseni}/>
			<Route exact path="/change-password" component={ForcedChangePassword}/>
			<Route exact path="/validation" component={Validation}/>
			<Route exact path="/gdpr" component={Gdpr}/>
      <Route exact path="/zapomenute-heslo" component={ZapomenuteHeslo}/>
      <Route exact path="/vytvoreni-pristupu" component={VytvoreniPristupu}/>
      <Route path="/" component={Default}/>
		</Switch>
	</Router>
);
export default Routes;
