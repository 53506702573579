type
SmartformClientRequestFieldType = "STREET_AND_NUMBER" | "STREET" | "NUMBER" | "CITY" | "ZIP" | "WHOLE_ADDRESS" | "MUNICIPALITY_AND_DISTRICT";

export default class SmartformClientRequest {

	constructor() {
		this.data = {
			id: this.generateId(),
			values: {
				STREET_AND_NUMBER: undefined,
				STREET: undefined,
				NUMBER: undefined,
				CITY: undefined,
				ZIP: undefined,
				WHOLE_ADDRESS: undefined,
				MUNICIPALITY_AND_DISTRICT: undefined,
			},
			fieldType: "STREET_AND_NUMBER",
			limit: 10
		};
	}

	toJson = () => {
		return this.data;
	};

	generateId = () => {
		return 1;
	};

	setFieldType = (value: SmartformClientRequestFieldType) => {
		this.fieldType = value;
		return this;
	};

	setCombination1 = (STREET: string = "", NUMBER: string = "", CITY: string = "", ZIP: string = "", COUNTRY: string = "") => {
		this.data.values['STREET'] = STREET;
		this.data.values['NUMBER'] = NUMBER;
		this.data.values['CITY'] = CITY;
		this.data.values['ZIP'] = ZIP;
		this.data.values['COUNTRY'] = COUNTRY;
		return this;
	};

	setCombination2 = (STREET_AND_NUMBER: string = "", CITY: string = "", ZIP: string = "", COUNTRY: string = "") => {
		this.data.values['STREET_AND_NUMBER'] = STREET_AND_NUMBER;
		this.data.values['CITY'] = CITY;
		this.data.values['ZIP'] = ZIP;
		this.data.values['COUNTRY'] = COUNTRY;
		return this;
	};

	setCombination3 = (WHOLE_ADDRESS: string = "", COUNTRY: string = "") => {
		this.data.values['WHOLE_ADDRESS'] = WHOLE_ADDRESS;
		this.data.values['COUNTRY'] = COUNTRY;
		return this;
	};

	setCombination4 = (MUNICIPALITY_AND_DISTRICT: string = "", COUNTRY: string = "") => {
		this.data.values['MUNICIPALITY_AND_DISTRICT'] = MUNICIPALITY_AND_DISTRICT;
		this.data.values['COUNTRY'] = COUNTRY;
		return this;
	};
}