import React from 'react';
import PropTypes from 'prop-types';
import {confirmable, createConfirmation} from 'react-confirm';
import Modal from "../components/utils/Modal/Modal";
import {modalBodyScrollPrevent} from "./generic";

const FileRenamePromtDialog = ({show, proceed, dismiss, cancel, name, options}) => {

	modalBodyScrollPrevent(show, "fileRename");

	return (
		<Modal
			onRequestClose={dismiss}
			isOpen={show}
		>
			<div className={'modal__confirm'}>
				<h1 className={'modal__confirm__title'}>Přejmenování dokumentu</h1>
				<div className={'modal__confirm__container'}>
					<p>Zadejte nový název dokumentu (maximálně 50 znaků):</p>
					<input defaultValue={name} id={"prommt__new-file-name"} className={"modal__confirm__input"}
						   maxLength={50}/>

					<div className={'modal__confirm__buttons'}>
						<button className={'modal__confirm__btn modal__confirm__btn--single'}
								onClick={() => proceed(document.getElementById("prommt__new-file-name").value)}>Změnit
						</button>
					</div>

				</div>
			</div>

		</Modal>
	);
};

FileRenamePromtDialog.propTypes = {
	show: PropTypes.bool,
	proceed: PropTypes.func,
	cancel: PropTypes.func,
	dismiss: PropTypes.func,
	name: PropTypes.string,
	options: PropTypes.object
};

const fileRenamePromtDialog = createConfirmation(confirmable(FileRenamePromtDialog));

export default function (name, options = {}) {
	return fileRenamePromtDialog({name, options});
}