import React, {Component} from "react";
import SmsSjednani from "../Validation/SmsSjednani";

class DetailZaznamuSjednani extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentWidth : window.innerWidth,
    }
  }

  updateWindowDimensions () {
    this.setState({ currentWidth: window.innerWidth });
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateWindowDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions.bind(this));
  }

  render = () => {

    let url = process.env.REACT_APP_BASE_URL;
    let authToken = process.env.REACT_APP_AUTH_TOKEN;
    url += ("/notificator/sjednaniPdf?smlouva=" + this.props.cislo_smlouvy + '&token=' + this.props.token + '&auth=' + authToken);


    return (
      <div>
        <div style={{fontSize: '14px'}}>Smlouva {this.props.cislo_smlouvy}</div>

          {this.state.currentWidth > 575 ? <>
            <iframe src={url} title={'Smlouva ' + this.props.cislo_smlouvy} style={{width: '100%', height: '500px'}} />
            <div style={{padding: '10px 0'}}>
              Pokud se smlouva nezobrazuje správně - můžete ji <a target={'_blank'} href={url} style={{color: '#44b5dd', cursor: 'pointer'}}>stáhnout</a>.
            </div>
          </> : <>
            <div style={{padding: '10px 0'}}>
              Smlouva ke stažení: <a target={'_blank'} href={url} style={{color: '#44b5dd', cursor: 'pointer'}}>stáhnout</a>
            </div>
          </>}


        {this.props.hidePodpis ? null : (
          <>
            {this.props.zaznamPodepsan  === true && this.props.podepsano === 0 && <SmsSjednani onSmsVerified={this.props.onSmsVerified} token={this.props.token} cislo_smlouvy={this.props.cislo_smlouvy} />}
            {this.props.zaznamPodepsan  === true && this.props.podepsano === 1 && <div className="validation__sign-box"><div><span><span className="validation__info">Smlouva byla úspěšně podepsána.</span></span></div></div>}
            {(this.props.zaznamPodepsan  === false || this.props.zaznamPodepsan === undefined) && <div className="text-red">K podpisu smlouvy je potřeba nejprve podepsat záznam.</div>}
          </>
        )}


      </div>
    );
  }
}

export default DetailZaznamuSjednani;
