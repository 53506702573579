import React, {Component} from "react";

export default class PojisteneOsobyRizika extends Component {
  riziko = {
    smrt: {
      title: 'Smrt',
      variants: 0
    },
    'smrt-urazem': {
      title: 'Smrt úrazem',
      variants: 0
    },
    'invalidita-1': {
      title: 'Invalidita 1',
      variants: 0
    },
    'invalidita-2': {
      title: 'Invalidita 2',
      variants: 0
    },
    'invalidita-3': {
      title: 'Invalidita 3',
      variants: 0
    },
    'invalidita-4': {
      title: 'Invalidita 4',
      variants: 0
    },
    'zavazne-onemocneni': {
      title: 'Závažné onemocnění',
      variants: 0
    },
    'pro-boj-s-rakovinou': {
      title: 'Pro boj s rakovinou',
      variants: 0
    },
    'pro-zeny': {
      title: 'Pro ženy',
      variants: 0
    },
    'uverovy-balicek': {
      title: 'Úvěrový balíček',
      variants: 0
    },
    'trvale-nasledky-urazu-od-0-5-proc': {
      title: 'Trvalé následky úrazu od 0,5 %',
      variants: 1
    },
    'trvale-nasledky-urazu-od-10-proc': {
      title: 'Trvalé následky úrazu od 10 %',
      variants: 1
    },
    'telesna-poskozeni-urazem': {
      title: 'Tělesná poškození úrazem',
      variants: 1
    },
    'pracovni-neschopnost': {
      title: 'Pracovní neschopnost',
      variants: 2
    },
    'denni-odskodne-za-uraz': {
      title: 'Denní odškodné za úraz',
      variants: 2
    },
    'hospitalizace': {
      title: 'Hospitalizace',
      variants: 2
    },
  };

  variants = {
    0: {
      'konstantni': {
        'title': 'konstantní',
        'additional': null
      },
      'klesajici': {
        'title': 'klesající',
        'additional': null
      },
      'anuitni': {
        'title': 'anuitní',
        'additional': 'Procento'
      }
    },
    1: {
      'konstantni': {
        'title': 'konstantní',
        'additional': 'Progrese'
      },
      'klesajici': {
        'title': 'klesající',
        'additional': 'Progrese'
      }
    },
    2: {
      'zpetne': {
        'title': 'zpětně',
        'additional': 'Odkladná doba'
      },
      'nezpetne': {
        'title': 'nezpětně',
        'additional': 'Odkladná doba'
      }
    }
  };

  constructor(props) {
    super(props);

    let state = {
      noveRizikoSelect: false,
      pojisteneOsobyRizika: props.pojisteneOsobyRizika ? props.pojisteneOsobyRizika : [],
      onChange: props.onChange ? props.onChange : (e, index) => {
        let name = e.target.name.replace("riziko[" + index + "]", "");
        this.state.pojisteneOsobyRizika[index][name] = e.target.value;
        this.setState({});
      },
      onClickSmazat: props.onClickSmazat ? props.onClickSmazat : (e, index) => {
        this.state.pojisteneOsobyRizika[index]['smazano'] = 1;
        this.setState({});
      }
    };

    this.state = state;
  }

  onChange = (e, index) => {
    // this.setState({ [e.target.name]: e.target.value });
    this.state.onChange(e, index);
    this.props.onFormChange();
  };

  onClickSmazat = (e, index) => {
    this.state.onClickSmazat(e, index);
    this.props.onFormChange();
  }

  createRiziko = (e) => {
    let riziko = e.target.value;
    e.target.value = "";
    this.setState({
      noveRizikoSelect: false
    });

    var maxIndex = -1;
    this.state.pojisteneOsobyRizika.map((item, index) => {
      if (index > maxIndex) {
        maxIndex = index;
      }
      return '';
    });

    let newIndex = maxIndex + 1;
    let pojisteneOsobyRizika = this.state.pojisteneOsobyRizika;
    pojisteneOsobyRizika[newIndex] = {
      id: null,
      do_veku: "",
      doplnujici_udaj: "",
      hodnota: "",
      riziko: riziko,
      varianta: "",
      unsaved: true
    };

    this.setState({
      pojisteneOsobyRizika
    });

    this.props.onFormChange();
  };

  render() {
    return (
      <div className={'portfolio-form-subform-osoby-rizika'}>
        {this.state.pojisteneOsobyRizika.map((riziko, index) => {
          let varianta = this.riziko[riziko.riziko].variants;

          return (
            <div key={index}>
              <div className={'riziko'} style={{display: (riziko.smazano ? 'none' : 'block')}}>
                <input type="hidden" name={'riziko[' + index + ']id'} value={riziko.id}/>
                <input type="hidden" name={'riziko[' + index + ']smazano'} value={riziko.smazano}/>
                <input type="hidden" name={'riziko[' + index + ']riziko'} value={riziko.riziko}/>
                <div className={'row input-row'} style={{marginRight: '10px'}}>
                  <div className={'col-sm-5'}>
                    <label>{this.riziko[riziko.riziko].title}</label>
                    <input type="text" name={'riziko[' + index + ']hodnota'} value={riziko.hodnota} onChange={(e) => {
                      this.onChange(e, index)
                    }}/>
                  </div>
                  <div className={'col-sm-2'}>
                    <label>Do věku:</label>
                    <input type="text" name={'riziko[' + index + ']do_veku'} value={riziko.do_veku} onChange={(e) => {
                      this.onChange(e, index)
                    }}/>
                  </div>
                  <div className={'col-sm-3 varianta'}>
                    <label>Varianta</label>
                    <select onChange={(e) => {
                      this.onChange(e, index)
                    }} name={'riziko[' + index + ']varianta'} value={riziko.varianta}>
                      <option value=""></option>
                      {Object.keys(this.variants[varianta]).map((value, index) => {
                        riziko.doplnujici_udaj = (this.variants[varianta][riziko.varianta] && this.variants[varianta][riziko.varianta].additional ? riziko.doplnujici_udaj: "")

                        return (<option key={index} value={value}
                                        data-additional={this.variants[varianta][value].additional}>{this.variants[varianta][value].title}</option>);
                      })}
                    </select>
                  </div>
                  <div className={'col-sm-2 doplnujici-udaj'}
                       style={{display: (this.variants[varianta][riziko.varianta] && this.variants[varianta][riziko.varianta].additional ? 'block' : 'none')}}>
                    <label>{(this.variants[varianta][riziko.varianta] && this.variants[varianta][riziko.varianta].additional ? this.variants[varianta][riziko.varianta].additional : '')}</label>
                    <input type="text" name={'riziko[' + index + ']doplnujici_udaj'} value={riziko.doplnujici_udaj}
                           onChange={(e) => {
                             this.onChange(e, index)
                           }}/>
                  </div>
                  <span className={'smazat'} onClick={(e) => {
                    this.onClickSmazat(e, index)
                  }}></span>
                </div>
              </div>
            </div>
          )
        })}
        <div className="dalsi-pripojisteni">
          <div style={{display: (this.state.noveRizikoSelect ? 'none' : 'block')}}>
            <div className="pointer"
               style={{marginTop: '10px'}} onClick={(e) => {
              this.setState({
                noveRizikoSelect: true
              }, this.props.onFormChange());

            }}>
              <span className="fa fa-plus" style={{color: '#44b5dd'}}></span> Další připojištění
            </div>
          </div>
          <div className="row" style={{display: (this.state.noveRizikoSelect ? 'block' : 'none')}}>
            <div className="col-sm-12">
              <label>Riziko</label>
              <br/>
              <select onChange={(e) => {
                this.createRiziko(e)
              }}>
                <option value=""></option>
                {Object.keys(this.riziko).map((key, index) => {
                  return (<option key={index} value={key}>{this.riziko[key].title}</option>);
                })}
              </select>
            </div>
            <span className="smazat" onClick={(e) => {
              this.setState({
                noveRizikoSelect: false
              }, this.props.onFormChange());
            }}> </span>
          </div>
        </div>
      </div>
    );
  }
}
