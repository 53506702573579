import React, {Component} from "react";
import {getContainer} from "../../../utils/generic";

export default class InvesticniPokyny extends Component {

	constructor(props) {
		super(props);

		let state = {
			activeTabIndex: props.activeTabIndex ? props.activeTabIndex : -1,
			investicniPokyny: props.investicniPokyny ? props.investicniPokyny : [],
			onChange: props.onChange ? props.onChange : (e, index) => {
				let name = e.target.name.replace("investicniPokyny[" + index + "]", "");
				this.state.investicniPokyny[index][name] = e.target.value;
				this.setState({});
			}
		};

		state.investicniPokyny.forEach((item, index) => {
			if (item.unsaved === true) {
				delete state.investicniPokyny[index];// = undefined;
			} else {
				if (state.activeTabIndex === -1) {
					state.activeTabIndex = index;
				}
			}
		});

		this.state = state;
		this._uzivatel = getContainer().getUzivatel();
	}

	changeTab = (e, index) => {
		this.setState({
			activeTabIndex: index
		});
	};

	createTab = (e) => {
		var maxIndex = -1;
		this.state.investicniPokyny.map((item, index) => {
			if (index > maxIndex) {
				maxIndex = index;
			}
			return '';
		});

		let newIndex = maxIndex + 1;
		let investicniPokyny = this.state.investicniPokyny;
		investicniPokyny[newIndex] = {
			id: null,
			nakup_prodej: "",
			typ_identifikace: "",
			identifikace: '',
			nazev: "",
			vyse: "",
			mena: "",
			periodicita: "",
			typ_transakce: "",
			dalsi_dispozice: "",
			unsaved: true,
		};

		this.setState({
			activeTabIndex: newIndex,
			investicniPokyny
		});
		this.props.onFormChange();
	};

	onChange = (e, index) => {
		this.state.onChange(e, index);
		this.props.onFormChange();
	};

	render() {
		return (
			<div className={'portfolio-form-subform'}>
				<div className={'portfolio-form-subform__header padding--vertical--10'}>
					Investiční pokyny
				</div>
				<div className={'portfolio-form-subform__triggers'}>
					{this._uzivatel.readAccessOnly() ? '' :
						<div
							className={'portfolio-form-subform__triggers__trigger portfolio-form-subform__triggers__trigger--plus pointer'}
							onClick={(e) => {
								this.createTab(e)
							}}>
							+
						</div>
					}
					{this.state.investicniPokyny.map((osoba, index) => {
						return (
							<div key={index}
								 className={'portfolio-form-subform__triggers__trigger pointer' + (this.state.activeTabIndex === index ? ' active' : '')}
								 onClick={(e) => {
									 this.changeTab(e, index)
								 }}>
								{index + 1}
							</div>
						);
					})}
				</div>
				<div className={'portfolio-form-subform__content'}>
					{this.state.activeTabIndex === -1 ?
						<span>
                            {this._uzivatel.readAccessOnly() ?
								<span className={'text-weight--400 font-size--11'}>
                                    Nejsou uloženy žádné investiční pokyny.
                                </span>
								:
								<span className={'text-weight--400 font-size--11'}>
                                    Přidejte investiční pokyny pomocí tlačítka <span
									className={'portfolio-form-subform__trigger-demo'} onClick={(e) => {
									this.createTab(e)
								}}>+</span>
                                </span>
							}
                        </span>
						: ''}
					{this.state.investicniPokyny.map((pokyny, index) => {
						if (this.state.activeTabIndex !== index) {
							return '';
						}
						return (
							<div key={index}>
								<div className={'row'}>

									<div className={'col-sm-4'}>
										<div className={'input-row'}>
											<label
												htmlFor={'investicniPokyny[' + index + ']nakup_prodej'}>Nákup/prodej</label>
											<select tabIndex={1} onChange={(e) => {
												this.onChange(e, index)
											}} name={'investicniPokyny[' + index + ']nakup_prodej'}
													value={pokyny.nakup_prodej}>
												<option value=""/>
												<option value="B">Nákup</option>
												<option value="S">Prodej</option>
											</select>
										</div>
										<div className={'input-row'}>
											<label htmlFor={'investicniPokyny[' + index + ']typ_identifikace'}>Typ
												identifikace</label>
											<select tabIndex={2} onChange={(e) => {
												this.onChange(e, index)
											}} name={'investicniPokyny[' + index + ']typ_identifikace'}
													value={pokyny.typ_identifikace}>
												<option value=""/>
												<option value="I">ISIN</option>
												<option value="O">Ostatní</option>
											</select>
										</div>
										<div className={'input-row'}>
											<label
												htmlFor={'investicniPokyny[' + index + ']identifikace'}>Identifikace</label>
											<input tabIndex={3} onChange={(e) => {
												this.onChange(e, index)
											}} type="text" name={'investicniPokyny[' + index + ']identifikace'}
												   value={pokyny.identifikace}/>
										</div>
									</div>

									<div className={'col-sm-4'}>
										<div className={'input-row'}>
											<label htmlFor={'investicniPokyny[' + index + ']nazev'}>Název</label>
											<input tabIndex={4} onChange={(e) => {
												this.onChange(e, index)
											}} type="text" name={'investicniPokyny[' + index + ']nazev'}
												   value={pokyny.nazev}/>
										</div>
										<div className={'input-row'}>
											<label htmlFor={'investicniPokyny[' + index + ']vyse'}>Výše</label>
											<input tabIndex={5} onChange={(e) => {
												this.onChange(e, index)
											}} type="text" name={'investicniPokyny[' + index + ']vyse'}
												   value={pokyny.vyse}/>
										</div>
										<div className={'input-row'}>
											<label htmlFor={'investicniPokyny[' + index + ']mena'}>Měna</label>
											<select tabIndex={6} onChange={(e) => {
												this.onChange(e, index)
											}} name={'investicniPokyny[' + index + ']mena'} value={pokyny.mena}>
												<option value=""/>
												<option value="CZK">CZK</option>
												<option value="EUR">EUR</option>
												<option value="USD">USD</option>
											</select>
										</div>
									</div>

									<div className={'col-sm-4'}>
										<div className={'input-row'}>
											<label
												htmlFor={'investicniPokyny[' + index + ']periodicita'}>Periodicita</label>
											<select tabIndex={7} onChange={(e) => {
												this.onChange(e, index)
											}} name={'investicniPokyny[' + index + ']periodicita'}
													value={pokyny.periodicita}>
												<option value=""/>
												<option value="A">Ročně</option>
												<option value="S">Pololetně</option>
												<option value="Q">Čtvrtelně</option>
												<option value="M">Měsíčně</option>
												<option value="B">Každé 2 měsíce</option>
												<option value="J">Jednorázově</option>
											</select>
										</div>
										<div className={'input-row'}>
											<label htmlFor={'investicniPokyny[' + index + ']typ_transakce'}>Typ
												transakce</label>
											<select tabIndex={8} onChange={(e) => {
												this.onChange(e, index)
											}} name={'investicniPokyny[' + index + ']typ_transakce'}
													value={pokyny.typ_transakce}>
												<option value=""/>
												<option value="D">Obchod</option>
												<option value="T">Přestup mezi fondy</option>
											</select>
										</div>
										<div className={'input-row'}>
											<label htmlFor={'investicniPokyny[' + index + ']dalsi_dispozice'}>Další
												dispozice</label>
											<input tabIndex={9} onChange={(e) => {
												this.onChange(e, index)
											}} type="text" name={'investicniPokyny[' + index + ']dalsi_dispozice'}
												   value={pokyny.dalsi_dispozice}/>
										</div>
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		);
	}
}