import React, {Component} from "react";
import Autosuggest from "react-autosuggest";
import {getContainer} from "../../../utils/generic";

class AutocompleteSpolecnost extends Component {
	constructor(props) {
		super(props);
		this._client = getContainer().getMyplannClient();
		this.state = {
			filterSuggestions: false,
			loaded: false,
			spolecnosti: [],
			suggestions: [],
			value: props.value ? props.value : ''
		};
	}

	componentDidMount() {
		const _self = this;
		this._mounted = true;
		this._client
			.getProducentiSelectOptions(this.props.oblast)
			.then(spolecnosti => {
				if (this._mounted) {
					_self.setState({
						spolecnosti
					}, () => {
						const suggestions = _self.createSuggestions('');
						const suggestion = suggestions.find((item) => (item.value === _self.props.value));
						_self.setState({
							suggestions,
							selectedProducentSuggestion: suggestion
						});
					});
				}
			});
	}

	componentWillUnmount() {
		this._mounted = false;
	}

	onChange = (event, {newValue}) => {
		this.setState({
			value: newValue,
			filterSuggestions: true,
		});

		this.changeFormValue(newValue);
	};

	onSuggestionSelected = (event, {suggestion}) => {
		this.setState({
			selected: suggestion,
			filterSuggestions: false,
		});
	};

	changeFormValue = (value) => {

		const suggestion = this.state.suggestions.find((item) => (item.value === value));

		const e = {
			target: {
				name: this.props.name,
				value: value
			},
			selectedProducentSuggestion: suggestion
		};

		this.props.onChange(e);
	};

	createSuggestions = (value) => {
		let inputValue = value.trim().toLowerCase();
		let suggestions = inputValue.length === 0 || !this.state.filterSuggestions
						? this.state.spolecnosti.filter(() => true)
						: this.state.spolecnosti.filter(
							s => s.label.toLowerCase().indexOf(inputValue) !== -1
						);
		return suggestions;
	};

	onSuggestionsFetchRequested = ({value}) => {
		let suggestions = this.createSuggestions(value);
		this.setState({
			suggestions
		});
	};

	onSuggestionsClearRequested = () => {
		this.setState({
			suggestions: []
		});
	};

	onFocus = () => {
		this.setState({
			filterSuggestions: false,
		});
  };

	onBlur = () => {
		this.setState({
			filterSuggestions: false,
		});
	};

	render() {
		let inputProps = {
			placeholder: "",
			value: this.state.value,
			onChange: this.onChange,
			onFocus: this.onFocus,
			onBlur: this.onBlur,
			name: this.props.name
		};
		return (
			<Autosuggest
				suggestions={this.state.suggestions}
				onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
				onSuggestionsClearRequested={this.onSuggestionsClearRequested}
				getSuggestionValue={suggestion => suggestion.label}
				renderSuggestion={suggestion => <div>{suggestion.label}</div>}
				inputProps={inputProps}
				onSuggestionSelected={this.onSuggestionSelected}
				shouldRenderSuggestions={() => true}
			/>
		);
	}
}

export default AutocompleteSpolecnost;
