import React, { Component } from "react";
import DatePicker from "react-datepicker";

import 'react-datepicker/dist/react-datepicker.css';
import {getContainer} from "../../utils/generic";

class DatePickerWithListener extends Component {
    constructor(props) {
        super(props);
        this._dateHelper = getContainer().getDateHelper();
        let dateFormat = props.dateFormat ? props.dateFormat : this._dateHelper.getActiveFormat();
        this.state = {
            selected: props.selected,
            inputClasses: props.className ? props.className.split(' ') : [],
            calendarClasses: props.calendarClasses ? props.calendarClasses.split(' ') : [],
            dateFormat: dateFormat,
            altDateFormats: [
                dateFormat,
                "D.M. YYYY",
                "D. M. YYYY",
            ]
        };
        this.datePickerRef = React.createRef();
        this.addedListener = null;
        this.addedListenerArea = null;
    }

	/**
     * Only when valid date is entered, we save this date.
     *
	 * @param prevProps
	 */
	componentDidUpdate = (prevProps) => {
        let newProps = this.props;

        if (newProps.selected && newProps.selected !== prevProps.selected) {
			let dateFormat = newProps.dateFormat ? newProps.dateFormat : this.state.dateFormat;

			if (newProps.selected.isValid()) {
				this.setState({
					selected: newProps.selected,
					dateFormat: dateFormat,
				});
            }
        }
    };

	/**
     * If new date isn't valid, we won't re-render component so in component will be showed string that was written by the user.
     *
	 * @param nextProps
	 * @returns {boolean}
	 */
	shouldComponentUpdate = (nextProps) => {
	    if (nextProps.selected && nextProps.selected.isValid() === false) {
	    	// if date is invalid we will set listener to click to datepicker day
	    	if (this.addedListener === null) {
				this.addedListenerArea = document.getElementById('react-datepicker__' + this.props.name);
				this.addedListener = this.handleClick.bind(this);
				this.addedListenerArea.addEventListener("click", this.addedListener, true);
			}
	        return false;
        }

        // if date is valid we will remove listener if listener is set
		if (this.addedListener !== null) {
			this.addedListenerArea.removeEventListener("click", this.addedListener, true);
			this.addedListener = null;
		}

        return true;
    };

    convSelectedToString(selected, dateFormat) {
        if (selected) {
            return selected.format(dateFormat);
        }
        return undefined;
    }

    componentDidMount() {
        document.addEventListener("change", this.handleNativeChange, true);
    }

    componentWillUnmount() {
        document.removeEventListener("change", this.handleNativeChange, true);
		// if listener is set we will remove this listener
		if (this.addedListener !== null) {
			this.addedListenerArea.removeEventListener("click", this.addedListener, true);
			this.addedListener = null;
		}
    }

	/**
	 * Listener for click on react datepicker day element
	 *
	 * @param event
	 */
	handleClick = event => {
        if (this.state.selected && this.state.selected.isValid()) {
			let popup = event.target.closest('.react-datepicker__day--selected');
			if (popup) {
				let moment = this.datePickerRef.state.preSelection;
				this.removeCssClass('error-react-datepicker').then(() => {
					if (this.props.onErrorRemoved) {
						this.props.onErrorRemoved(this.props.name, moment.format(this.state.dateFormat));
					}
				});
			}
		}
    };

    handleNativeChange = event => {
        if (event.target.name === this.props.name) {
            let that = this;
            if (that.datePickerRef) {
                setTimeout(() => {
                    that.datePickerRef.setOpen(false); // fix for automated tests.
                    // Must use timeout because date would not change if user interacts with datepicker interface by choosing valid date after altering input value to anything invalid.
                    // Datepicker would 'die' before processing selected value, if no delay for closing datepicker is used
                }, 200);

            }
        }
    };

    addCssClass = (clazz) => {
        return new Promise((resolve) => {
            let arrClone = this.state.inputClasses.slice(0);
            let idx = arrClone.indexOf(clazz);
            if (idx === -1) { //add only once
                arrClone.push(clazz);
                this.setState({
                    inputClasses: arrClone
                }, () => {
                    resolve(arrClone);
                });
            } else {
                resolve();
            }
        });
    };

    removeCssClass = (clazz) => {
        return new Promise((resolve) => {
            let arrClone = this.state.inputClasses.slice(0);
            let idx = arrClone.indexOf(clazz);
            if (idx >= 0) {
                arrClone.splice(idx, 1);
            } else {
                resolve();
            }
            this.setState({
                inputClasses: arrClone
            }, () => {
                resolve(arrClone);
            });
        });
    };

    createValidMomentFromString = (value) => {
        let that = this;
        let curFormat = null;
        let moment = null;
        for (let key in that.state.altDateFormats) {
            if (that.state.altDateFormats.hasOwnProperty(key)) {
                curFormat = that.state.altDateFormats[key];
                moment = that._dateHelper.createMomentInstance(value, true, curFormat);
                if (moment.isValid()) {
                    return moment;
                }
            }
        }
        return null;
    };

    onChangeRaw = event => {
        let that = this;
        let value = event.target.value;
        let moment = that.createValidMomentFromString(value);
        if (moment && moment._f !== that.state.dateFormat) {
            value = moment.format(that.state.dateFormat); // value will come again from parent via props because onError events refresh props
        }
        if (moment === null && event.target.value) {
            that.addCssClass('error-react-datepicker').then(() => {
                if (that.props.onError) {
                    that.props.onError(that.props.name, value);
                }
            });
        } else {
            that.removeCssClass('error-react-datepicker').then(() => {
                if (that.props.onErrorRemoved) {
                    that.props.onErrorRemoved(that.props.name, value);
                }
            });
        }
        if (that.props.onChangeRaw) {
            that.props.onChangeRaw(event);
        }
    };

    onChange = date => {
        let that = this;
        if (date && date.format) {
            date = date.format(that.state.dateFormat); //convert date to string
        }
        if (that.props.onErrorRemoved) { //onChange is called only when date is valid
            that.removeCssClass('error-react-datepicker').then(() => {
                that.props.onErrorRemoved(that.props.name, date);
            });
        }
        if (that.props.onChange) {
            that.props.onChange(date);
        }
    };

    render() {
        return (
        	<div id={'react-datepicker__' + this.props.name}>
				<DatePicker
					{...this.props}

					ref={datePicker => {
						this.datePickerRef = datePicker;
					}}

					className={this.state.inputClasses.join(' ')}
					calendarClassName={this.state.calendarClasses.join(' ')}
					selected={this.state.selected}
					dateFormat={this.state.dateFormat}
					onChangeRaw={this.onChangeRaw}
					onChange={this.onChange}

					disabledKeyboardNavigation
					locale='cs'
					showYearDropdown
					showMonthDropdown
					dropdownMode="select"
					popperPlacement="top-end"
					autoComplete='off'
				/>
			</div>
        );
    }
}

export default DatePickerWithListener;
