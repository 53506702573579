import React, {Component} from "react";
import * as PubSub from "pubsub-js";
import confirmDialog from "../../../utils/confirmDialog";
import fileRenamePromtDialog from "../../../utils/fileRenamePromt";
import {getContainer} from "../../../utils/generic";

// initial form data
const emptyData = {};

class DokumentyKeSmlouve extends Component {
	constructor(props) {
		super(props);
		this.state = emptyData;
		this._dokumenty = props.dokumenty;
		this._client = getContainer().getMyplannClient();
		this._uzivatel = getContainer().getUzivatel();
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.dokumenty) {
			this._dokumenty = nextProps.dokumenty;
			this.setState({});
		} else {
			this.setState(emptyData);
		}
	}

	getDokumenty = () => {
		return this._dokumenty;
	};

	smazatDokument = (id) => {
		let confirmOptions = {
			additionalConfirmMessage: 'Položka bude nenávratně smazána.<br/>Chcete-li položku zachovat, zavřete křížkem nebo vyberte <em>zrušit</em>.'
		};
		confirmDialog("Smazání dokumentu", "Opravdu chcete smazat dokument?", "smazat dokument", confirmOptions).then(
			result => {
				PubSub.publish('smazatPortfolioDalsiDokument', {id: id});
			},
			result => {
				// `cancel` callback
			}
		);
	};

	editovatDokument = (e, index) => {
		let doc = this._dokumenty[index];

		e.preventDefault();
		e.stopPropagation();

		fileRenamePromtDialog(doc.nazev).then(
			result => {
				if (result.length > 0) {
					this._client.editovatDokumentKeSmlouve(doc.id, {
						nazev: result.substr(0, 50)
					}).then((r) => {
						if (r) {
							doc.nazev = result;
							this.setState({});
							PubSub.publish('renamePortfolioDalsiDokument', {id: doc.id, name: result});
						}
					});
				}
			},
			result => {
				// `cancel` callback
			}
		);

	};

	render() {
		return (
			<div>
				{this.getDokumenty() && this.getDokumenty().length > 0 ?
					<div>
						{this.getDokumenty().map((item, index) => {
							return (
								<div key={index} className="document-row">
									<div className={'width--20'}>
										<a className="pointer download hide-text"
										   href={this._client.__dokumenty_download_url(item.id)}
										   onClick={(e) => {
											   e.preventDefault();
											   this._client.__dokumenty_download(item.id)
										   }}>Stáhnout soubor</a>
									</div>
									<div className={'break-words'}>
										{item.nazev}
									</div>
									<div className="width--30 text-right">
										{this._uzivatel.readAccessOnly() ? "" :
											<div>
												{item.sab_dokument ?
													''
													:
													<div>
														<span className="pointer edit d-inline-block hide-text"
														   onClick={(e) => this.editovatDokument(e, index)}>Upravit
															soubor</span>
														<span className="delete pointer delete__cross--small d-inline-block hide-text"
														   onClick={() => this.smazatDokument(item.id)}>Smazat
															soubor</span>
													</div>
												}
											</div>
										}
									</div>
								</div>
							);
						})}
					</div>
					:
					<div className={'font-size--11 text-gray--darken'}>
						{this._uzivatel.readAccessOnly() ? "Nejsou dostupné žádné dokumenty" : ""}
					</div>
				}
			</div>
		);
	}
}

export default DokumentyKeSmlouve;
