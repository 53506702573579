import React, {Component} from "react";
import * as PubSub from "pubsub-js";

class ErrorMessage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false
		};
	}

	UNSAFE_componentWillMount = function () {
		this._apiErrorSubscription = PubSub.subscribe(
			"apiError",
			this.apiError
		);
	};

	componentWillUnmount = function () {
		PubSub.unsubscribe(this._requestStarted);
	};

	apiError = (event, data) => {
		this.setState({show: true, error: data});
	};

	render() {
		if (!this.state.show) return null;

		return (
			<div className={"error-overlay"}>
				<h1>
					V aplikaci došlo k chybě :(<br/>
					Zkuste obnovit okno prohlížeče.
				</h1>
				<div className="details">
					<h2>
						<strong>Podrobnosti</strong>
					</h2>
					{this.state.error.message}
				</div>
			</div>
		);
	}
}

export default ErrorMessage;
